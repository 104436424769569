import React from "react";
import ReactDOM from "react-dom";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import CurrentUser from "./libs/CurrentUser";
import App from "./App";
import Public from "./Public";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(en)
let search = window.location.search;
let params = new URLSearchParams(search);
var token = params.get("token");
if (token) {
  localStorage.setItem("gorilla-token", token);
}
if (CurrentUser.token() != undefined) {
  ReactDOM.render(<App />, document.getElementById("root"));
} else {
  ReactDOM.render(<Public />, document.getElementById("root"));
}
