import React, { Component } from "react";
import Nav from "./Nav";
import ApiRequest from "../../libs/request";
import FeedNav from "./FeedNav";
import Post from "./Post";
import { loadStripe } from "@stripe/stripe-js";

class PromotePost extends Component {
  constructor(props) {
    super(props);
    this.state = { post: null, tiers: [] };
    this.handleSelectPrice = this.handleSelectPrice.bind(this);
    this.card = null;
    this.stripe = null;
  }
  render() {
    if (!this.state.post) {
      return <div />;
    }

    if (this.state.tier) {
      return this.stripeView();
    } else {
      return this.priceList();
    }
  }

  stripeView() {
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
              <h4>Promote Post</h4>
              <hr />
              <input
                type="hidden"
                id="tier"
                defaultValue={this.state.tier.id}
              />
              <input
                type="hidden"
                id="post_id"
                defaultValue={this.props.match.params.id}
              />
              <form id="payment-form">
                <div id="card-element"></div>

                <div id="card-errors" role="alert"></div>

                <button
                  id="submit"
                  type="button"
                  onClick={this.handleSubmit.bind(this)}
                  className="g-button full mt-4"
                >
                  Pay
                </button>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSubmit() {
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        // Inform the customer that there was an error.
        var errorElement = document.getElementById("card-errors");
        errorElement.textContent = result.error.message;
      } else {
        // Send the token to your server.
        var api = new ApiRequest(
          "post",
          "/posts/" + this.state.post.id + "/promote"
        );
        api.send(
          {
            post: {
              stripe_customer_token: result.token.id,
              sponsored: true,
              ad_tier_id: this.state.tier.id,
            },
          },
          (resp) => {
            this.setState({ flash: "Post Promoted", tier: null, tiers: [] });
            //window.location.href = '/?id=' + this.state.post.id;
          }
        );
      }
    });
  }

  priceList() {
    var _tiers = [];
    if (this.state.tiers.length > 0) {
      _tiers.push(<h4 key="-1">Prices</h4>);
    }
    this.state.tiers.map((t, idx) => {
      _tiers.push(
        <div
          key={idx}
          className="price"
          onClick={() => this.handleSelectPrice(t)}
        >
          {t.description}
        </div>
      );
    });

    var _flash = null;
    if (this.state.flash) {
      _flash = <div className="alert alert-success">{this.state.flash}</div>;
    }

    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
              <h4>Promote Post</h4>
              <hr />
              {_flash}
              <Post
                key="post"
                hideFooter={true}
                post={this.state.post}
                user={this.props.user}
                asset={null}
              />
              <div className="promote-options">{_tiers}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSelectPrice(tier) {
    // var api = new ApiRequest('post', '/payments/intent')
    // api.send({amount: tier.price}, (response) => {
    // this.setState({intent: response.data, tier: tier})
    //  });
    this.setState({ tier: tier });
  }

  componentDidUpdate() {
    if (this.state.tier) {
      loadStripe(process.env.REACT_APP_STRIPE_KEY).then(
        (stripe) => {
          var elements = stripe.elements();
          this.stripe = stripe;
          this.card = elements.create("card");
          this.card.hidePostalCode = true;
          this.card.mount("#card-element");
        }
      );
    }
  }
  componentDidMount() {
    var api = new ApiRequest("get", "/user/show-post/" + this.props.match.params.id);
    api.send({}, (response) => {
      this.setState({
        post: response.data.post,
      });

      api.method = "get";
      api.url = "/posts/tiers";
      api.send({}, (resp) => {
        this.setState({
          post: response.data.post,
          // tiers: resp.data.data.result,
        });
      });
    });
  }
}

export default PromotePost;
