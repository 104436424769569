import React, { Component } from "react";
import ApiRequest from "../libs/request.js";
import * as EmailValidator from 'email-validator';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.password = React.createRef();
    this.password_confirmation = React.createRef();
    this.errorsPanel = React.createRef();
    this.token = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid signup" style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className="row" style={{ width: '100%' }}>
            
            <div className="col-md-6 left-panel show-text-on-desktop  meta-text-paragraph" >
              <p className="auth-intro-text-signup" >
                Welcome to Gorilla Film, the platform connecting filmmakers worldwide with a film market, monetized streaming service, production office featuring call sheets, and a job board for finding and posting jobs.
                Screenwriters can upload screenplays and composers can upload their compositions to attract film producers.
                Designed to streamline your filmmaking process, Gorilla Film enables seamless collaboration and profitability for your projects.
              </p>
            </div>


            <div className="col-md-6 right-panel  meta-text-paragraph" >

              <div className=" panel">
                <div style={{ marginBottom: "20px" }}>
                  <img
                    src="/images/gorilla-logo-large.png"
                    className=" homelogo signuplogo"
                    style={{ width: "80%" }}
                    alt="Gorilla"
                  />
                  <br />
                  <span className="h5">Make Friends, Make Films!</span>
                </div>
                <div className="login signuppanel">
                  <span className="h3">
                    Create New
                    <br />
                    Account
                  </span>
                  <br />
                  <div className="errors" ref={this.errorsPanel}>
                    {" "}
                  </div>
                  <form>
                    <div className="field">
                      <input type="email" placeholder="Email" ref={this.email} />
                    </div>
                    <div className="field">
                      <input
                        type="password"
                        placeholder="Password"
                        ref={this.password}
                      />
                    </div>
                    <div className="field">
                      <input
                        type="password"
                        placeholder="Password Confirmation"
                        ref={this.password_confirmation}
                      />
                    </div>
                    {/* <div className="field">
                  <input
                    type="text"
                    placeholder="Invitation Code (optional)"
                    ref={this.token}
                  />
                </div> */}
                    <div className="buttons">
                      <button
                        className="btn"
                        onClick={this.handleSignupClick.bind(this)}
                        type="button"
                      >
                        Create Account
                      </button>
                      <span> Already have an account? <a href="/login">Login</a> </span>
                      <br />
                      <a
                        href="/terms-conditions"
                      >
                        Terms and Conditions
                      </a>
                      <span> | </span>
                      <a
                        href="/privacy"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-md-6 left-panel show-text-on-mobile" >
              <p className="auth-intro-text-signup" >
                Welcome to Gorilla Film, the platform connecting filmmakers worldwide with a film market, monetized streaming service, production office featuring call sheets, and a job board for finding and posting jobs.
                Screenwriters can upload screenplays and composers can upload their compositions to attract film producers.
                Designed to streamline your filmmaking process, Gorilla Film enables seamless collaboration and profitability for your projects.
              </p>
            </div>


          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidUpdate() {
    if (this.state.errors) {
      this.errorsPanel.current.innerHTML = this.state.errors;
    }
  }

  handleSignupClick() {
    var validator = EmailValidator;
    if (this.email.current.value.length < 1) {
      this.setState({ errors: ["Please enter an email address"] });
      return;
    }

    if (!validator.validate(this.email.current.value)) {
      this.setState({ errors: ["Please enter a valid email address"] });
      return;
    }

    let params = {
      email: this.email.current.value,
      password: this.password.current.value,
      confirm_password: this.password_confirmation.current.value
    };
    // if (this.token.current.value.length > 0) {
    //   params["invite_code"] = this.token.current.value;
    // }

    let apiRequest = new ApiRequest("post", "/user/signup");
    apiRequest.send(params, (response) => {
      let result = response;
      if (result.errors) {
        this.setState({ errors: result.message });
      } else {
        localStorage.setItem("gorilla-token", result.token);

        location.href = "/";
      }
    });
  }
}

export default SignUp;
