import React from 'react';
import './MetaDataCard.css'; // Import CSS for styling

const MetaDataCard = ({ metaData }) => {
  if (!metaData) return null; // Render nothing if metadata is not available

  return (
    // <div className="metadata-card">
    <div style={{
      cursor: 'pointer',
    }}
      onClick={() => {
        if (metaData.url) {
          window.open(metaData.url, '_blank');
        }
      }}
    >
      {metaData.image && (
        <img className="metadata-card-image" src={metaData.image} alt="Metadata" />
      )}
      <div className="metadata-card-content">
        <h2 className="metadata-card-title">{metaData.title}</h2>
        <p className="metadata-card-description">{metaData.description}</p>
    
      </div>
    </div>
  );
};

export default MetaDataCard;
