import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { toast } from "react-toastify";

function UsersList(_props) {
  let _table = [];
  _props.users.map((user, idx) => {
    var _btnCss =
      _props.pending.indexOf(user.user.id.toString()) > -1 ||
      user.user.is_friend ||
      user.user.is_pending_friend
        ? "requested"
        : "";
    var _text =
      _props.pending.indexOf(user.user.id.toString()) > -1 ||
      user.user.is_pending_friend
        ? "REQUESTED"
        : "VOUCHER REQUEST";
    if (user.user.is_friend) {
      _text = "REMOVE";
    }

    _table.push(
      <tr key={idx}>
        <td>
          <img src={user.user.profile_photo} height="40" width="40" />
        </td>
        <td>
          <b>
            {user.user.first_name} {user.user.last_name}
          </b>
        </td>
        <td>
          <button
            id={user.user.id}
            className={"actionbtn " + _btnCss}
            type="button"
            onClick={_props.onButtonClick}
          >
            {_text}
          </button>
        </td>
      </tr>
    );
  });
  return (
    <table className="users-list">
      <tbody>{_table}</tbody>
    </table>
  );
}

class VoucherRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      pending: [],
      remaining: this.props.user.voucher_requests_remaning,
    };
    if (
      !this.props.skipVerificaiton &&
      (this.props.user.is_verified || this.props.user.friends_count >= 2)
    ) {
      window.location.href = "/";
    }
  }

  render() {
    var header = (
      <div>
        <h2>Voucher Request</h2>
        <b>Request a Voucher</b>
        <br />
        To have full app access, please request a voucher from at least two
        users. You can request up to {this.state.remaining} vouchers only.
      </div>
    );
    if (this.props.skipVerificaiton) {
      header = "";
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-9" style={{ margin: "auto" }}>
            {header}
            <input
              type="text"
              className="voucher-search"
              placeholder="Search"
              onKeyUp={this.handleSearch.bind(this)}
            />
            <UsersList
              users={this.state.users}
              pending={this.state.pending}
              onButtonClick={this.handleVoucherClick.bind(this)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleVoucherClick(e) {
    if (e.target.innerText == "REQUESTED") {
      toast.success("Request has already been made");
      return;
    }
    let _pending = this.state.pending;
    if (_pending.length < 10) {
      var api = new ApiRequest("post", "/friends");
      var id = e.target.id;
      api.send({ follower_id: id }, () => {
        _pending.push(id);
        this.setState({
          pending: _pending,
          remaining: this.state.remaining - 1,
        });
      });
    } else {
      toast.error("You can not make more than 10 voucher requests");
    }
  }

  handleSearch(e) {
    let users = [];
    var api = new ApiRequest(
      "get",
      "/users.json?min=1&search=" + e.target.value
    );
    api.send({}, (response) => {
      users = response.data.data.results;
      this.setState({ users: users });
    });
  }

  componentDidMount() {
    let users = [];
    var api = new ApiRequest("get", "/users.json?page=1&min=1");
    api.send({}, (response) => {
      users = response.data.data.results;
      this.setState({ users: users });
    });
  }
}

export default VoucherRequest;
