import React, { Component } from "react";
//import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Feed from "./components/main/Feed";
import Profile from "./components/main/Profile";
import Projects from "./components/projects/Projects";
// import NewProject from "./components/projects/NewProject";
import MoreInfo from "./components/profile/MoreInfo";
import Occupations from "./components/profile/Occupations";
import VoucherRequest from "./components/profile/VoucherRequest";
import Messages from "./components/main/Messages";
import Invites from "./components/main/Invites";
import Friends from "./components/main/Friends";
import Faq from "./components/main/Faq";
import Privacy from "./components/main/Privacy";
import Companies from "./components/main/Companies";
import ApiRequest from "./libs/request";
import UserProfile from "./components/main/UserProfile";
import PostQuestions from "./components/main/PostQuestions";
import ReviewRequests from "./components/main/ReviewRequests";
import EditWorkHistory from "./components/main/EditWorkHistory";
import EditScreenPlay from "./components/main/EditScreenPlay";
import EditMusic from "./components/main/EditMusic";
import EditReels from "./components/main/EditReels";
import UserProflile from "./components/profile/UserProfile";
import Info from "./components/main/Info";
import OnProfile from "./components/onboarding/OnProfile";
import OnProjects from "./components/onboarding/OnProjects";
import OnProjectList from "./components/onboarding/OnProjectList";
import Calendar from "./components/main/Calendar";
import PromotePost from "./components/main/PromotePost";
import EditProject from "./components/main/EditProject";
import NoMatchPage from "./components/NoMatchPage";
import SeekingCrew from "./components/main/SeekingCrew";
import SeekingWork from "./components/main/SeekingWork";
import SeekingScreenPlay from "./components/main/SeekingScreenPlay";
import SeekingMusic from "./components/main/SeekingMusic";
import SeekingInvestment from "./components/main/SeekingInvestment";
import NewPostProject from "./components/main/NewPostProject";
import SellItem from "./components/main/SellItem";
import FilmMarket from "./components/film-market/FilmMarket";
import WatchFilms from "./components/films/WatchFilms";
import AddFilm from "./components/films/AddFilms";
import MyFilms from "./components/films/MyFilms";
import MyList from "./components/films/MyList";
import BuyAds from "./components/ads/BuyAds";
import MyAds from "./components/ads/MyAds";
import FilmDetails from "./components/films/FilmDetails";
import FilmsByCategory from "./components/films/FilmsByCategory";
import TermsAndConditions from "./components/main/TermsConditions";
// import TopSlidingText from "./components/shared/top-sliding-text/TopSlidingText";

const PrivateRoute = ({ user, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("gorilla-token") != null ? (
        <>
        {/* <TopSlidingText /> */}
        <Component {...props} user={user} />
        </>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);

    var routes = [
      { path: "/", component: Feed },
      { path: "/profile", component: UserProflile },
      { path: "/calendar", component: Calendar },
      { path: "/projects", component: Projects },
      // { path: "/projects/new", component: NewProject },
      { path: "/projects/create", component: EditProject },
      { path: "/projects/edit/:id", component: EditProject },
      { path: "/profile/more-info", component: MoreInfo },
      { path: "/profile/occupations", component: Occupations },
      { path: "/profile/vouchers", component: VoucherRequest },
      { path: "/profile/voucher-request", component: VoucherRequest },
      { path: "/messages", component: Messages },
      { path: "/profile/:user/edit", component: Profile },
      { path: "/profile/:user/info", component: Info },
      { path: "/friends", component: Friends },
      { path: "/invites", component: Invites },
      { path: "/faq", component: Faq },
      { path: "/privacy", component: Privacy },
      { path: "/terms-conditions", component: TermsAndConditions },
      { path: "/companies", component: Companies },
      { path: "/companies/edit/:id", component: Companies },
      { path: "/user/profile/:id", component: UserProfile },
      { path: "/posts/new/:type_id", component: PostQuestions },
      { path: "/posts/edit/:type_id/:post_id", component: PostQuestions },
      { path: "/review-requests", component: ReviewRequests },
      { path: "/profile/0/workhistory", component: EditWorkHistory },
      { path: "/profile/edit_screenplay/:id", component: EditScreenPlay },
      { path: "/profile/edit_music/:id", component: EditMusic },
      { path: "/profile/edit_reels/:id", component: EditReels },

      { path: "/seeking/crew", component: SeekingCrew },
      { path: "/seeking/music", component: SeekingMusic },
      { path: "/seeking/work", component: SeekingWork },
      { path: "/seeking/investment", component: SeekingInvestment },
      { path: "/seeking/screenplay", component: SeekingScreenPlay },
      { path: "/new-project", component: NewPostProject },

      { path: "/profile/new_screenplay", component: EditScreenPlay },
      { path: "/profile/new_music", component: EditMusic },
      { path: "/profile/new_reels", component: EditReels },

      { path: "/onboarding/profile", component: OnProfile },
      { path: "/onboarding/projects", component: OnProjects },
      { path: "/onboarding/projects_list", component: OnProjectList },

      { path: "/posts/promote/:id", component: PromotePost },
      { path: "/posts/sell", component: SellItem },

      { path: "/film-market", component: FilmMarket},
      { path: "/watch-films", component: WatchFilms},
      { path: "/add-film", component: AddFilm},
      { path: "/my-films", component: MyFilms},
      { path: "/my-list", component: MyList},
      { path: "/film-details/:id", component: FilmDetails},
      { path: "/films-by-category", component: FilmsByCategory},

      { path: "/buy-ads", component: BuyAds},
      { path: "/my-ads", component: MyAds},

      { path: null, component: NoMatchPage },
    ];
    this.state = { current_user: null, routes: routes };
  }

  render() {
    if (this.state.current_user == null) {
      return <div />;
    }

    var _privateRoutes = [];
    this.state.routes.map((r, idx) => {
      _privateRoutes.push(
        <PrivateRoute
          key={idx}
          path={r.path}
          user={this.state.current_user}
          component={r.component}
          exact
        />
      );
    });
    return (
      <div>
        <BrowserRouter>
          <Switch>{_privateRoutes}</Switch>
        </BrowserRouter>
        {/* <p>&nbsp;</p> */}
      </div>
    );
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    var token = params.get("token");
    if (token) {
      localStorage.setItem("gorilla-token", token);
    }

    const api = new ApiRequest("get", "/user/view-profile");
    api.send({}, (response, status) => {
      if (status != undefined && status != 200) {
        localStorage.clear();
        location.href = "/login";
      } else {
        this.setState({ current_user: response.data.user });
      }
    });
  }
}

export default App;
