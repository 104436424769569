import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { toast } from "react-toastify";

function ProjectList(props) {
  if (props.projects.length < 1) {
    return <div></div>;
  }

  const items = props.projects.map((p, idx) => (
    <div className="project-item" key={"item-" + idx}>
      <div
        className="image"
        onClick={() => (location.href = "/projects/edit/" + p.post.id)}
        style={{ backgroundImage: "url(" + Object(p.post.photos[0]).url + ")" }}
        key={idx}
      ></div>
      <div className="image-controls" key={"ctr" + idx}>
        <div className="title">{p.post.body}</div>
        <div className="icons">
          CREATED {Object(p.post).created_at}
          <i
            className="fas fa-trash"
            onClick={() => props.onDeleteClicked(p.post.id)}
          />
        </div>
      </div>
    </div>
  ));
  return <div className="project-list">{items}</div>;
}

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = { projects: [] };
    this.loadProjects();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid header small">
          <div className="logo"></div>
        </div>
        <div className="row justify-content-center projects">
          <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
            <div className="hdr">
              <h2>Your Projects</h2>
              <a href="/projects/new">
                <i className="fas fa-plus-circle" /> Add new project
              </a>
            </div>
            <ProjectList
              projects={this.state.projects || []}
              onDeleteClicked={this.handleDelete.bind(this)}
            />
            <button
              type="button"
              className="btn g-button"
              onClick={this.handleContinue.bind(this)}
            >
              Continue
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  loadProjects() {
    let api = new ApiRequest(
      "get",
      "/posts?post_type=13&page=1&per=20&promoted=false"
    );
    api.send_file({}, (response) => {
      this.setState({ projects: response.data.data.results });
    });
  }

  handleContinue() {
    if (this.state.projects.length < 1) {
      toast.error("You must add at least one project");
      return;
    } else {
      location.href = "/profile/more-info";
    }
  }

  handleDelete(id) {
    if (confirm("Are you sure?")) {
      let api = new ApiRequest("delete", "/posts/" + id + ".json");
      api.send({}, (response) => {
        this.loadProjects();
      });
    }
  }
}

export default Projects;
