import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import ProjectForm from "../projects/ProjectForm";
import ScreenPlayForm from "../projects/ScreenPlayForm";
import MusicProjectForm from "../projects/MusicProjectForm";
import ReelsForm from "../projects/ReelsForm";
import { time_ago } from "../../libs/functions";

function ProjectList(props) {
  if (props.projects.length < 1) {
    return <div></div>;
  }
  var _items = [];

  props.projects.map((p, idx) => {
    var _title = p.post.body;
    var _imdb = "";
    var _trailer = "";
    if (p.post.meta_data && p.post.meta_data["title"]) {
      _title = p.post.meta_data["title"];
    }
    if (p.post.meta_data && p.post.meta_data["imdb_url"]) {
      _imdb = p.post.meta_data["imdb_url"];
    }
    if (p.post.meta_data && p.post.meta_data["imdb_link"]) {
      _imdb = p.post.meta_data["imdb_link"];
    }
    if (p.post.meta_data && p.post.meta_data["trailer_video"]) {
      _trailer = p.post.meta_data["trailer_video"];
    }

    //   var _desc = (p.post.meta_data && p.post.meta_data["description"]) ? p.post.meta_data["description"] : "";

    _items.push(
      <div
        className="project-item float-left"
        key={"item-" + idx}
        style={{ maxHeight: "459px", height: "auto" }}
      >
        <div
          className="image"
          style={{
            backgroundImage: "url(" + Object(p.post.photos[0]).url + ")",
          }}
          key={idx}
        ></div>
        <div
          className="image-controls"
          key={"ctr" + idx}
          style={{ height: "90px" }}
        >
          <div className="title">{_title}</div>
          <br />
          <div
            style={{
              float: "left",
              clear: "both",
              marginLeft: "12px",
              fontSize: "14px",
            }}
          >
            <a href={_imdb} target="_blank">
              {_imdb}
            </a>
            <br />
            <a href={_trailer} target="_blank">
              {_trailer}
            </a>
          </div>
          <div className="icons">
            created {time_ago(Object(p.post).created_at)}
            <i
              className="fas fa-trash"
              onClick={() => props.onDeleteClicked(p.post.id)}
            />
          </div>
        </div>
      </div>
    );
  });
  return <div className="project-list">{_items}</div>;
}

class OnProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      post_type: 13,
      tabs: [
        { name: "Projects", post_type: 13 },
        { name: "Screenplays", post_type: 5 },
        { name: "Music", post_type: 9 },
        { name: "Reels", post_type: 11 },
      ],
      new_post_type: null,
    };
    this.loadProjects();
  }

  render() {
    if (this.state.new_post_type != null) {
      return this.projectView();
    } else {
      return this.renderList();
    }
  }

  handleCancel() {
    this.setState({ post_type: 13, new_post_type: null });
  }

  projectView() {
    var _view = null;
    var _banner = null;

    if (!this.state.post_type) {
      _view = this.renderChoose();
    } else {
      //_banner = ()
    }

    if (this.state.new_post_type == "13") {
      _view = (
        <ProjectForm
          onCancel={this.handleCancel.bind(this)}
          onAfterSave={() =>
            (window.location.href = "/onboarding/projects_list")
          }
        />
      );
    }
    if (this.state.new_post_type == "5") {
      _view = (
        <ScreenPlayForm
          onCancel={this.handleCancel.bind(this)}
          onAfterSave={() =>
            (window.location.href = "/onboarding/projects_list")
          }
        />
      );
    }
    if (this.state.new_post_type == "9") {
      _view = (
        <MusicProjectForm
          onCancel={this.handleCancel.bind(this)}
          onAfterSave={() =>
            (window.location.href = "/onboarding/projects_list")
          }
        />
      );
    }
    if (this.state.new_post_type == "11") {
      _view = (
        <ReelsForm
          onCancel={this.handleCancel.bind(this)}
          onAfterSave={() =>
            (window.location.href = "/onboarding/projects_list")
          }
        />
      );
    }

    return (
      <React.Fragment>
        <div className="container-fluid header">
          <div className="logo"></div>
        </div>
        {_view}
      </React.Fragment>
    );
  }

  renderList() {
    var _tabs = this.state.tabs;
    var _proj = "project";
    var _tabs_str = [];
    _tabs.map((t, idx) => {
      var clsname = "inverted";
      if (this.state.post_type == t.post_type) {
        clsname = "inverted-on";
        _proj = t.name;
      }

      _tabs_str.push(
        <button
          index={idx}
          onClick={() => this.setState({ post_type: t.post_type })}
          className={"g-button " + clsname + " w-177"}
        >
          {t.name}
        </button>
      );
    });

    return (
      <React.Fragment>
        <div className="container-fluid header small">
          <div className="logo"></div>
        </div>
        <div className="row justify-content-center projects">
          <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
            <div className="hdr">
              <h2>Work History</h2>
              <a
                href="#"
                onClick={() =>
                  this.setState({ new_post_type: this.state.post_type })
                }
              >
                <i className="fas fa-plus-circle" /> Add new {_proj}
              </a>
            </div>
            <div className="mb-4 project-navs">{_tabs_str}</div>
            <ProjectList
              projects={this.state.projects || []}
              onDeleteClicked={this.handleDelete.bind(this)}
            />
            <button
              type="button"
              className="btn g-button"
              onClick={this.handleContinue.bind(this)}
            >
              Continue
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  loadProjects() {
    let api = new ApiRequest(
      "get",
      "/posts?user_id=" +
        this.props.user.id +
        "&post_type=" +
        this.state.post_type +
        "&page=1&per=20&promoted=false"
    );
    api.send_file({}, (response) => {
      this.setState({ projects: response.data.data.results });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.post_type != prevState.post_type) {
      this.loadProjects();
    }
  }

  handleContinue() {
    // if (this.state.projects.length < 1) {
    //   alert("You must add at least one project");
    //   return;
    // } else {
    location.href = "/profile/more-info";
    // }
  }

  handleDelete(id) {
    if (confirm("Are you sure?")) {
      let api = new ApiRequest("delete", "/posts/" + id + ".json");
      api.send({}, (response) => {
        this.loadProjects();
      });
    }
  }
}

export default OnProjectsList;
