import React, { Component } from "react";
import ApiRequest from "../../libs/request";

class WorkHistory extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], tab: "Projects" };
  }

  render() {
    var _tabs = ["Projects", "Screenplays", "Music", "Reels"];
    var _li = [];
    _tabs.map((t, idx) => {
      var _active = this.state.tab == t ? "active" : "";
      _li.push(
        <li
          key={idx}
          onClick={() => {
            this.loadProjects(t);
          }}
          className={_active}
        >
          {t}
        </li>
      );
    });

    var _data = [];
    var tabFields = {
      Music: [
        "Instrumentation",
        "Production Genre",
        "Mood / Emotion",
        "Musical Style",
        "Select Duration",
        "Tempo",
      ],
      Screenplays: ["Page Count", "LogLine", "SYNOPSIS", "Select Film Genre"],
    };

    var _createUrl = "/projects/create";
    if (this.state.tab == "Screenplays") {
      _createUrl = "/profile/new_screenplay/";
    }
    if (this.state.tab == "Music") {
      _createUrl = "/profile/new_music";
    }
    if (this.state.tab == "Reels") {
      _createUrl = "/profile/new_reels";
    }

    this.state.data.map((d, idx) => {
      var _meta_data = [];
      var _link = d.imdb || d.link || "";
      var _title = d.name || d.title || "";
      var _trailer = d.trailer || "";

      var hasPoster = "block";
      if (this.state.tab == "Music" || this.state.tab == "Reels") {
        hasPoster = "none";
      }
      

      var delete_edit = [];
      if (this.props.editable) {
        var _editUrl = "/projects/edit/" + d.id;

        if (this.state.tab == "Screenplays") {
          _editUrl = "/profile/edit_screenplay/" + d.id;
        }
        if (this.state.tab == "Music") {
          _editUrl = "/profile/edit_music/" + d.id;
        }
        if (this.state.tab == "Reels") {
          _editUrl = "/profile/edit_reels/" + d.id;
        }
        delete_edit.push(
          <div className="item-controls" key={"ctrls-" + idx}>
            <i
              className="fas fa-edit"
              onClick={() => (window.location.href = _editUrl + "?profile=1")}
            />
            <i
              className="fas fa-trash"
              onClick={() => this.handleDeletePost(d.id, this.state.tab)}
            />
          </div>
        );
      }
      var _desc = d.description || "";
      var _photo = "";
      if (typeof d.media == "string") {
        _photo = (
          <div
            className="poster"
            style={{
              backgroundImage: 'url("' + d.media + '")',
              display: hasPoster,
            }}
          ></div>
        );
      }
      if (typeof d.media != "string" && d.media && d.media.length > 0) {
        _photo = (
          <div
            className="poster"
            style={{
              backgroundImage:
                'url("' +
                d.media[d.media.length - 1] +
                '")',
              display: hasPoster,
            }}
          ></div>
        );
      }
      _data.push(
        <div key={idx} className="project-item-box">
          {_photo}
          <div className="title">{_title}</div>
          <div className="meta">
            <a href={_link} target="_blank">
              {_link}
            </a>
            <br />
            <a href={_trailer} target="_blank">
              {_trailer}
            </a>
          </div>
          {_meta_data}
          <div className="description">
            {_desc}
            {delete_edit}
          </div>
        </div>
      );
    });

    var _addnew = (
      <a href={_createUrl + "?profile=1"} className="add-new-sub-tab">
        <i className="fas fa-plus-circle"></i> Add new{" "}
      </a>
    );
    if (!this.props.editable) {
      _addnew = "";
    }

    return (
      <React.Fragment>
        <ul className="profile-sub-tab">{_li}</ul>
        {_addnew}
        <div className="col-12">{_data}</div>
      </React.Fragment>
    );
  }

  handleDeletePost(postId, type) {
    if (confirm("Are you sure you want to delete?")) {
      if (type === "Screenplays") {
        let api = new ApiRequest("post", "/user/delete-screenplay");
        api.send({
          id: postId,
        }, () => {
          this.loadProjects(this.state.tab);
        });
      }

      if (type === "Music") {
        let api = new ApiRequest("post", "/user/delete-music");
        api.send({
          music_id: postId,
        }, () => {
          this.loadProjects(this.state.tab);
        });
      }

      if (type === "Reels") {
        let api = new ApiRequest("post", "/user/delete-reel");
        api.send({
          reel_id: postId,
        }, () => {
          this.loadProjects(this.state.tab);
        });
      }

      else {
        let api = new ApiRequest("post", "/user/delete-project");
        api.send({
          project_id: postId,
        }, () => {
          this.loadProjects(this.state.tab);
        });
      }
    }
  }

  loadProjects(type) {
    var _post_type = 13;

    // if url is like /user/profile/ then don't hit api and get data from props of user
    if (window.location.href.indexOf("/user/profile/") > -1 && this.props.user) {
      if (type === "Projects") {
        this.setState({ tab: type, data: this.props.user.projects });
      }
      if (type === "Screenplays") {
        this.setState({ tab: type, data: this.props.user.screenplays });
      }
      if (type === "Music") {
        this.setState({ tab: type, data: this.props.user.music });
      }
      if (type === "Reels") {
        this.setState({ tab: type, data: this.props.user.reels });
      }
      return;
    }

    var api = new ApiRequest(
      "get",
      "/user/my-projects"
    );

    if (type === "Projects") {
      api.url = "/user/my-projects";
      api.send({}, (result) => {
        this.setState({ tab: type, data: result.data.projects });
      });
    }
    if (type === "Screenplays") {
      api.url = "/user/screenplays";
      api.send({}, (result) => {
        this.setState({ tab: type, data: result.data.screenplays });
      });
    }
    if (type === "Music") {
      api.url = "/user/my-music";
      api.send({}, (result) => {
        this.setState({ tab: type, data: result.data.music });
      });
    }
    if (type === "Reels") {
      api.url = "/user/my-reels";
      api.send({}, (result) => {
        this.setState({ tab: type, data: result.data.reels });
      });
    }


  }

  // componentDidUpdate() {
  //   if(this.state.tab === "Projects") {
  //     this.loadProjects(2);
  //   }
  //   if(this.state.tab == "Screenplays") {
  //     this.loadProjects(5);
  //   }
  // }

  componentDidMount() {
    this.loadProjects(this.state.tab);
  }
}
export default WorkHistory;
