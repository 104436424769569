import React, { Component } from "react";
import ApiRequest from "../../libs/request";

class SettingsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { user: null, nstate: true };
  }

  render() {
    var notifications = this.state.nstate
      ? "/images/on.png"
      : "/images/off.png";
    return (
      <div className="settings-panel">
        <a href={"/user/profile/" + this.props.user.id + "#profile"} className="label" style={{
          height: "43px",
        }} >
          {/* <div className="SP-user-icon">
            <i className="fas fa-user"></i>
          </div>
          My Profile */}
          <img src={this.props.userImage} className="SP-user-profile-img" />
          <span style={{
            fontSize: "18px",
          }}>
            {this.props.userName}
          </span>
        </a>
        <div className="sep" />
        <a href="/invites" className="label">
          <img src="/images/send-invites.png" />
          Send Invitations
        </a>
        <div className="sep" />
        <a href="/faq" className="label">
          <img src="/images/faq.png" />
          FAQs
        </a>
        <div className="sep" />

        <a href="/privacy" className="label">
          <img src="/images/privacy-policy.png" />
          Privacy Policy
        </a>
        <div className="sep" />
        <a href="/terms-conditions" className="label">
          <img src="/images/privacy-policy.png" />
          Terms And Conditions
        </a>
        <div className="sep" />
        <div className="label" onClick={this.handleDeleteAccount.bind(this)}>
          <img src="/images/delete-account.png" />
          Delete Account
        </div>
        <div className="sep" />
        <div className="label" onClick={this.handleSignout.bind(this)}>
          <img src="/images/logout.png" />
          Logout
        </div>
      </div>
    );
  }

  handleDeleteAccount() {
    if (
      confirm(
        "Are you sure you want to delete your account? This can not be un-done."
      )
    ) {
      var api = new ApiRequest("delete", "/user/delete-account");
      api.send({}, () => {
        this.handleSignout();
      });
    }
  }

  handleSignout() {
    // var api = new ApiRequest("delete", "/sessions/" + this.props.user.id);
    // api.send({}, () => {
      localStorage.removeItem("gorilla-token");
      localStorage.removeItem("current_user");
      window.location.href = "/";
    // });
  }

  componentDidMount() {
    this.setState({
      user: this.props.user,
      nstate: this.props.user.push_notifications,
    });
  }

  handleToggleNotifications() {
    var notifications = !this.state.nstate;
    var api = new ApiRequest("patch", "/users/0");
    api.send({ push_notifications: notifications }, () => {
      var _user = this.state.user;
      _user["push_notifications"] = notifications;
      this.setState({ user: _user, nstate: notifications });
    });
  }
}

export default SettingsPanel;
