import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import ProfileStats from "./ProfileStats";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import InfoForm from "../profile/InfoForm";
import { _backgroundImagePrompt } from "../../libs/constants.js";

function DownloadLink(_props) {
  if (_props.resume != "") {
    return (
      <div className="location">
        <a href={_props.resume} onClick={() => _props.onDownloadClick}>
          <u>download</u>
        </a>
      </div>
    );
  }
}

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tab: "#profile",
      reviews: [],
      tabs: [
        {
          url: "edit",
          icon: "fa-user",
          name: "Personal Info",
        },
        {
          url: "workhistory",
          icon: "fa-video",
          name: "Work History",
        },
        {
          url: "info",
          icon: "fa-star",
          name: "Addtional Info",
        },
      ],
    };
  }

  render() {
    if (!this.state.id) {
      return <div />;
    }

    var occupation = 
      this.state.occupations && this.state.occupations.length > 0 ? (
        this.state.occupations[0].name
      ) : (
        <p>&nbsp;</p>
      )
    
      var occupation_counts = 
        this.state.occupations && this.state.occupations.length > 0 ? (
          this.state.occupations.length-1
        ) : (0);

    var _errors = <div />;
    if (this.state.errors) {
      _errors = <div className="alert alert-danger">{this.state.errors}</div>;
    }

    var _tabs = [];
    this.state.tabs.map((t, idx) => {
      var _active = "info" === t.url ? "active" : "";
      _tabs.push(
        <li key={idx} className={_active}>
          <a
            className={_active}
            onClick={() => {
              this.setState({ tab: t.url });
            }}
            href={"/profile/0/" + t.url}
          >
            <i className={"fas " + t.icon} />
            <br />
            {t.name}
          </a>
        </li>
      );
    });

    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12">
            <div
              className="row justify-content-center"
              style={{ float: "left" }}
            >
              <div
                className="col-9"
                style={{
                  marginTop: "108px",
                  marginLeft: "30px",
                  maxWidth: "unset",
                }}
              >
                <div className="profile-form">
                  <div className="title"></div>
                  <div className="upload" style={{ height: "275px" }}>
                    <div className="image-upload">
                      <div id="backgroundPrompt" style={_backgroundImagePrompt}>Upload background image</div>
                    </div>
                    <div className="camera-btn" style={{ float: "left" }}>
                      <a href="#" id="avatar"></a>
                    </div>
                    <div
                      className="profile-id"
                      style={{
                        float: "unset",
                        marginTop: "-50px",
                        marginLeft: "152px",
                      }}
                    >
                      <b>
                        {this.state.first_name} {this.state.last_name}
                      </b>
                      <br />
                      <span style={{ fontSize: "16px" }}>{occupation} <span className={occupation_counts > 0 ? '' : 'hidden'}> & {occupation_counts} more</span></span>
                    </div>

                    <div className="stats" style={{ float: "left" }}></div>
                    <div
                      className="prompt"
                      style={{
                        marginRight: "0px",
                        textAlign: "right",
                        width: "255px",
                        marginTop: "9px",
                      }}
                    >
                      <ProfileStats user={this.state} showRequestButton={false} />
                    </div>
                  </div>
                  <div
                    className="col-12 profile-tabs"
                    style={{ float: "left" }}
                  >
                    <ul>{_tabs}</ul>
                  </div>
                  <div
                    className="profile-fields projects"
                    style={{ marginTop: "180px" }}
                  >
                    <InfoForm />
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    let api = new ApiRequest("get", "/user/view-profile");
    api.send({}, (response) => {
      let result = response.data;
      var user = result.user;
      this.setState(user);
      if (user.cover) {
        document.querySelector("#backgroundPrompt").style.display = "none";
        document.querySelector(".image-upload").style.backgroundImage =
          "url('" + user.cover + "')";
      }
      if (user.profile_photo) {
        document.querySelector("#avatar").style.display = "none";
        document.querySelector(".camera-btn").style.backgroundImage =
          "url('" + user.profile_photo + "')";
      }

      this.setState(user);
      // api.url = "/users/" + user.id + "/ratings";
      // api.send({}, (resp) => {
      //   user["reviews"] = resp.data.data.paging.total_count;
      //   this.setState(user);
      // });
    });
  }
}

export default Info;
