import React, { Component, useRef, useEffect } from "react";
import ApiRequest from "../../libs/request.js";
import Post from "./Post";
import PhotoGallery from "./PhotoGallery";

const BannerComponent = () => {
    const banner = useRef(null);

    useEffect(() => {
        if (banner.current && !banner.current.firstChild) {
            // Create the script element for the new ad
            const script = document.createElement("script");
            script.src =
                "//pl24904580.profitablecpmrate.com/ac44f92973042c59ced26542511e4c4c/invoke.js";
            script.async = true;
            script.setAttribute("data-cfasync", "false");

            // Create the div container for the ad
            const adContainer = document.createElement("div");
            adContainer.id = "container-ac44f92973042c59ced26542511e4c4c";

            // Append the script and div to the banner element
            banner.current.appendChild(adContainer);
            banner.current.appendChild(script);
        }
    }, []);

    return (
        <div
            style={{
                marginTop: "20px",
            }}
        >
            <div ref={banner} />
        </div>
    );
};

class FeedList extends Component {
    constructor(props) {
        super(props);
        this.state = { current_page: 1, comments: [] };
    }

    render() {
        const items = [];

        this.props.posts.forEach((obj, idx) => {
            let _post = obj;
            let _asset = null;

            if (_post.media.length > 0) {
                _asset = <PhotoGallery post={_post} />;
            }
            if (_post.video && _post.video.length > 0) {
                _asset = (
                    <video controls>
                        <source src={_post.video[0].url} />
                    </video>
                );
            }
            if (_post.user) {
                items.push(
                    <Post key={`post-${idx}`} post={_post} user={this.props.user} asset={_asset} />
                );
            }

            // Insert BannerComponent after the first post
            if (idx === 0) {
                items.push(
                    <>
                        <div
                            key={`banner-first`}
                            style={{
                                width: 500,
                                height: 413,
                                margin: "0 auto",
                            }}
                        >
                            <BannerComponent />
                        </div>
                        <div
                            key={`banner-${idx}`}
                            style={{
                                width: 500,
                                height: 413,
                                margin: "0 auto",
                                marginTop: 35,
                                backgroundImage: "url(./images/Advertisement.png)",

                            }}
                        >
                            <BannerComponent />
                        </div>
                    </>
                );
            }

            // Insert BannerComponent after every 3 subsequent posts
            if ((idx + 1) % 3 === 0 && idx !== 0) {
                items.push(
                    <div
                        key={`banner-${idx}`}
                        style={{
                            width: 500,
                            height: 413,
                            margin: "0 auto",
                            marginTop: 35,
                            backgroundImage: "url(./images/Advertisement.png)",

                        }}
                    >
                        <BannerComponent />
                    </div>
                );
            }
        });

        return <div>{items}</div>;
    }
}

export default FeedList;
