import React from "react";

class Oauth extends React.Component {
  constructor(props) {
    super(props);
    let search = window.location.search;
    let params = new URLSearchParams(search);
    localStorage.setItem("gorilla-token", params.get("id"));
    window.location.href = "/";
  }

  render() {
    return <div />;
  }
}

export default Oauth;
