import React, { Component } from "react";
import Nav from "./Nav";
import ApiRequest from "../../libs/request";
import FeedNav from "./FeedNav";
import LocationSeach from "./LocationSearch";
import Modal from "react-modal";
import { validFile } from "../../libs/functions";
import { FaTimesCircle } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    minHeight: "400px",
    transform: "translate(-50%, -50%)",
    width: "600px",
    padding: "0px",
    borderRadius: "10px",
  },
};

function FilePreview(_props) {
  if (_props.files.length > 0) {
    var _prevs = [];
    var items = Array.from(_props.files);
    for (var i = 0; i < items.length; i++) {
      _prevs.push(
        <div
          className="img"
          style={{
            backgroundImage: "url('" + URL.createObjectURL(items[i]) + "')",
          }}
        >
          <i
            className="fas fa-trash"
            title="Remove"
            data-index={i}
            onClick={(e) => _props.onDelectClick(e)}
          />
        </div>
      );
    }
    return _prevs;
  }
  return <div />;
}

class SellItem extends Component {
  constructor(props) {
    super(props);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.state = {
      errors: [],
      desc: "",
      price: "",
      location: [],
      files: [],
      showModal: false,
      tiers: [],
      tier: null,
      post: {},
    };
    this.handleSelectPrice = this.handleSelectPrice.bind(this);
    this.card = null;
    this.stripe = null;
  }

  render() {
    var _showErrors = this.state.errors.length > 0 ? "block" : "none";
    var _errorStr = this.state.errors.join(" , ");
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
              <form className="seeking container">
                <h4>Sell an item</h4>
                <div
                  className="alert alert-danger"
                  style={{ display: _showErrors }}
                >
                  {_errorStr}
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        className="form-control"
                        placeholder="Type here"
                        onChange={(e) =>
                          this.setState({ desc: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Product Price *</label>
                      <input
                        type="text"
                        placeholder="0.00"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ price: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Preferred Location</label>
                      <LocationSeach
                        onLocationSelected={(loc) =>
                          this.setState({ location: loc })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Photo</label>
                      <input
                        type="file"
                        onChange={this.handleAttachementPreview.bind(this)}
                        id="photo"
                        style={{ visibility: "hidden", height: "0px" }}
                      />
                      <br />
                      <div
                        className="messages"
                        style={{
                          float: "left",
                          marginBottom: "12px",
                          minHeight: "0px",
                        }}
                      >
                        <img
                          style={{ cursor: "pointer", float: "left" }}
                          className="camera-btn"
                          src="/images/status-camera.png"
                          onClick={this.handleFileClick.bind(this)}
                        />
                        <div
                          className="attachment-prev"
                          style={{ float: "left", marginLeft: "12px" }}
                        >
                          {" "}
                          <FilePreview
                            onDelectClick={(e) =>
                              this.handleRemoveAttachment(e)
                            }
                            files={this.state.files}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={this.handleSave.bind(this)}
                        className="g-button full"
                      >
                        Add Sell/Promote Post
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showModal}
          style={customModalStyles}
          contentLabel="Reviews"
          shouldCloseOnOverlayClick={true}
          overlayClassName="overlay"
        >
          <div className="modalHeader">
            {this.state.modalComponent}
            <div className="modalClose">
              <FaTimesCircle onClick={this.handleCloseModal.bind(this)} />
            </div>
          </div>
          <div className="modalContent">{this.renderPaymentForm()}</div>
        </Modal>
      </React.Fragment>
    );
  }

  renderPaymentForm() {
    if (!this.state.tier) {
      return this.priceList();
    } else {
      return this.stripeView();
    }
  }

  stripeView() {
    return (
      <React.Fragment>
        <input type="hidden" id="tier" defaultValue={this.state.tier.id} />
        <form id="payment-form">
          <div id="card-element"></div>

          <div id="card-errors" role="alert"></div>

          <button
            id="submit"
            type="button"
            onClick={this.handleSubmit.bind(this)}
            className="g-button full mt-4"
          >
            Pay
          </button>
        </form>
      </React.Fragment>
    );
  }

  handleSubmit() {
    var errorElement = document.getElementById("card-errors");
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        // Inform the customer that there was an error.

        errorElement.textContent = result.error.message;
      } else {
        var api = new ApiRequest("post", "/posts");

        var formData = new FormData();
        for (var i = 0; i < this.state.files.length; i++) {
          formData.append("post[assets][][data]", this.state.files[i]);
          formData.append("post[assets][][type]", "photo");
        }
        formData.append("post[post_type]", 8);
        formData.append("post[body]", this.state.desc);
        formData.append("post[longitude]", this.state.location[0]);
        formData.append("post[latitude]", this.state.location[1]);
        formData.append("post[stripe_customer_token]", result.token.id);
        formData.append("post[sponsored]", true);
        formData.append("post[ad_tier_id]", this.state.tier.id);
        var answers = [];
        answers.push({
          question_id: 27,
          textAnswer: this.state.desc,
          answer: this.state.desc,
        });
        answers.push({
          question_id: 30,
          textAnswer: this.state.price,
          answer: this.state.price,
        });

        answers.map((t, idx) => {    
          formData.append("post[answers][][question_id]", t.question_id);
          formData.append("post[answers][][textAnswer]", t.textAnswer);
          formData.append("post[answers][][answer]", t.answer);
        });

        api.send_file(formData, (response) => {
          if (response.data.data.errors) {
            errorElement.textContent = response.data.data.errors[0];
          } else {
            location.href = "/?id=" + response.data.data.post.id;
          }
        });
      }
    });
  }

  priceList() {
    var _tiers = [];
    if (this.state.tiers.length > 0) {
      _tiers.push(<h4>Pricing</h4>);
    }
    this.state.tiers.map((t, idx) => {
      _tiers.push(
        <div
          key={idx}
          className="price"
          onClick={() => this.handleSelectPrice(t)}
        >
          {t.description}
        </div>
      );
    });

    var _flash = null;
    if (this.state.flash) {
      _flash = <div className="alert alert-success">{this.state.flash}</div>;
    }

    return (
      <React.Fragment>
        <div className="promote-options">{_tiers}</div>
      </React.Fragment>
    );
  }

  handleSave() {
    if (this.state.price.length < 1 || isNaN(this.state.price)) {
      toast.error("Price must be a number");
      return;
    }
    this.setState({ showModal: true });
  }

  handleFileClick() {
    document.getElementById("photo").click();
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }
  handleRemoveAttachment(e) {
    var _index = e.target.dataset["index"];
    var items = [];
    var _files = Array.from(this.state.files);
    for (var i = 0; i < _files.length; i++) {
      if (i != _index) {
        items.push(_files[i]);
      }
    }
    this.setState({ files: items });
  }

  handleAttachementPreview() {
    var input = document.getElementById("photo");
    var _files = this.state.files;
    if (input.files && input.files[0]) {
      if (validFile(input, "photo")) {
        _files.push(input.files[0]);
        this.setState({ files: _files });
      } else {
        toast.error("Invalid file format");
      }
    }
  }

  handleSelectPrice(tier) {
    this.setState({ tier: tier });
  }

  componentDidMount() {
    var api = new ApiRequest();
    api.method = "get";
    api.url = "/posts/tiers";
    api.send({}, (resp) => {
      this.setState({
        tiers: resp.data.data.result,
      });
    });
  }
  componentDidUpdate() {
    if (this.state.tier) {
      loadStripe(process.env.REACT_APP_STRIPE_KEY).then(
        (stripe) => {
          var elements = stripe.elements();
          this.stripe = stripe;
          this.card = elements.create("card");
          this.card.hidepostalcode = true;
          this.card.mount("#card-element");
        }
      );
    }
  }
}
export default SellItem;
