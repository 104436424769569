import React, { Component } from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';

class ConfirmationModal extends Component {
    
    render() {
        const { isOpen, onRequestClose, heading, bodyText, onSubmit } = this.props;

        if(isOpen) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }

        const customModalStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '500px',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
        };

        return (
            <Modal
                style={customModalStyles}
                isOpen={isOpen}
                onRequestClose={onRequestClose}
                contentLabel="Confirmation Modal"
                ariaHideApp={false}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>{heading}</h4>
                    <FaTimes onClick={onRequestClose} style={{ cursor: 'pointer' }} />
                </div>
                <p>{bodyText}</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <button onClick={onRequestClose} className='confirmation-modal-cancel'>Cancel</button>
                    <button onClick={onSubmit} className='confirmation-modal-submit'>Submit</button>
                </div>
            </Modal>
        );
    }
}

export default ConfirmationModal;
