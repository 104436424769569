import React, { Component } from "react";

class PostQuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getValue(question_id) {
    var _value = "";
    if (
      this.props.post &&
      this.props.post.meta_data &&
      this.props.post.meta_data["answers"]
    ) {
      this.props.post.meta_data.answers.map((a) => {
        if (parseInt(a.question_id) === parseInt(question_id)) {
          var val = Array.isArray(a.answer) ? a.answer[0] : a.answer;
          _value = val;
        }
      });
    }
    return _value;
  }

  render() {
    let _questions = [];
    if (
      !this.props.questions &&
      (!this.props.post || !this.props.post.meta_data)
    ) {
      return <div />;
    }
    //let _type_name = (this.state.questions[0]) ? this.state.questions[0].post_type_name : "";
    this.props.questions.map((x, ix) => {
      x.options.sort().map((q, idx) => {
        if (this.props.fields.indexOf(q.name) == -1) {
          return;
        }
        var field_type = q.field_type == undefined ? "na" : q.field_type;
        var field;
        if (field_type != "na") {
          if (
            field_type == "text" ||
            field_type == "phone" ||
            field_type == "location"
          ) {
            var _placeHolder = field_type == "phone" ? "xxx-xxx-xxxx" : "";
            field = (
              <input
                placeholder={_placeHolder}
                data-field_type={field_type}
                data-name={q.name}
                data-keyboard_type={q.keyboard_type}
                type="text"
                defaultValue={this.getValue(q.id)}
                name="questions[]"
                data-id={q.id}
                data-question={q.name}
                data-required={q.required}
                className="form-control question-field"
              />
            );
          }
          if (field_type == "email") {
            field = (
              <input
                data-field_type={field_type}
                data-name={q.name}
                type="email"
                defaultValue={this.getValue(q.id)}
                name="questions[] "
                data-id={q.id}
                data-question={q.name}
                data-required={q.required}
                className="form-control question-field"
              />
            );
          }
          if (field_type == "range") {
            var unit = "";
            var max = 200;

            if (q.name.indexOf("uration") > -1) {
              unit = " minutes";
              max = 10;
            }
            field = (
              <div>
                <input
                  data-field_type={field_type}
                  data-name={q.name}
                  defaultValue={this.getValue(q.id)}
                  name="questions[]"
                  data-id={q.id}
                  data-question={q.name}
                  data-required={q.required}
                  type="range"
                  onChange={this.handleSlider.bind(this)}
                  style={{ width: "100%" }}
                  min="1"
                  max={max}
                  step="0.50"
                />
                <span className="sliderVal">1</span>
                {unit}
              </div>
            );
          }
          if (field_type == "textarea") {
            field = (
              <textarea
                name="questions[]"
                data-field_type={field_type}
                data-name={q.name}
                defaultValue={this.getValue(q.id)}
                data-id={q.id}
                data-question={q.name}
                data-required={q.required}
                className="form-control question-field"
              />
            );
          }

          if (field_type == "single" || field_type == "multiple") {
            var _options = [];
            // if(q.children[0] && q.children[0].children[0]) {
            //   q.children.sort().map((o,i) => {
            //     var _suboptions = [];
            //     o.children.map((c,j) => {
            //       _suboptions.push(<option key={c.id + "_opt" + j} value={c.id}>{c.name}</option>)
            //     });
            //     _options.push(<optgroup key={o.id + "opt" + i} label={o.name}>{_suboptions}</optgroup>)
            //   });
            // } else {
            q.children.sort().map((o, i) => {
              _options.push(
                <option key={o.id + "_opt" + i} value={o.id}>
                  {o.name}
                </option>
              );
            });
            // }
            field = (
              <select
                data-field_type={field_type}
                data-name={q.name}
                name="questions[]"
                defaultValue={this.getValue(q.id)}
                data-id={q.id}
                data-question={q.name}
                data-required={q.required}
                className="form-control"
              >
                {_options}
              </select>
            );
          }

          if (field_type == "date") {
            field = (
              <input
                type="date"
                data-field_type={field_type}
                data-name={q.name}
                defaultValue={this.getValue(q.id)}
                name="questions[] "
                data-id={q.id}
                data-question={q.name}
                data-required={q.required}
                className="form-control question-field"
              />
            );
          }

          if (q.name && q.name.toLowerCase().indexOf("type something") < 0) {
            var _label = q.name;
            if (q.name.indexOf("Select Location") > -1) {
              _label = "Enter location";
            }
            _questions.push(
              <div className="form-group" key={x.question_id + "-" + q.id}>
                {" "}
                <label className="question">{_label}</label>
                {field}
              </div>
            );
          }
        }
      });
    });
    return <React.Fragment>{_questions}</React.Fragment>;
  }

  handleSlider(e) {
    let slider = document.querySelector(".sliderVal");
    slider.innerText = e.target.value;
  }
}

export default PostQuestionList;
