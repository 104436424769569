import React, { Component } from "react";
import ApiRequest from "../../libs/request";

class ReportUser extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit() {
    var ele = document.getElementById("reason");
    if (ele.value.length > 0) {
      var api = new ApiRequest(
        "post",
        "/users/" + this.props.user.id + "/report"
      );
      api.send({ reason: ele.value }, () => {
        this.props.onReportComplete();
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-2">
            <img src={this.props.user.profile_photo} width="100%" />
          </div>
          <div className="col-9">
            Report {this.props.user.first_name} {this.props.user.last_name}
            <div className="form-group">
              <label>Why are you reporting {this.props.user.first_name}?</label>
              <textarea
                id="reason"
                className="form-control"
                placeholder="Report Reason"
              ></textarea>
            </div>
            <button
              type="button"
              onClick={this.handleSubmit.bind(this)}
              className="g-button full"
            >
              Send
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReportUser;
