import React, { Component, useEffect, useRef } from "react";
import { FcAcceptDatabase, FcAddImage, FcAdvertising, FcAudioFile, FcBusinessman, FcCalendar, FcCamcorderPro, FcClapperboard, FcCompactCamera, FcConferenceCall, FcDebt, FcList, FcMindMap, FcMoneyTransfer, FcNews, FcPortraitMode, FcPositiveDynamic, FcReading, FcReadingEbook, FcSalesPerformance } from "react-icons/fc";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'
import CurrentUser from "../../libs/CurrentUser";


const BannerComponentNav = () => {
  const banner = useRef(null);

  useEffect(() => {
      if (banner.current && !banner.current.firstChild) {
          const atOptions = {
              'key': '46d9106feda2aa4031e3b87c25af2953',
              'format': 'iframe',
              'height': 90,
              'width': 728,
              'params': {}
          };

          const conf = document.createElement('script');
          conf.type = 'text/javascript';
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          const script = document.createElement('script');
          script.src = "//www.highperformanceformat.com/46d9106feda2aa4031e3b87c25af2953/invoke.js";
          script.type = "text/javascript";

          banner.current.appendChild(conf);
          banner.current.appendChild(script);
      }
  }, []);

  return (
      <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
      }}>
          <div ref={banner} />
      </div>
  );
};


class FeedNav extends Component {
  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: "12px", marginLeft: "15px" }}>
          <a href="/" >
            <FcAdvertising className="news-feed-left-icon" />
            <span>News Feed</span>
          </a>
          <a href="/profile/0/edit" >
            <FcPortraitMode className="news-feed-left-icon" />
            <span>Edit Profile</span>
          </a>
          <a href="/friends" >
            <FcConferenceCall className="news-feed-left-icon" />
            <span>Friends</span>
          </a>
          <a href="/review-requests" >
            <FcReadingEbook className="news-feed-left-icon" />
            <span>Review Requests</span>
          </a>

          <a href="/calendar" >
            <FcCalendar className="news-feed-left-icon" />
            <span>Calendar/Availability</span>
          </a>

          <a href="/invites" >
            <FcMindMap className="news-feed-left-icon" />
            <span>Send Invites</span>
            <span title="Invites Remaining" >
              {this.props.user.invites_remaining}
            </span>
          </a>
          <a href="/companies" >
            <FcBusinessman className="news-feed-left-icon" />
            <span>Company Profiles</span>
          </a>
          <a href="/profile/0/workhistory" >
            <FcAcceptDatabase className="news-feed-left-icon" />
            <span>Work History</span>
          </a>
          <hr style={{ margin: '10px', width: '60%' }} />
          <a href="/seeking/crew" >
            <FcReading className="news-feed-left-icon" />
            <span>Seeking Crew</span>
          </a>
          <a href="/seeking/work" >
            <FcDebt className="news-feed-left-icon" />
            <span>Seeking Work</span>
          </a>
          <a href="/seeking/screenplay" >
            <FcNews className="news-feed-left-icon" />
            <span>Seeking Screenplays</span>
          </a>
          <a href="/seeking/music" >
            <FcAudioFile className="news-feed-left-icon" />
            <span>Seeking Music</span>
          </a>
          <a href="/seeking/investment" >
            <FcMoneyTransfer className="news-feed-left-icon" />
            <span>Seeking Investment</span>
          </a>
          <hr style={{ margin: '10px', width: '60%' }} />
          {/* <a href="/film-market" >
            <FcClapperboard className="news-feed-left-icon" />
            <span>Film Market</span>
          </a>
          <hr style={{ margin: '10px', width: '60%' }} /> */}
          <a href="/watch-films" >
            <FcCamcorderPro className="news-feed-left-icon" />
            <span>Watch Films</span>
          </a>
          <a href={`https://adminnew.gorillafilm.com/movie/add?token=${CurrentUser.token()}`} target="_blank" >
            <FcAddImage className="news-feed-left-icon" />
            <span>Add Film</span>
          </a>
          <a href="/my-films" >
            <FcCompactCamera className="news-feed-left-icon" />
            <span>My Favorite</span>
          </a>
          <a href="/my-list" >
            <FcList className="news-feed-left-icon" />
            <span>My Films</span>
          </a>
          <br />
          <a href="https://screnpla.com" target="_blank" className="screnpla-btn" >
            <button
              className={"actionbtn suggested-frnd-btn"}
              type="button"
            >
              <b>SCRENPLA</b>
            </button>
          </a>

            <BannerComponentNav key={"BannerComponentNav"} />

          {/* <hr style={{ margin: '10px', width: '60%' }} />
          <a href="/buy-ads" >
            <FcSalesPerformance className="news-feed-left-icon" />
            <span>Buy Ads</span>
          </a>
          <a href="/my-ads" >
            <FcPositiveDynamic className="news-feed-left-icon" />
            <span>My Ads</span>
          </a> */}
          <ToastContainer />
        </div>
      </React.Fragment>
    );
  }
}

export default FeedNav;
