import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

const NoMatchPage = () => {
  return (
    <Redirect to="/" /> 
  );
};

export default NoMatchPage;
