import React, { Component } from "react";
import Hobbies from "./Hobbies";
import ApiRequest from "../../libs/request.js";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import MultiSelect from "../main/MultiSelect";
import ReactSelect from "react-select";
import { toast } from "react-toastify";

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.handleFormChange = this.handleFormChange.bind(this);
    var _occupation_ids = [];
    if (this.props.user.occupations) {
      this.props.user.occupations.map((o) => {
        _occupation_ids.push(o.occupation_id);
      });
    }
    this.state = {
      errors: [],
      genres: [],
      selectedGenres: [],
      user: this.props.user,
      occupations: this.props.user.occupations,
      occupation_ids: _occupation_ids,
      phone: this.props.user.phone,
    };
  }

  componentDidMount() {
    
    let genreApi = new ApiRequest("get", "/user/crew-types");
    genreApi.send({}, (response) => {
      const options = response.data.types.map((primaryType) => {
        const primaryOption = {
          label: primaryType.name,
          options: primaryType.secondary.map((secondaryType) => ({
            value: secondaryType.id,
            label: secondaryType.name,
          })),
        };
        return primaryOption;
      });

      this.setState({ genres: options });
    });
  }

  handleFormChange(e) {
    var _user = this.state.user;
    _user[e.target.name] = e.target.value;
    this.setState({ user: _user });
  }

  handleOccupations() {
    let apiRequest = new ApiRequest("put", "/users/0.json");
    let params = this.params();
    apiRequest.send({ user: params }, (response) => {
      let result = response.data;
      if (result.errors) {
        this.setState({ errors: result.message });
      } else {
        location.href = "/profile/occupations";
      }
    });
  }

  phonenumber(val) {
    var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return val.match(phoneno);
  }

  isValidUrl(str) {
    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }

  handleProfileSave() {
    let apiRequest = new ApiRequest("put", "/users/0.json");
    let params = this.params();
    var _errors = [];

    if (
      !params["first_name"] ||
      (params["first_name"] && params["first_name"].length < 1)
    ) {
      _errors.push("First name is required ");
    }
    if (
      !params["last_name"] ||
      (params["last_name"] && params["last_name"].length < 1)
    ) {
      _errors.push("Last name is required ");
    }

    if (params["imdb_link"] && !this.isValidUrl(params["imdb_link"])) {
      _errors.push("IMDB Link is not a valid url ");
    }
    if (params["zipcode"] && isNaN(this.state.user.zipcode)) {
      _errors.push("Zipcode is not valid");
    }

    if (this.state.user.hobbies.length < 3) {
      toast.error("Please select at least 3 hobbies");
      return;
    }

    if (_errors.length > 0) {
      this.setState({ errors: _errors });
      window.scrollTo(0, 0);
      return;
    } else {
      this.setState({ errors: null });
    }
    apiRequest.send({ user: params }, (response) => {
      let result = response.data;
      if (result.errors) {
        this.setState({ errors: result.message });
      } else {
        this.setState({ errors: [], user: result.data.user });
      }
      this.handleNext();
    });
  }

  handleNext() {
    if (this.props.next) {
      window.location.href = this.props.next;
    } else {
      window.scrollTo(0, 0);
    }
  }

  params() {
    return {
      first_name: this.state.user.first_name,
      last_name: this.state.user.last_name,
      phone: this.state.user.phone,
      email: this.state.user.email,
      zipcode: this.state.user.zipcode,
      imdb_link: this.state.user.imdb_link,
      work_radius: document.getElementById("radius").value,
      aboutme: this.state.user.aboutme,
      occupation_ids: this.state.occupation_ids,
    };
  }

  handleSlider(e) {
    let slider = document.querySelector(".sliderVal");
    slider.innerText = e.target.value;
  }

  render() {
    var _errors = <div />;
    if (this.state.errors.length > 0) {
      _errors = <div className="alert alert-danger">{this.state.errors}</div>;
    }
    var _miles =
      this.props.user && this.props.user.work_radius
        ? this.props.user.work_radius
        : 1;
    var _phone = "+10000000000";
    if (this.state.phone && this.state.phone.length > -1) {
      _phone = this.state.phone;
    }
    return (
      <React.Fragment>
        <form id="profileForm" className="gorilla-form">
          {_errors}
          <h2>Personal Information</h2>
          <div className="row m-0 p-0">
            <div className="col-6 m-0 p-0 pr-4 pl-6">
              <label>First Name</label>
              <input
                name="first_name"
                id="first_name"
                defaultValue={this.state.user.first_name || ""}
                onChange={this.handleFormChange}
                className="form-control"
                placeholder="First Name"
              />
            </div>
            <div className="col-6 m-0 pl-6 pr-0 pb-4">
              <label>Last Name</label>
              <input
                name="last_name"
                id="last_name"
                defaultValue={this.state.user.last_name || ""}
                onChange={this.handleFormChange}
                className="form-control"
                placeholder="Last Name"
              />
            </div>
            <div className="col-6 m-0 p-0 pr-4 pl-6">
              <label>Phone</label>
              <PhoneInput
                value={_phone}
                onChange={(phone) => this.setState({ phone })}
              />
            </div>
            <div className="col-6 m-0 pl-6 pr-0 pb-4">
              <label>Zip Code</label>
              <input
                name="zipcode"
                id="zipcode"
                defaultValue={this.state.user.zipcode == "null" ? "" : this.state.user.zipcode || ""}
                onChange={this.handleFormChange}
                className="form-control"
                placeholder="Zip"
              />
            </div>
            <div className="col-6 m-0 p-0 pr-4">
              <label>IMDb Link</label>
              <input
                name="imdb_link"
                id="imdb_link"
                defaultValue={this.state.user.imdb_link || ""}
                onChange={this.handleFormChange}
                className="form-control"
                placeholder="IMDb Link"
              />
            </div>
            <div className="col-6 m-0 pl-6 pr-0 pb-4">
              <label>Occupations</label>
              <ReactSelect
                  options={this.state.genres}
                  isMulti
                  value={this.state.selectedGenres}
                  onChange={(selectedOptions) => this.setState({ selectedGenres: selectedOptions })}
                />
              {/* <MultiSelect
                id={0}
                hideButton={true}
                post_type={0}
                question_id={1}
                occupations={true}
                placeholder="Select Occupations"
                selectedTypes={this.state.occupations}
                onOccupationSelected={(objs) =>
                  this.setState({ occupations: objs })
                }
                onCompanyTypesSelected={(items) =>
                  this.setState({ occupation_ids: items })
                }
              /> */}
            </div>
            <div className="col-12 m-0 p-0 pb-4">
              <label>Email</label>
              <input
                name="email"
                id="email"
                defaultValue={this.state.user.email || ""}
                readOnly={true}
                onChange={this.handleFormChange}
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div className="col-12 m-0 p-0 pb-4">
              <label>Work Radius</label>
              <br />
              <input
                type="range"
                style={{ width: "100%" }}
                id="radius"
                defaultValue={this.state.work_radius}
                onChange={this.handleSlider.bind(this)}
                min="1"
                max="12500"
                step="1"
              />
              <br />
              <span className="sliderVal">{_miles}</span> miles
            </div>
            <div className="col-12 m-0 p-0 pb-4">
              <label>About Me</label>
              <textarea
                name="aboutme"
                defaultValue={this.state.user.aboutme || ""}
                onChange={this.handleFormChange}
                style={{ height: "160px" }}
                className="form-control textarea"
                placeholder="Tell us about yourself"
              ></textarea>
            </div>
            <Hobbies
              user_hobbies={this.state.user.hobbies || []}
              onHobbiesChange={(hobbies) => {
                this.setState({ hobbies: hobbies });
              }}
            />
            <div className="row m-0 p-0">
              <div className="col-12 m-0 p-0">
                <button
                  className="g-button"
                  type="button"
                  onClick={this.handleProfileSave.bind(this)}
                >
                  Save
                </button>
              </div>
            </div>
            <p>&nbsp;</p>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default ProfileForm;
