import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";

class ReelsForm extends Component {
  constructor(props) {
    super(props);
    this.state = { post: {}, post_type: 11 };
    //this.loadProject();
  }

  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-center projects"></div>
        <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
          <h2>Reels</h2>
          <input type="hidden" name="id" id="post_id" value="" />
          <input
            type="hidden"
            value="11"
            id="post_type_id"
            name="post[post_type]"
          />

          <div className="col-12 m-0 p-0">
            <div className="form-group">
              <label>Reel Name</label>
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Name of Reel"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input
                type="text"
                id="body"
                name="body"
                placeholder="Description"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Add Link</label>
              <input
                type="text"
                id="imdb_link"
                name="meta_data[imdb_link]"
                placeholder="Link"
                className="form-control"
              />
            </div>
          </div>

          <div className="col-12 p-0" style={{ float: "left" }}>
            <button
              className="g-button full"
              onClick={this.handleSubmit.bind(this)}
            >
              Save Reel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSubmit() {
    const id = null; //this.props.match.params.id;
    var params = {};
    var _title = document.getElementById("title").value;
    params["body"] = document.getElementById("body").value;
    params["post_type"] = 11;
    params["meta_data"] = {
      description: params["body"],
      title: _title,
      imdb_link: document.getElementById("imdb_link").value,
    };

    var api = new ApiRequest("post", "/posts");
    if (id) {
      api.url = "/posts/" + id;
      api.method = "put";
    }
    api.send({ post: params }, (result) => {
      this.props.onAfterSave();
    });
  }
}

export default ReelsForm;
