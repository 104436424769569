import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { isset } from "../../libs/functions";
import ProjectBanner from "./ProjectBanner";
import { toast } from "react-toastify";

class ProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = { post_id: this.props.post_id, media: [] };

    this.handleFormChange = this.handleFormChange.bind(this);
    this.loadProject();
  }

  render() {
    var _post_id = this.props.posts ? this.props.post.id : 0;
    return (
      <React.Fragment>
        <div className="row justify-content-center projects">
          <ProjectBanner
            post_id={_post_id}
            onBannerUploaded={(post) => this.setState({ media: post })}
            post_type_id={13}
          />
        </div>
        <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
          <h2>Project</h2>
          <input type="hidden" name="id" id="post_id" value="" />
          <input
            type="hidden"
            value="2"
            id="post_type_id"
            name="post[post_type]"
          />

          <div className="col-12 m-0 p-0">
            <div className="form-group">
              <label>Project</label>
              <input
                type="text"
                id="name"
                name="name"
                defaultValue={this.state.name || ""}
                onChange={this.handleFormChange}
                placeholder="Name of your project/film…"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                id="description"
                style={{ resize: "none" }}
                name="description"
                defaultValue={this.state.description || ""}
                onChange={this.handleFormChange}
                placeholder="Tell us in detail about your project/film…"
                className="form-control"
              ></textarea>
            </div>
            <div className="form-group">
              <label>Imdb Link</label>
              <input
                type="text"
                id="imdb"
                name="imdb"
                placeholder="http://"
                defaultValue={this.state.imdb || ""}
                onChange={this.handleFormChange}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Trailer Video</label>
              <input
                type="text"
                id="trailer"
                name="trailer"
                placeholder="http://"
                defaultValue={this.state.trailer || ""}
                onChange={this.handleFormChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-12 p-0" style={{ float: "left" }}>
            <button
              className="g-button full"
              onClick={this.hanndleSave.bind(this)}
            >
              Save Project
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  loadProject() {
    const id = this.state.post_id;
    if (isset(id)) {
      let api = new ApiRequest("get", "/user/project-details?project_id=" + id);
      api.send({}, (response) => {

        var _post = response.data.project;
        var _name = _post.name;
        this.setState(response.data.project);
        this.setState({
          name: _name,
          description: _post.description,
          imdb: _post.imdb,
          trailer: _post.trailer,
          media: _post.media,
        });

        if (response.data.project.media.length > 0) {
          var headerPanel = document.querySelector("#project-asset-view");
          headerPanel.style.backgroundImage =
            "url('" + response.data.project.media[0] + "')";
          document.querySelector(".project-camera").style.display = "none";
        }
      });
    }
  }

  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  hanndleSave() {
    if (!isset(document.querySelector("#name").value)) {
      toast.error("Please enter a name");
      return;
    }
    if (!isset(document.querySelector("#description").value)) {
      toast.error("Please enter a description");
      return;
    }
    if (!isset(document.querySelector("#imdb").value)) {
      toast.error("Please enter a url");
      return;
    }

    if (this.state.media.length == 0) { 
      toast.error("Please upload a banner image");
      return;
    }

    var params = {
      name: document.querySelector("#name").value,
      description: document.querySelector("#description").value,
      imdb: document.querySelector("#imdb").value,
      trailer: document.querySelector("#trailer").value,
      media: this.state.media,
    };
    if (isset(this.state.post_id)) {
      params.project_id = this.state.post_id;
    }

    const _formData = new FormData();
    _formData.append("name", params.name);
    _formData.append("description", params.description);
    _formData.append("imdb", params.imdb);
    _formData.append("trailer", params.trailer);
    _formData.append("media[]", params.media);
    
    if (this.state.media.length > 0) {
      _formData.append("media[]", params.media);
    }

    var api = isset(this.state.post_id)
      ? new ApiRequest("post", "/user/edit-project")
      : new ApiRequest("post", "/user/add-project");
      isset(this.state.post_id)
      ? api.send(params, (response) => {
          this.props.onAfterSave();
        }) :  api.send_file(_formData, (response) => {
        this.props.onAfterSave();
      });
  }
}

export default ProjectForm;
