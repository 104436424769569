import React, { Component } from "react";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import EditProfile from "./EditProfile";
class Profile extends Component {
  render() {
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12">
            <EditProfile user={this.props.user} />
            </div>
          </div>
          
        </div>
      </React.Fragment>
    );
  }
}
export default Profile;
