import React, { Component } from "react";
import { params, formatDate } from "../../libs/functions";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import ApiRequest from "../../libs/request";
import MultiSelect from "./MultiSelect";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LocationSeach from "./LocationSearch";
import * as EmailValidator from 'email-validator';

class NewPostProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project_types: [],
      union_types: [],
      director_name: "",
      errors: [],
      phone: "",
      email: "",
      minimum: 0,
      maximum: 0,
      audition_date: null,
      callback_date: null,
      shoot_date: null,
      additional_info: "",
      special_note: "",
      regions: [],
      crew_types: [],
      location: [],
      start_date: null,
      end_date: null,
      range: 0,
      title: "",
      rate: null,
      location_name: "",
      display: "",
    };
  }

  phonenumber(val) {
    var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return val.match(phoneno);
  }

  handleSave() {
    var _errors = [];
    if (document.getElementById("title").value == "") {
      _errors.push("Please enter a project title");
    }
    if (document.getElementById("casting-director").value == "") {
      _errors.push("Please enter a casting director name");
    }

    if (document.getElementById("rate").value == "") {
      _errors.push("Please select a rate");
    }

    if (document.getElementById("contact-email").value != "") {
      var validator = EmailValidator;

      if (!validator.validate(document.getElementById("contact-email").value)) {
        _errors.push("Email is invalid");
      }
    }
    if (this.state.phone.length > 1) {
      if (!this.phonenumber(this.state.phone)) {
        _errors.push("Phone is invalid");
      }
    }

    this.setState({ errors: _errors });
    if (_errors.length > 0) {
      window.scrollTo(0, 0);
      return;
    }
    var _answers = [];
    _answers.push({
      answer: this.state.title,
      question_id: 690,
      question: "Enter Project Name",
      textAnswer: this.state.title,
    });
    _answers.push({
      answer: this.state.project_types,
      question_id: 691,
      question: "Select Project Type",
      textAnswer: "",
    });
    _answers.push({
      answer: this.state.union_types,
      question_id: 692,
      question: "Enter Director name",
      textAnswer: "",
    });
    _answers.push({
      answer: this.state.director_name,
      question_id: 694,
      question: "",
      textAnswer: this.state.director_name,
    });
    _answers.push({
      answer: this.state.phone,
      question_id: 698,
      question: "",
      textAnswer: this.state.phone,
    });
    _answers.push({
      answer: this.state.email,
      question_id: 701,
      question: "",
      textAnswer: this.state.email,
    });
    _answers.push({
      answer: this.state.additional_info,
      question_id: 702,
      question: "",
      textAnswer: this.state.additional_info,
    });
    _answers.push({
      answer: this.state.audition_date,
      question_id: 703,
      question: "",
      textAnswer: this.state.audition_date,
    });
    _answers.push({
      answer: this.state.callback_date,
      question_id: 704,
      question: "",
      textAnswer: this.state.callback_date,
    });
    _answers.push({
      answer: this.state.shoot_date,
      question_id: 706,
      question: "",
      textAnswer: this.state.shoot_date,
    });
    _answers.push({
      answer: this.state.location_name,
      question_id: 707,
      question: "",
      textAnswer: this.state.location_name,
    });
    _answers.push({
      answer: [this.state.rate],
      question_id: 708,
      question: "",
      textAnswer: "",
    });
    _answers.push({
      answer: this.state.special_note,
      question_id: 709,
      question: "",
      textAnswer: this.state.special_note,
    });
    _answers.push({
      answer: this.state.regions,
      question_id: 710,
      question: "",
      textAnswer: "",
    });

    var nudity_textanswer = "";
    if(this.state.nudity != undefined){
      nudity_textanswer = this.state.nudity == 896
          ? "No - There are no nude or semi-nude scenes in the listed project"
          : "Yes - There is nudity in the listed project"
    }

    _answers.push({
      answer: [this.state.nudity],
      question_id: 711,
      question: "",
      textAnswer: nudity_textanswer,
    });
    _answers.push({
      answer: this.state.minimum,
      question_id: 683,
      question: "",
      textAnswer: this.state.minimum,
    });
    _answers.push({
      answer: this.state.maximum,
      question_id: 684,
      question: "",
      textAnswer: this.state.maximum,
    });
    _answers.push({
      answer: this.state.crew_types,
      question_id: 1964,
      question: "",
      textAnswer: "",
    });
    _answers.push({
      answer: this.state.location_name,
      question_id: 716,
      question: "",
      textAnswer: this.state.location_name,
    });
    _answers.push({
      answer: this.state.range,
      question_id: 717,
      question: "",
      textAnswer: this.state.range,
    });
    _answers.push({
      answer: this.state.start_date,
      question_id: 718,
      question: "",
      textAnswer: this.state.start_date,
    });
    _answers.push({
      answer: this.state.end_date,
      question_id: 719,
      question: "",
      textAnswer: this.state.end_date,
    });
    _answers.push({
      answer: this.state.display,
      question_id: 2424,
      question: "",
      textAnswer: this.state.display,
    });

    var _params = {
      post: {
        answers: _answers,
        location: this.state.location,
        body: this.state.title,
        post_type: 2,
      },
    };

    var api = new ApiRequest("post", "/posts");
    if (params("id")) {
      api.url = "/posts/" + params("id");
      api.method = "put";
    }
    api.send(_params, (response) => {
      location.href = "/?id=" + response.data.data.post.id;
    });
  }

  componentDidMount() {
    var api = new ApiRequest("get", "/posts/new?post_type=2");
    var _edit_post = null;
    api.send({}, (response) => {
      var data = response.data.data.questions[11];
      var nudity = response.data.data.questions[14];
      if (params("id")) {
        api.url = "/posts/" + params("id");
        api.send({}, (response) => {
          _edit_post = response.data.data.post;

          this.setState({
            rates_options: data.options[0].children,
            nudity_options: nudity.options[0].children,
            edit_post: _edit_post,
            project_types: this.defaultValue(691, _edit_post),
            union_types: this.defaultValue(692, _edit_post),
            regions: this.defaultValue(710, _edit_post),
            phone: this.defaultValue(698, _edit_post),
            audition_date: this.defaultValue(703, _edit_post),
            callback_date: this.defaultValue(704, _edit_post),
            shoot_date: this.defaultValue(706, _edit_post),
            rate: this.defaultValue(708, _edit_post),
            nudity: this.defaultValue(711, _edit_post),
            crew_types: this.defaultValue(1964, _edit_post),
            location_name: this.defaultValue(716, _edit_post),
            range: this.defaultValue(717, _edit_post),
            start_date: this.defaultValue(719, _edit_post),
            end_date: this.defaultValue(719, _edit_post),
            title: this.defaultValue(690, _edit_post),
            email: this.defaultValue(701, _edit_post),
            director_name: this.defaultValue(694, _edit_post),
            additional_info: this.defaultValue(702, _edit_post),
            special_note: this.defaultValue(709, _edit_post),
            minimum: this.defaultValue(683, _edit_post),
            maximum: this.defaultValue(684, _edit_post),
            display: this.defaultValue(2424, _edit_post),
          });
        });
      } else {
        this.setState({
          rates_options: data.options[0].children,
          nudity_options: nudity.options[0].children,
        });
      }
    });
  }

  defaultValue(question_id, items) {
    var _value = null;
    var _items =
      this.state.edit_post && this.state.edit_post.meta_data["answers"]
        ? this.state.edit_post.meta_data["answers"]
        : [];
    if (items && items.meta_data["answers"]) {
      _items = items.meta_data["answers"];
    }

    _items.map((item) => {
      if (item["question_id"] == question_id) {
        _value = item["answer"];
        return _value;
      }
    });

    return _value;
  }

  render() {
    var _showErrors = this.state.errors.length > 0 ? "block" : "none";
    var _errorStr = this.state.errors.join(" , ");
    var _rates = [];
    if (!this.state.rates_options) {
      return <div />;
    }
    if (!this.state.nudity_options) {
      return <div />;
    }

    if (this.state.rates_options) {
      this.state.rates_options.map((o, idx) => {
        var _selected = false;
        if (o.id == this.state.rate) {
          _selected = true;
        }
        _rates.push(
          <option value={o.id} selected={_selected}>
            {o.name}
          </option>
        );
      });
    }
    var _nudity = [];
    if (this.state.nudity_options) {
      this.state.nudity_options.map((o, idx) => {
        _nudity.push(<option value={o.id}>{o.name}</option>);
      });
    }
    var _project_types = [];
    var _union_types = [];
    var _regions = [];
    var _phone = "";
    var _audtionDate = "";
    var _callbackDate = "";
    var _shootDate = "";
    var _crewTypes = [];
    var _selectedLocations = "";
    var _startDate = "";
    var _endDate = "";
    var dt;
    if (params("id") && this.state.project_types.length > 0) {
      _project_types = this.state.project_types;
    }
    if (params("id") && this.state.union_types.length > 0) {
      _union_types = this.state.union_types;
    }
    if (params("id") && this.state.crew_types.length > 0) {
      _crewTypes = this.state.crew_types;
    }
    if (params("id") && this.state.location_name) {
      _selectedLocations = this.state.location_name;
    }
    if (params("id") && this.state.regions.length > 0) {
      _regions = this.state.regions;
    }
    if (params("id") && this.state.phone.length > 0) {
      _phone = this.state.phone;
    }
    if (params("id") && this.state.audition_date) {
      dt = new Date(this.state.audition_date);
      _audtionDate = dt;
    }
    if (params("id") && this.state.callback_date) {
      dt = new Date(this.state.callback_date);
      _callbackDate = dt;
    }
    if (params("id") && this.state.shoot_date) {
      dt = new Date(this.state.shoot_date);
      _shootDate = dt;
    }
    if (params("id") && this.state.start_date) {
      dt = new Date(this.state.start_date);
      _startDate = dt;
    }
    if (params("id") && this.state.end_date) {
      dt = new Date(this.state.end_date);
      _endDate = dt;
    }

    var _nudity_options = [];
    if (this.state.nudity && this.state.nudity[0] == "896") {
      _nudity_options.push(
        <option value="896" selected>
          No - There are no nude or semi-nude scenes in the listed project
        </option>
      );
    } else {
      _nudity_options.push(
        <option value="896">
          No - There are no nude or semi-nude scenes in the listed project
        </option>
      );
    }
    if (this.state.nudity && this.state.nudity[0] == "895") {
      _nudity_options.push(
        <option value="895" selected>
          Yes - There is nudity in the listed project
        </option>
      );
    } else {
      _nudity_options.push(
        <option value="895">Yes - There is nudity in the listed project</option>
      );
    }

    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12">
            <div className="feed-center large">
              <form className="seeking container">
                <h4>Post Project</h4>
                <label>(* required)</label>
                <div
                  className="alert alert-danger"
                  style={{ display: _showErrors }}
                >
                  {_errorStr}
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Project Title or Name *</label>
                      <input
                        type="text"
                        placeholder="Enter a title"
                        className="form-control"
                        defaultValue={this.defaultValue(690)}
                        id="title"
                        onChange={(e) =>
                          this.setState({ title: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "12px" }}>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Project Type</label>
                      <MultiSelect
                        id={691}
                        hideButton={true}
                        post_type={2}
                        placeholder="Select Project Type"
                        selectedIds={_project_types}
                        selectedTypes={this.state.project_types}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ project_types: items })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Union Type</label>
                      <MultiSelect
                        id={692}
                        hideButton={true}
                        post_type={2}
                        placeholder="Select Union Type"
                        selectedIds={_union_types}
                        selectedTypes={this.state.union_types}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ union_types: items })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Casting Director Name *</label>
                      <input
                        type="text"
                        id="casting-director"
                        onChange={(e) =>
                          this.setState({ director_name: e.target.value })
                        }
                        defaultValue={this.defaultValue(694)}
                        placeholder="Enter Casting Director's Name"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Contact Phone</label>
                      <PhoneInput
                        country={"us"}
                        value={_phone}
                        onChange={(phone) => this.setState({ phone })}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Contact Email</label>
                      <input
                        type="email"
                        id="contact-email"
                        className="form-control"
                        placeholder="Enter Contact Email"
                        defaultValue={this.defaultValue(701)}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Additional Project Info</label>
                      <textarea
                        className="form-control"
                        placeholder="Enter Additional Info"
                        defaultValue={this.defaultValue(702)}
                        onChange={(e) =>
                          this.setState({ additional_info: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Interview Audition Date</label>
                      <DatePicker
                        placeholderText="Select an Audition Date"
                        selected={_audtionDate}
                        onChange={(date) =>
                          this.setState({ audition_date: date })
                        }
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label>Callback Date</label>
                      <DatePicker
                        placeholderText="Select a Callback Date"
                        selected={_callbackDate}
                        onChange={(date) =>
                          this.setState({ callback_date: date })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Start/Shoot Date</label>
                      <DatePicker
                        placeholderText="Select a Shoot Date"
                        selected={_shootDate}
                        onChange={(date) => this.setState({ shoot_date: date })}
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label>Rate of Pay/Contract *</label>
                      <select
                        className="form-control"
                        id="rate"
                        onChange={(e) =>
                          this.setState({ rate: e.target.value })
                        }
                      >
                        <option value="">Select a Rate</option>
                        {_rates}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Special Note</label>
                      <textarea
                        className="form-control"
                        defaultValue={this.defaultValue(709)}
                        placeholder="Special Note"
                        onChange={(e) =>
                          this.setState({ special_note: e.target.value })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>
                        Choose One or More geographic locations for the project
                      </label>
                      <MultiSelect
                        id={6923}
                        hideButton={true}
                        post_type={2}
                        question_id={50}
                        placeholder="Select Geographic Region"
                        selectedIds={_regions}
                        selectedTypes={this.state.regions}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ regions: items })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Nudity</label>
                      <select
                        id="nudity"
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ nudity: e.target.value })
                        }
                      >
                        <option value="">Select Nudity</option>
                        {_nudity_options}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Minimum Investment Amount $</label>
                      <input
                        defaultValue={this.defaultValue(683)}
                        type="number"
                        onChange={(e) =>
                          this.setState({ minimum: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Maximum Investment Amount $</label>
                      <input
                        type="number"
                        defaultValue={this.defaultValue(684)}
                        onChange={(e) =>
                          this.setState({ maximum: e.target.value })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Crew Type</label>
                      <MultiSelect
                        id={715}
                        question_id={89}
                        post_type={3}
                        hideButton={true}
                        placeholder="Select Crew Type"
                        selectedIds={_crewTypes}
                        selectedTypes={this.state.crew_types}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ crew_types: items })
                        }
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Preferred Location</label>
                      <LocationSeach
                        selected_names={[_selectedLocations]}
                        onLocationComplete={(name) =>
                          this.setState({ location_name: name })
                        }
                        onLocationSelected={(loc) =>
                          this.setState({ location: loc })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="form-group"
                      style={{ backgroundColor: "#fff", padding: "12px" }}
                    >
                      <label>WORK RADIUS</label>
                      <input
                        type="range"
                        className="form-control"
                        min="1"
                        max="12500"
                        defaultValue={this.state.range}
                        step="1"
                        onChange={(e) =>
                          this.setState({ range: e.target.value })
                        }
                      />
                      <span className="sliderVal">{this.state.range || 0}</span>{" "}
                      miles
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Project Start Date</label>
                      <DatePicker
                        placeholderText="Select a Project Start Date"
                        selected={_startDate}
                        onChange={(date) => this.setState({ start_date: date })}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Project End Date</label>
                      <DatePicker
                        placeholderText="Select a Project End Date"
                        selected={_endDate}
                        onChange={(date) => this.setState({ end_date: date })}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Casting Direct Display Name</label>
                      <input
                        onChange={(e) =>
                          this.setState({ display: e.target.value })
                        }
                        type="text"
                        defaultValue={this.defaultValue(2424)}
                        className="form-control"
                        placeholder="Enter Casting Director Display Name"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={this.handleSave.bind(this)}
                        className="g-button full"
                      >
                        Post Project
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default NewPostProject;
