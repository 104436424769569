import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";

class CompanyTypes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      selected_items: [],
      selected_names: [],
      selected_occupation: null,
    };
    this.removeCompanyType = this.removeCompanyType.bind(this);
  }

  occupationItems() {
    const items = this.state.results.map((item) => {
      const childrens = item.children.map((c, idx) => {
        let checkedCss =
          this.state.selected_items.indexOf(c.id) > -1
            ? "checkbox-on"
            : "checkbox-off";
        let selectedCss = "round";
        return (
          <div
            className={selectedCss}
            key={idx}
            style={{ fontSize: "13px", lineHeight: "26px", height: "56px" }}
          >
            <a href="#" onClick={() => this.handleClick(c.name, c.id, true)}>
              {c.name}
            </a>
            <input type="checkbox" id={c.parent_id} />
            <label
              id={"label_" + c.id}
              htmlFor={c.id}
              className={checkedCss}
              occupation_id={c.id}
              onClick={() => this.handleClick(c.name, c.id)}
            />
          </div>
        );
      });
      if (item.companytypes) {
        return (
          <div className="root" key={item.id}>
            {" "}
            <div
              className="title"
              title={item.companytypes.title}
              style={{
                fontSize: "14px",
                lineHeight: "53px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.companytypes.title}
            </div>{" "}
            {childrens} <div className="root-last"></div>
          </div>
        );
      }
    });
    return items;
  }

  handleClick(name, occupation_id, showFormOnly = false) {
    var items = this.state.selected_items;
    var _names = this.state.selected_names;
    if (items.indexOf(occupation_id) === -1) {
      _names.push(name);
      items.push(occupation_id);
    } else {
      var index = items.indexOf(occupation_id);
      if (index !== -1) items.splice(index, 1);
      index = _names.indexOf(name);
      if (index !== -1) _names.splice(index, 1);
    }
    this.setState({
      selected_names: _names,
      selected_items: items,
      selected_occupation: occupation_id,
    });
    this.handleDone();
    this.props.onCompanyTypesSelected(items);
  }

  render() {
    var _itemsCss = this.state.results.length > 0 ? "block" : "none";
    var _names = [];
    this.state.selected_names.map((n, idx) => {
      _names.push(
        <div
          key={idx}
          onClick={() => this.removeCompanyType(idx)}
          className="ct-bubble"
        >
          {n}
        </div>
      );
    });

    var _button = "block";
    if (this.props.hideButton) {
      _button = "none";
    }

    return (
      <React.Fragment>
        <div className="ctype-bubbles">{_names}</div>
        <input
          id="type-search"
          className="form-control nooutline"
          placeholder="Search types"
          style={{ lineHeight: "42px" }}
          onKeyUp={this.handleTypeSearch.bind(this)}
        />
        <div
          className="occupations"
          style={{ display: _itemsCss, padding: "0px" }}
        >
          <div
            className="autosearch occupations-list"
            style={{ textAlign: "left" }}
          >
            <div
              className="results"
              style={{ padding: "12px", width: "100%", height: "240px" }}
            >
              {this.occupationItems()}
            </div>
            <br />
            <button
              style={{ display: _button }}
              className="g-button"
              type="button"
              onClick={this.handleDone.bind(this)}
            >
              Done
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleDone() {
    document.querySelector(".occupations").style.display = "none";
    document.querySelector("#type-search").value = "";
  }

  removeCompanyType(index) {
    var items = this.state.selected_items;
    var _names = this.state.selected_names;
    if (index !== -1) items.splice(index, 1);
    if (index !== -1) _names.splice(index, 1);
    this.setState({ selected_names: _names, selected_items: items });
    this.props.onCompanyTypesSelected(items);
  }

  handleTypeSearch() {
    var typeField = document.querySelector("#type-search");

    if (typeField.value.length < 3) {
      document.querySelector(".occupations").style.display = "none";
      return;
    } else {
      document.querySelector(".occupations").style.display = "block";
    }
    var api = new ApiRequest(
      "get",
      "/user/search-company?search=" + typeField.value
    );
    api.send({ search: typeField.value }, (results) => {
      this.setState({ results: results.data.companies });
    });
  }

  componentDidMount() {
    if (this.props.selectedTypes && this.props.selectedTypes.length > 0) {
      var _ids = [];
      var _names = [];
      this.props.selectedTypes.map((t) => {
        _ids.push(t.companytypes.id);
        _names.push(t.companytypes.title);
      });
      this.setState({ selected_items: _ids, selected_names: _names });
    }
  }
}

export default CompanyTypes;
