import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { isset } from "../../libs/functions";
import ProjectForm from "../projects/ProjectForm";

class EditProject extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid header">
          <div className="logo"></div>
          <a
            href="/profile/0/workhistory"
            style={{ marginLeft: "-696px", color: "white" }}
          >
            &lt; Back
          </a>
        </div>
        <div className="row justify-content-center projects"></div>
        <ProjectForm
          post_id={this.props.match.params.id}
          onAfterSave={() => (window.location.href = "/profile/0/workhistory")}
          onCancel={() => (window.location.href = "/profile/0/workhistory")}
          user={this.props.user}
        />
      </React.Fragment>
    );
  }

  handleSubmit() {
    const id = this.props.match.params.id;
    var params = {};
    var _title = document.getElementById("title").value;
    params["body"] = document.getElementById("body").value;
    params["post_type"] = 11;
    params["meta_data"] = {
      description: params["body"],
      title: _title,
      imdb_link: document.getElementById("imdb_link").value,
    };

    var api = new ApiRequest("post", "/posts");
    if (id) {
      api.url = "/posts/" + id;
      api.method = "put";
    }
    api.send({ post: params }, (result) => {
      window.location.href = "/profile/0/workhistory";
    });
  }

  loadProject() {
    const id = this.props.match.params.id;
    if (isset(id)) {
      let api = new ApiRequest("get", "/posts/" + id);
      api.send({}, (response) => {
        var _post = response.data.data.post;
        var _title = _post.meta_data.title ? _post.meta_data.title : _post.body;

        if (response.data.data.post.photos.length > 0) {
          var headerPanel = document.querySelector("#project-asset-view");
          headerPanel.style.backgroundImage =
            "url('" + response.data.data.post.photos[0].url + "')";
          document.querySelector(".project-camera").style.display = "none";
        }

        api.url = "/posts/new?post_type=13";
        api.method = "GET";
        api.send({}, (response) => {
          this.setState({
            post: _post,
            title: _title,
            description: _post.meta_data.description,
            imdb_url: _post.meta_data.imdb_url,
            questions: response.data.data.questions,
            post_type: 13,
          });
        });
      });
    }
  }

  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
}

export default EditProject;
