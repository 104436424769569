import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";

class Occupations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      occupations: [],
      selected_items: [],
      selected_occupation: null,
      questions: "",
      searchText: null,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  createMarkup(markup) {
    return { __html: markup };
  }

  listItemsOnly() {
    const childrens = this.state.occupations.map((c, idx) => {
      let checkedCss =
        this.state.selected_items.indexOf(c.id) > -1
          ? "checkbox-on"
          : "checkbox-off";
      let selectedCss =
        this.state.selected_occupation == c.id ? "round round-on" : "round";
      return (
        <div className="root" key={idx + "" + c.id}>
          <div className={selectedCss} key={idx}>
            <a href="#" onClick={() => this.handleClick(c.id)}>
              {c.name}
            </a>
            <input type="checkbox" id={c.id} />
            <label
              id={"label_" + c.id}
              htmlFor={c.id}
              className={checkedCss}
              occupation_id={c.id}
              onClick={() => this.handleClick(c.id)}
            />
          </div>
        </div>
      );
    });
    return <React.Fragment>{childrens}</React.Fragment>;
  }

  occupationItems() {
    if (this.state.searchText) {
      return this.listItemsOnly();
    }
    const items = this.state.occupations.map((item) => {
      const childrens = item.children.map((c, idx) => {
        let checkedCss =
          this.state.selected_items.indexOf(c.id) > -1
            ? "checkbox-on"
            : "checkbox-off";
        let selectedCss =
          this.state.selected_occupation == c.id ? "round round-on" : "round";
        return (
          <div className={selectedCss} key={idx}>
            <a href="#" onClick={() => this.handleClick(c.id)}>
              {c.name}
            </a>
            <input type="checkbox" id={c.id} />
            <label
              id={"label_" + c.id}
              htmlFor={c.id}
              className={checkedCss}
              occupation_id={c.id}
              onClick={() => this.handleClick(c.id)}
            />
          </div>
        );
      });
      return (
        <div className="root" key={item.id}>
          <div className="title" onClick={() => this.expandItems(item.id)}>
            {item.name}
          </div>
          <div id={"children_" + item.id} style={{ display: "none" }}>
            {childrens}
          </div>{" "}
          <div className="root-last"></div>
        </div>
      );
    });
    return items;
  }

  expandItems(id) {
    var _child = document.getElementById("children_" + id);
    if (_child.style.display == "none") {
      _child.style.display = "block";
    } else {
      _child.style.display = "none";
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid header">
          <div className="logo"></div>
        </div>
        <div className="row justify-content-center projects">
          <div className="header-panel large" id="project-asset-view">
            <div className="occupations">
              <h3>Search and select your occupation</h3>
              <input
                type="text"
                className="occupation-search"
                placeholder="Search"
                onKeyUp={this.handleSearch.bind(this)}
              />
              <div className="occupations-list col-6">
                <div className="results">{this.occupationItems()}</div>
              </div>
              <div
                className="occupation-questions col-6 pl-5 pt-2"
                dangerouslySetInnerHTML={this.createMarkup(
                  this.state.questions
                )}
              ></div>
              <div className=" d-flex flex-column  col-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => history.back()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSaveQuestions(occupation, fn) {
    var items = this.state.selected_items;
    var index = items.indexOf(occupation);
    if (index == -1) {
      fn("");
      return;
    }
    var qfields = document.querySelectorAll('input.question[type="checkbox"]');
    var answers = [];
    var obj = {};
    for (const a of qfields) {
      if (!a.checked) {
        continue;
      }
      const found = answers.find(
        ({ question_id }) => question_id === a.getAttribute("data-id")
      );
      if (!found) {
        obj = { question_id: a.getAttribute("data-id"), answer: [a.value] };
        answers.push(obj);
      } else {
        found["answer"].push(a.value);
      }
    }

    qfields = document.querySelectorAll(".question");
    for (const a of qfields) {
      if (a.type != "checkbox") {
        obj = { question_id: a.getAttribute("data-id"), answer: a.value };
        answers.push(obj);
      }
    }

    if (answers.length > 0) {
      var api = new ApiRequest("post", "/occupations/save_answers.html");
      api.send(
        {
          occupation_id: occupation,
          answers: answers,
        },
        (response) => {
          fn(response.data);
        }
      );
    } else {
      fn("");
    }
  }

  handleClick(occupation_id, showFormOnly = false) {
    var items = this.state.selected_items;
    this.handleSaveQuestions(this.state.selected_occupation, (saveRepsonse) => {
      if (showFormOnly) {
        this.handleSelectedOccupation(occupation_id);
        this.setState({ selected_occupation: occupation_id });
        return;
      }
      if (items.indexOf(occupation_id) === -1) {
        items.push(occupation_id);
        this.setState({
          questions: saveRepsonse,
          selected_items: items,
          selected_occupation: occupation_id,
        });
        this.handleSelectedOccupation(occupation_id);
      } else {
        var index = items.indexOf(occupation_id);
        if (index !== -1) items.splice(index, 1);
        this.setState({
          questions: saveRepsonse,
          selected_items: items,
          selected_occupation: null,
        });
      }
    });
  }

  handleSelectedOccupation(occupation_id) {
    let api = new ApiRequest("get", "/occupations/" + occupation_id + ".html");
    api.send({}, (response) => {
      this.setState({ questions: response.data });
    });
  }

  handleSearch(e) {
    let searchText = e.target.value;
    if (searchText.length > 2 || searchText == "") {
      let api = new ApiRequest(
        "get",
        "/occupations.json?children=1&search=" + searchText
      );
      api.send({}, (response) => {
        this.setState({
          occupations: response.data.data.occupations,
          searchText: searchText,
        });
      });
    }
  }

  componentDidMount() {
    let api = new ApiRequest("get", "/occupations.json");
    var userSelected = [];
    var _occupations = [];
    api.send({}, (response) => {
      _occupations = response.data.data.occupations;
      _occupations.map((oc) => {
        oc.children.map((child) => {
          if (child.is_selected) {
            userSelected.push(child.id);
          }
        });
      });
      this.setState({
        occupations: _occupations,
        selected_items: userSelected,
      });
    });
  }
}

export default Occupations;
