import React from "react";

import ApiRequest from "../../libs/request.js";

const ShareLink = ({ user, post }) => {
  const onClick = (e) => {
    e.preventDefault();

    // const comment = prompt("Leave comment");

    // if (comment) {
      var api = new ApiRequest("post", `/user/share-post`);

      api.send(
        {
          // comment: comment,
          post_id: post.id,
        },
        () => {
          window.location.href = "/";
        }
      );
    // }
  };

  return (
    <a href="#" onClick={onClick} className="likes">

      <i className="fas fa-share-alt"></i>

      <span
        style={{
          marginLeft: "5px"
        }}>{
          post.shared_post_count ? post.shared_post_count : ""
        }</span>
      <span style={{ marginLeft: "5px" }}>
        Share</span>
    </a>
  );
};

export default ShareLink;
