import React, { Component } from "react";
import ApiRequest from "../../libs/request";

function DownloadLink(_props) {
  if (_props.resume && _props.resume != "") {
    return (
      <div className="pt-4 select-field resume">
        <b>Resume</b>
        <br />
        <div className="location">
          <a href={_props.resume} onClick={() => _props.onDownloadClick}>
            <u>download</u>
          </a>
        </div>
      </div>
    );
  } else {
    return <div>&nbsp;</div>;
  }
}
class AdditionalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reels: [],
      location: { city: this.props.user.city, state: this.props.user.state },
    };
  }

  handleFileClick(mode) {
    if (mode == "download") {
      window.location.href = this.state.resume;
      return;
    }
  }

  render() {
    var _info = [];
    console.log("AdditionalInfo.js", this.props.user.additional_info);
    return (
      <React.Fragment>
        <h2>Additional Info</h2>
        <div className="col-12 m-0 p-0 mt-12 other">
          <div className="select-field">
            <b>Location</b>
            <br />
            {this.props.user.additional_info ? <div className="location">
              {this.props.user.additional_info.location}
            </div> : 
            <div className="location">
            {this.state.location.city}, {this.state.location.state}
          </div>}
          </div>
          <DownloadLink
            resume={this.state.resume}
            onDownloadClick={() => this.handleFileClick("download").bind(this)}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default AdditionalInfo;
