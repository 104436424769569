import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import PostQuestionList from "../../components/main/PostQuestionList";
import ProjectBanner from "./ProjectBanner";
import ReactSelect from "react-select";
import { isset } from "../../libs/functions.js";
// import MultiSelect from "../main/MultiSelect";


class ScreenPlayForm extends Component {
  constructor(props) {
    super(props);
    var _post = this.props.post ? this.props.post : {};
    this.state = {
      post: _post,
      questions: [],
      post_type: 5,
      id: null,
      film_genere: [],
      genres: [],
      title: "",
      page_count: null,
      log_line: "",
      synopsis: "",
      form_errors: [],
      media: [],
      screen_play: {},
      post_id: this.props.post_id,
    };
  }


  render() {
    if (!this.state.post && !this.props.post) {
      return <div />;
    }
    var title = this.props.post ? this.props.post.body : "";
    var _post_id = this.props.post ? this.props.post.id : 0;
    var _errorsStr = "";
    if (this.state.form_errors.length > 0) {
      _errorsStr = this.state.form_errors.join(" , ");
    }
    const { screen_play } = this.state;
    return (
      <React.Fragment>
        <div className="row justify-content-center projects">
          <ProjectBanner
            post_id={_post_id}
            onBannerUploaded={(post) => this.setState({ media: post })}
            post_type_id={5}
          />
        </div>
        <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
          <h2>Screenplay</h2>
          <input type="hidden" name="id" id="post_id" value="" />
          <input
            type="hidden"
            value="2"
            id="post_type_id"
            name="post[post_type]"
          />

          <div className="col-12 m-0 p-0">
            <div className="form-group">
              <div
                id="errors-panel"
                style={{ display: _errorsStr.length > 0 ? "block" : "none" }}
                className="alert alert-danger"
              >
                {_errorsStr}
              </div>
              <label>Screenplay Title</label>
              <input
                type="text"
                id="title"
                name="title"
                defaultValue={screen_play.name || this.state.title}
                placeholder="Enter screenplay title"
                className="form-control"
                onChange={(e) => this.setState({ title: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Page Count</label>
              <input
                type="number"
                id = "page_count"
                placeholder="Enter page count"
                defaultValue={screen_play.page || this.state.page_count}
                onChange={(e) => this.setState({ page_count: e.target.value })}
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Log Line</label>
              <input
                type="text"
                id="log_line"
                placeholder="Enter log line"
                className="form-control"
                defaultValue={screen_play.log || this.state.log_line}
                onChange={(e) => this.setState({ log_line: e.target.value })}
              />
            </div>
            <div className="form-group">
              <label>Synopsis</label>
              <textarea
                className="form-control"
                placeholder="Enter synopsis"
                id = "synopsis"
                defaultValue={screen_play.synopsis || this.state.synopsis}
                onChange={(e) => this.setState({ synopsis: e.target.value })}
              ></textarea>
            </div>
          </div>
          <div className="form-group">
            <label>Film Genre</label>
            <ReactSelect options={this.state.genres} isMulti
            defaultValue={screen_play.genre_categories ? 
              screen_play.genre_categories.map((genre) => {
                return { value: genre.id, label: genre.category }
              })
              : 
              this.state.film_genere}
              id="film_genere"
            onChange={(e) => this.setState({ film_genere: e })}
            placeholder="Select Film Genre"
            />
            {/* <MultiSelect
              id={280}
              hideButton={true}
              question_id={5}
              post_type={5}
              placeholder="Select Genre"
              selectedTypes={this.state.film_genere}
              onCompanyTypesSelected={(items) =>
                this.setState({ film_genere: items })
              }
            /> */}
            
          </div>
          {/* <div>
            <div className="form-group">
              <label>Attach PDF</label>
              <input
                type="file"
                id="screenplay-pdf"
                name="media"
                className="form-control"
                // onChange={(e) => this.setState({ media: e.target.files[0] })}
              />
            </div>
          </div> */}

          <div className="col-12 p-0" style={{ float: "left" }}>
            <button
              className="g-button full"
              onClick={this.handleSubmit.bind(this)}
            >
              Save Screenplay
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }


  

  loadProject() {
    let id = this.props.screenplayID;
    if (isset(id)) {
      let api = new ApiRequest("get", "/user/screenplay-details?id=" + id);
      api.send({}, (response) => {
        var _post = response.data.screenplay;

        if (response.data.screenplay.media.length > 0) {
          var headerPanel = document.querySelector("#project-asset-view");
          headerPanel.style.backgroundImage =
            "url('" + response.data.screenplay.media + "')";
          document.querySelector(".project-camera").style.display = "none";
        }

        this.setState({
          screen_play: _post,
          });
      
       
      });
    } 
  }

  componentDidMount() {
    let api = new ApiRequest("get", "/all-genres-screaning");
    api.send({}, (response) => {
     const options = response.data.genres.map((genre) => {
       return { value: genre.id, label: genre.category }
     });
      this.setState({
        genres: options
      });
    });

    this.loadProject();
  }

  validate(fields) {
    document.getElementById("errors-panel").style.display = "none";
    var errors = [];
    if (document.getElementById("title").value == "") {
      errors.push("Title is required");
    }
    fields.forEach((f) => {
      if (f.dataset["required"] == "true" && f.value.length < 1) {
        errors.push(f.dataset["name"] + " is required");
      }

      if (
        f.dataset["keyboard_type"] &&
        f.dataset["keyboard_type"] == "number" &&
        f.dataset["field_type"] != "phone" &&
        f.value.length > 1 &&
        isNaN(f.value)
      ) {
        errors.push(f.dataset["name"] + " must be a number");
      }

      if (
        f.dataset["field_type"] == "phone" &&
        f.value.length > 1 &&
        !this.phonenumber(f.value)
      ) {
        errors.push(f.dataset["name"] + " is not a valid phone number");
      }
      if (
        f.dataset["field_type"] == "email" &&
        f.value.length > 1 &&
        !this.validateEmail(f.value)
      ) {
        errors.push(f.dataset["name"] + " is not a valid email");
      }
      if (
        f.dataset["field_type"] == "date" &&
        f.value.length > 1 &&
        !Object.prototype.toString.call(f.value) === "[object Date]"
      ) {
        errors.push(f.dataset["name"] + " is not a valid date");
      }
    });
    if (errors.length > 0) {
      document.getElementById("errors-panel").innerHTML = errors.join("<br/>");
      document.getElementById("errors-panel").style.display = "block";
      window.scrollTo(0, 0);
    }
    return errors;
  }

  phonenumber(val) {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return val.match(phoneno);
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  handleSubmit() {
    var _state = this.state;
    var _errors = [];

    if (_state.title.length < 1) {
      _errors.push("Please enter a title");
    }

    if (_state.film_genere.length < 1) {
      _errors.push("Please select a Genre");
    }

    this.setState({ form_errors: _errors });
    if (_errors.length > 0) {
      window.scrollTo(0, 0);
      return;
    }

    var search_params = {};
    search_params["answers"] = [];

    if (_state.title.length > 0) {
      search_params["answers"].push({
        question_id: 660,
        answer: _state.title,
      });
    }

    if (_state.log_line.length > 0) {
      search_params["answers"].push({
        question_id: 663,
        answer: _state.log_line,
      });
    }
    if (_state.synopsis.length > 0) {
      search_params["answers"].push({
        question_id: 664,
        answer: _state.synopsis,
      });
    }

    if (_state.film_genere.length > 0) {
      search_params["answers"].push({
        question_id: 665,
        answer: _state.film_genere,
      });
    }
    if (_state.page_count) {
      search_params["answers"].push({
        question_id: 662,
        answer: _state.page_count,
      });
    }
    if (_state.film_genere.maximum > 0) {
      search_params["answers"].push({
        question_id: 1500,
        answer: { less_than: _state.maximum, greater_than: _state.minimum },
      });
    }
    var id = this.props.screenplayID;
    
    var params = { 
      name: this.state.title,
      genres: this.state.film_genere.map((genre) => genre.value),
      page: this.state.page_count,
      log: this.state.log_line,
      synopsis: this.state.synopsis,
      media: this.state.media

    };

    const _formData = new FormData();
    _formData.append("name", this.state.title);
    _formData.append("genres[]", 
      this.state.film_genere.map((genre) => genre.value)
     );
    _formData.append("page", this.state.page_count);
    _formData.append("log", this.state.log_line);
    _formData.append("synopsis", this.state.synopsis);
    _formData.append("media", this.state.media);

    var api = new ApiRequest("post", "/user/add-screenplay");
    if (id) {
      api.method = "post";
      api.url = "/user/edit-screenplay" ;
      params["id"] = id;
    }
    id ? api.send(params, (result) => {
      this.props.onAfterSave(result.data.message);
    }) : api.send_file(_formData, (result) => {

      this.props.onAfterSave(result.data.message);
    });
  }

  ___handleSubmit() {
    var id = this.state.post_id ? this.state.post_id : null;
    if (this.props.post && this.props.post.id) {
      id = this.props.post.id;
    }
    var _required = [];
    var fields = document.querySelectorAll(".form-control");
    var answers = [];
    var params = {};
    var _errors = this.validate(fields);

    this.setState({ form_errors: _errors });
    if (_errors.length > 0) {
      return;
    }
    fields.forEach((f) => {
      if (!params["body"]) {
        params["body"] = f.value;
      }
      var _txtAns = f.options ? f.options[f.selectedIndex].text : f.value;
      answers.push({
        question_id: parseInt(f.dataset.id),
        answer: f.value,
        textAnswer: _txtAns,
        question: f.dataset.question,
      });
    });
    params["answers"] = { answers: answers };
    params["post_type"] = this.state.post_type;
    params["public"] = true;
    params["meta_data"] = { answers: answers };
    var api = new ApiRequest("post", "/posts");
    if (id) {
      api.method = "put";
      api.url = "/posts/" + id;
    }
    api.send({ post: params }, (result) => {
      this.props.onAfterSave(result.data.data.post.id);
    });
  }
}

export default ScreenPlayForm;
