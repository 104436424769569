import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import PostQuestionList from "./PostQuestionList";
import TaggingField from "./TaggingField";
import PhotoEdit from "./PhotoEdit";

class PostQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_errors: [],
      answers: [],
      questions: [],
      post: { meta_data: { answers: [] } },
      currentTag: "",
      friends: [],
      taggedFriends: [],
      deletedPhotos: [],
    };
  }

  render() {
    let _type_name = this.state.questions[0]
      ? this.state.questions[0].post_type_name
      : "";
    var _fields = [];
    if (this.props.match.params["type_id"] == "2") {
      _fields = [
        "Enter Project Name",
        "Select Project Type",
        "Enter Director name",
        "Select UNION TYPE",
        "Enter Contact Number",
        "Select Rate",
        "Select Geographic Region",
        "Project Start Date",
        "Project End Date",
        "Select Nudity",
        "Select Callback Date",
        "Select Interview/Audition Date",
        "Select Start/Shoot date",
        "Select Work Radius",
        "Enter Email",
        "Select Crew Type",
        "Select Location",
        "Enter Casting Director Display Name",
      ];
    }
    if (this.props.match.params["type_id"] == "3") {
      _fields = [
        "Select Location",
        "Start Date",
        "End Date",
        "Select Crew Type",
      ];
    }
    if (this.props.match.params["type_id"] == "4") {
      _fields = ["Enter Location", "Select occupation"];
    }
    if (this.props.match.params["type_id"] == "6") {
      _fields = [
        "ENTER SONG TITLE",
        "Enter Link",
        "Musical Style",
        "Tempo",
        "Mood / Emotion",
        "Production Genre",
        "Instrumentation",
        "Select Duration",
      ];
    }
    if (this.props.match.params["type_id"] == "7") {
      _fields = [
        "Select Project Type",
        "Are you seeking investors",
        "MINIMUM AMOUNT $",
        "MAXIMUM AMOUNT $",
      ];
    }

    if (this.props.match.params["type_id"] == "10") {
      _fields = [
        "Select Film Genre",
        "MINIMUM PAGE COUNT",
        "MAXIMUM PAGE COUNT",
      ];
    }
    let _mode = "post-questions";
    var _form = (
      <PostQuestionList
        answers={this.state.answers}
        post={this.state.post}
        questions={this.state.questions}
        fields={_fields}
      />
    );
    if (
      this.state.refresh ||
      (this.props.match.params["type_id"] == "1" && this.state.post.post)
    ) {
      _type_name = "Edit Post";
      _form = (
        <div className="col-12 mb-4 pt-3 pb-3" style={{ background: "#fff" }}>
          <TaggingField
            user={this.props.user}
            placeholder={`What are you up to${this.props.user.first_name ? " "+this.props.user.first_name : ""}?`}
            defaultValue={this.state.post}
            onTaggedItem={(data) => this.handleTagged(data)}
          />
        </div>
      );
    }
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
              <div className={_mode}>
                <h4>{_type_name}</h4>
                <hr />
                <div
                  id="errors-panel"
                  style={{ display: "none" }}
                  className="alert alert-danger"
                />
                <form id="postform">{_form}</form>
              </div>
              <button
                type="button"
                onClick={this.handleSubmit.bind(this)}
                className="g-button full"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  handleTagged(data) {
    this.setState(data);
  }

  validate(fields) {
    document.getElementById("errors-panel").style.display = "none";
    var errors = [];
    fields.forEach((f) => {
      if (f.dataset["required"] == "true" && f.value.length < 1) {
        errors.push(f.dataset["name"] + " is required");
      }

      if (
        f.dataset["keyboard_type"] &&
        f.dataset["keyboard_type"] == "number" &&
        f.dataset["field_type"] != "phone" &&
        f.value.length > 1 &&
        isNaN(f.value)
      ) {
        errors.push(f.dataset["name"] + " must be a number");
      }

      if (
        f.dataset["field_type"] == "phone" &&
        f.value.length > 1 &&
        !this.phonenumber(f.value)
      ) {
        errors.push(f.dataset["name"] + " is not a valid phone number");
      }
      if (
        f.dataset["field_type"] == "email" &&
        f.value.length > 1 &&
        !this.validateEmail(f.value)
      ) {
        errors.push(f.dataset["name"] + " is not a valid email");
      }
      if (
        f.dataset["field_type"] == "date" &&
        f.value.length > 1 &&
        !Object.prototype.toString.call(f.value) === "[object Date]"
      ) {
        errors.push(f.dataset["name"] + " is not a valid date");
      }

      var startDate = document.querySelector("input[data-name='Start Date']");
      var endDate = document.querySelector("input[data-name='End Date']");
      if (f.dataset["field_type"] == "date" && startDate && endDate) {
        var today = new Date(new Date() + " EDT");
        today.setHours(0, 0, 0, 0);
        var sdt = new Date(startDate.value + " EDT");
        sdt.setHours(0, 0, 0, 0);
        var edt = new Date(endDate.value + " EDT");
        edt.setHours(0, 0, 0, 0);
        if (sdt < today || edt < today) {
          var msg = "Dates can not be in the past";
          if (errors.indexOf(msg) == -1) {
            errors.push(msg);
          }
        }
        if (Date.parse(startDate.value) > Date.parse(endDate.value)) {
          var msg = "State date can not be greater than End date";
          if (errors.indexOf(msg) == -1) {
            errors.push(msg);
          }
        }
      }

      [
        "Select Interview/Audition Date",
        "Select Callback Date",
        "Select Start/Shoot date",
      ].map((item) => {
        var dt = document.querySelector("input[data-name='" + item + "']");
        if (dt) {
          var today = new Date(new Date() + " EDT");
          today.setHours(0, 0, 0, 0);
          var sdt = new Date(dt.value + " EDT");
          sdt.setHours(0, 0, 0, 0);
          if (sdt < today) {
            var msg = "Dates can not be in the past";
            if (errors.indexOf(msg) == -1) {
              errors.push(msg);
            }
          }
        }
      });
    });
    if (errors.length > 0) {
      document.getElementById("errors-panel").innerHTML = errors.join("<br/>");
      document.getElementById("errors-panel").style.display = "block";
      window.scrollTo(0, 0);
    }
    return errors;
  }

  phonenumber(val) {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return val.match(phoneno);
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  handleSubmit() {
    if (this.state.answers.length == 0 && this.state.post.id) {
      var api = new ApiRequest("post", "/user/edit-post");
      let params = {
        post: document.querySelector("#description").innerText,
        post_id: this.state.post.id
      };
      
      var companyId = localStorage.getItem("selectedCompany") ? JSON.parse(localStorage.getItem("selectedCompany"))[0].id : null;

      if (companyId) {
        params["company_id"] = companyId;
      }

      params["tag_friend_ids"] = this.state.taggedFriends.map((u) => u.id);
      
      api.send( params, (result) => {
        window.location.href = window.location.origin;
      });
      return;
    }
    var _required = [];
    var fields = document.querySelectorAll(".form-control");
    var answers = [];
    var params = {};
    var _errors = this.validate(fields);
    this.setState({ form_errors: _errors });
    if (_errors.length > 0) {
      return;
    }

    fields.forEach((f) => {
      if (!params["body"]) {
        params["body"] = f.value;
      }
      var _txtAns = f.options ? f.options[f.selectedIndex].text : f.value;
      answers.push({
        question_id: f.dataset.id,
        answer: f.value,
        textAnswer: _txtAns,
        question: f.dataset.question,
      });
    });

    params["answers"] = { answers: answers };
    params["post_type"] = this.state.post_type;
    params["public"] = true;
    params["meta_data"] = { answers: answers };
    var api = new ApiRequest("post", "/posts");
    if (this.state.post.id) {
      api.url = "/posts/" + this.state.post.id;
      api.method = "put";
    }
    api.send({ post: params }, (result) => {
      window.location.href = "/?id=" + result.data.data.post.id;
    });
  }

  componentDidMount() {
    var _state = this.state;
    var params = new URLSearchParams(window.location.search);
    var api = new ApiRequest(
      "get",
      "/posts/new?post_type=" + this.props.match.params["type_id"]
    );
    if (this.props.match.params["post_id"]) {
      api.url = "/user/show-post/" + this.props.match.params["post_id"];
      api.send({}, (results) => {
        console.log("single post", results.data.post);
        _state["post"] = results.data.post;
        this.setState(_state);
      });
    } 
  }
}

export default PostQuestions;
