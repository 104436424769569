import React, { Component } from "react";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import ApiRequest from "../../libs/request";
import MultiSelect from "./MultiSelect";
import LocationSeach from "./LocationSearch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSelect from "react-select";
import GooglePlacesAutoComplete from "../shared/location/location";

class SeekingWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crew_types: [],
      location: {},
      startDate: null,
      endDate: null,
      range: 0,
      errors: [],
      genres: [],
      selectedGenres: [],
      location_name: "",
    };
  }

  componentDidMount() {

    let genreApi = new ApiRequest("get", "/user/crew-types");
    genreApi.send({}, (response) => {
      const options = response.data.types.map((primaryType) => {
        const primaryOption = {
          label: primaryType.name,
          options: primaryType.secondary.map((secondaryType) => ({
            value: secondaryType.id,
            label: secondaryType.name,
          })),
        };
        return primaryOption;
      });

      this.setState({ genres: options });
    });

  }

  handleSearch() {
    var _state = this.state;
    var _errors = [];
    // if (_state.crew_types.length < 1) {
    //   _errors.push("Please select at least one Work Type");
    // }
    // if (_state.location.length == 0) {
    //   _errors.push("Please select a location");
    // }

    // this.setState({ errors: _errors });
    // if (_errors.length > 0) {
    //   return;
    // }

    // var search_params = {};
    // search_params["answers"] = [];
    // if (_state.crew_types.length > 0) {
    //   search_params["answers"].push({
    //     question_id: 715,
    //     answer: _state.crew_types,
    //   });
    // }

    // if (_state.startDate) {
    //   search_params["answers"].push({
    //     question_id: 757,
    //     answer: _state.startDate,
    //   });
    // }
    // if (_state.endDate) {
    //   search_params["answers"].push({
    //     question_id: 758,
    //     answer: _state.endDate,
    //   });
    // }

    // if (_state.location.length > 0) {
    //   search_params["near"] = {
    //     coordinates: _state.location,
    //     radius: _state.range,
    //   };
    // }
    // search_params["post_type"] = [3];
    // localStorage.setItem("seeking_search", JSON.stringify(search_params));
    // localStorage.setItem("seeking_type", "work");

    // window.location.href = "/?seeking=1";
    window.location.href = "/";
  }

  selectedLocation(loc) {
    this.setState({ location_name: loc.label });
  }

  render() {
    var _showErrors = this.state.errors.length > 0 ? "block" : "none";
    var _errorStr = this.state.errors.join(" , ");

    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
              <form className="seeking container">
                <h4>Seeking Work</h4>
                <div
                  className="alert alert-danger"
                  style={{ display: _showErrors }}
                >
                  {_errorStr}
                </div>
                <div className="row" style={{ marginBottom: "12px" }}>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Work Type</label>
                      {/* <MultiSelect
                        id={715}
                        hideButton={true}
                        question_id={89}
                        post_type={3}
                        placeholder="Select Work Type"
                        selectedTypes={this.state.crew_types}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ crew_types: items })
                        }
                      /> */}
                      <div className="react-select-styled">
                        <ReactSelect
                          options={this.state.genres}
                          isMulti
                          value={this.state.selectedGenres}
                          onChange={(selectedOptions) => this.setState({ selectedGenres: selectedOptions })}
                        />
                      </div>

                    </div>
                  </div>
                  <div className="col-6">
                    <label>Location</label>
                    {/* <LocationSeach
                      onLocationSelected={(loc) =>
                        this.setState({ location: loc })
                      }
                    /> */}

                    <GooglePlacesAutoComplete selectedLocation={this.selectedLocation.bind(this)} />

                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="form-group"
                      style={{ backgroundColor: "#fff", padding: "12px" }}
                    >
                      <label>WORK RADIUS</label>
                      <input
                        type="range"
                        className="form-control"
                        min="1"
                        max="12500"
                        step="1"
                        onChange={(e) =>
                          this.setState({ range: e.target.value })
                        }
                      />
                      <span className="sliderVal">{this.state.range || 0}</span>{" "}
                      miles
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>WORK DURATION (Start Date)</label>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>WORK DURATION (End Date)</label>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={this.handleSearch.bind(this)}
                        className="g-button full"
                      >
                        Seek Available Work
                      </button>
                      This search will list out all available work matching your
                      searching criterea but not notify them.
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SeekingWork;
