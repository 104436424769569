/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import Alerts from "./Alerts";
import Popup from "reactjs-popup";
import ApiRequest from "../../libs/request";
import FeedFilters from "./FeedFilters";
import { params } from "../../libs/functions";
import SettingsPanel from "./SettingsPanel";
import SearchResults from "./SearchResults";
import MessagesPanel from "./MessagesPanel";
import { FaTimes, FaTimesCircle } from "react-icons/fa";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = { alerts: 0, search_results: {} };
  }

  render() {
    if (this.props.partialNav) {
      return this.renderPartialNav();
    } else {
      return this.renderFullNav();
    }
  }

  renderPartialNav() {
    return (
      <React.Fragment>
        <div className="header main">
          <div className="container">
            <div
              className="logo-feed"
              onClick={() => {
                location.href = "/";
              }}
            ></div>
            {/* <div className="settings-pop" style={{ float: "right" }}>
              <Popup
                trigger={(open) => (
                  <div className="settings" title="Settings" />
                )}
                position="bottom center"
                closeOnDocumentClick
              >
                <div className="settings-panel">
                  <div className="label" onClick={this.handleSignout.bind(this)}>
                    <img src="/images/logout.png" />
                    Logout
                  </div>
                </div>
              </Popup>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
  renderFullNav() {
    if (this.props.user == null) {
      return <div />;
    }
    var _alerts_count = [];
    if (this.state.alerts == 0) {
      _alerts_count.push(<div key="alerts-count" />);
    } else {
      _alerts_count.push(
        <div key="alerts-count" className="alerts-count">
          {this.state.alerts}
        </div>
      );
    }
    var _searchPreviews = "";
    var _showSearch = "none";
    var _avatar_image = this.props.user.profile_photo != null ? this.props.user.profile_photo : "/images/placeholder.png";
    if (this.state.search_results.length > 0) {
      _showSearch = "block";
      _searchPreviews = (
        <SearchResults
          user={this.props.user}
          results={this.state.search_results}
        />
      );
    }

    return (
      <React.Fragment>
        <div className="header main">
          <div className="row row-margin0">
            <div className="col-md-6">
              <div
                className="logo-feed"
                onClick={() => {
                  location.href = "/";
                }}
              ></div>
              <input
                type="text"
                placeholder="Search"
                id="search-nav-input"
                className="nav-search"
                autoComplete="off"
                defaultValue={params("search")}
                onFocus={this.handleFocusClick.bind(this)}
                onKeyUp={this.handleSearch.bind(this)}
              />
              <div className="search-preview" style={{ display: _showSearch }}>
                <FaTimesCircle className="close-search" onClick={() => {
                  this.setState({
                    search_results: {}
                  })
                  document.getElementById("search-nav-input").value = "";
                }} />
                <div style={{ marginTop: "20px" }}>
                  {_searchPreviews}
                </div>
              </div>
              {/* <div className="filter-options">
                <Popup
                  trigger={(open) => (
                    <img src="/images/options.png" className="options-btn" />
                  )}
                  position="bottom center"
                  closeOnDocumentClick
                >
                  <FeedFilters />
                </Popup>
              </div> */}
            </div>
            <div className="col-md-6 nav-items-right">

              <div className="row" >
                <div className="col-md-12">
                  <div className="nav-right-content">
                    <div className="alerts-control">
                      <Popup
                        trigger={(open) => (
                          <div className="header-messages" style={{ right: '7px' }} title="Notifications">
                            <div >
                              {_alerts_count}
                            </div>
                            <i className="fas fa-bell header-bell-icon"></i>
                          </div>
                        )}
                        position="bottom right"
                        closeOnDocumentClick
                      >
                        <Alerts
                          user={this.props.user}
                          onAlertsChange={this.getAlertCount.bind(this)}
                        />
                      </Popup>
                    </div>

                    {/* <a href="/messages" title="New Message">
                      <div className="header-messages">
                        <i className="fas fa-comment-dots header-msg-icon"></i>
                      </div>
                    </a> */}
                    <div className="messages-control">
                      <Popup
                        trigger={(open) => (
                          <div className="header-messages" style={{ right: '7px', cursor: 'pointer' }} title="Messages">
                            <div className="header-messages">
                              <i className="fas fa-comment-dots header-msg-icon"></i>
                            </div>
                          </div>
                        )}
                        position="bottom right"
                        closeOnDocumentClick
                      >
                        <div style={{
                          float: "left",
                          textAlign: "left"
                        }} >

                          <MessagesPanel user={this.props.user} nav={true} />
                        </div>

                      </Popup>
                    </div>
                    <div className="settings-pop">
                      <Popup
                        trigger={(open) => (
                          <div className="user-avatar">
                            <img src={_avatar_image} className="header-avatar" />
                            <i className="fas fa-angle-down avatar-angleDownIcon"></i>
                          </div>
                        )}
                        position="bottom right"
                        closeOnDocumentClick
                      >
                        <SettingsPanel user={this.props.user} userImage={_avatar_image} userName={this.props.user.first_name ? `${this.props.user.first_name} ${this.props.user.last_name}` : "Username"} />
                      </Popup>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSearch(e) {
    var _obj = e;
    // if (_obj.keyCode != 13) {
    //   return;
    // }
    if (_obj.target.value.length > 2) {
      var _type = params("type") ? params("type") : 1;
      var api = new ApiRequest(
        "get",
        "/user/search-friends?search=" +
        _obj.target.value
      );
      api.send({}, (response) => {
        this.setState({ search_results: response.data.users.data });
      });
    } else {
      this.setState({ search_results: {} });
    }
    //location.href = "/?type=" + _type + "&search=" + _obj.target.value;
  }

  handleFocusClick() {
    var api = new ApiRequest(
      "get",
      "/user/search-friends?search=a"
    );
    api.send({}, (response) => {
      if (response.data.users.data.length > 0) {
        // attach first 10 results
        const _results = response.data.users.data.slice(0, 10);
        this.setState({ search_results: _results });
      }
    });
  }

  handleSignout() {
    var api = new ApiRequest("delete", "/sessions/" + this.props.user.id);
    api.send({}, () => {
      localStorage.removeItem("gorilla-token");
      window.location.href = "/";
    });
  }

  componentDidMount() {
    this.getAlertCount();
  }

  getAlertCount() {
    if (Object.keys(this.props.user).length === 0) {
      return;
    }
    var api = new ApiRequest("get", "/user/get-notifictions");
    api.send({}, (response) => {
      this.setState({
        alerts:
          response.data.notifications ? response.data.notifications.filter((a) => a.status == "unread").length : 0
      });
    });
  }
}

export default Nav;
