import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import Tagging from "./Tagging";
import EmojiPicker from "./EmojiPicker";
import { highLightTags, urlify, validFile } from "../../libs/functions.js";

import PhotoEdit from "./PhotoEdit";

class TaggingField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      friends: [],
      taggedFriends: [],
      currentTag: "",
      defaultValue: this.props.defaultValue,
    };
    this.handleTaggedItem = this.handleTaggedItem.bind(this);
  }

  render() {
    var _photoControls = (
      <React.Fragment>
        <img
          className="camera-btn"
          style={{ float: "right", width: "35px", marginTop: "-32px" }}
          src="/images/status-camera.png"
          onClick={this.handleFileClick.bind(this)}
        />

        <input
          type="file"
          className="hidden-upload-field"
          name="photos[]"
          multiple
          id="photo-field"
          onChange={this.handleFileUpload.bind(this)}
        />
        <img
          src="/images/loading.gif"
          style={{ display: "none" }}
          className="loading"
          id="loader"
        />
      </React.Fragment>
    );

    if (this.props.hidePhotoControls) {
      _photoControls = "";
    }

    return (
      <React.Fragment>
        <div
          className="textarea taggingfield"
          placeholder={this.props.placeholder}
          id="description"
          onKeyUp={this.handleTagging.bind(this)}
          contentEditable
          dangerouslySetInnerHTML={{
            __html: urlify(highLightTags(this.props.defaultValue)),
          }}
        ></div>
        {/* <EmojiPicker
          style={{
            padding: "12px  0 0 12px",
          }}
          field="description"
          isHtml
        /> */}

        <Tagging
          onTagSelected={(friend) => this.handleTaggedItem(friend)}
          friends={this.state.friends}
        />
        {_photoControls}
        <PhotoEdit
          post={this.state.defaultValue}
          onPhotoDelete={(item) => this.removePhoto(item)}
        />
      </React.Fragment>
    );
  }

  removePhoto(item) {
    var api = new ApiRequest(
      "delete",
      "/posts/" + this.state.defaultValue.id + "/delete_asset"
    );
    api.send({ asset_id: item.id }, () => {});
  }

  handleFileClick() {
    document.getElementById("photo-field").click();
  }

  handleFileUpload() {
    document.getElementById("loader").style.display = "block";
    var api = new ApiRequest("put", "/posts/" + this.props.defaultValue.id);
    var formData = new FormData();
    var imagefile = document.querySelector("#photo-field");
    if (!validFile(imagefile, "photo")) {
      return;
    }
    formData.append("post[assets][][data]", imagefile.files[0]);
    formData.append("post[assets][][type]", "photo");
    api.send_file(formData, (response) => {
      document.getElementById("loader").style.display = "none";
      this.setState({ defaultValue: response.data.data.post });
    });
  }

  handleTagging(e) {
    if (e.keyCode == 50 && !this.state.taggingActive) {
      this.setState({ taggingActive: true });
    }
    if (this.state.taggingActive) {
      var obj = document.getElementById("description");
      var value = obj.innerHTML;
      var lastInstance = value.lastIndexOf("@");
      var tagSearch = value.substring(lastInstance + 1, value.length);
      tagSearch = tagSearch.replace(/(<([^>]+)>)/gi, "");
      if (tagSearch.length > 2) {
        var api = new ApiRequest("get", "/user/search-friends?search=" + tagSearch);
        api.send({}, (response) => {
          this.setState({
            currentTag: tagSearch,
            friends: response.data.users.data,
          });
        });
      } else {
        this.setState({ friends: [] });
      }
    }
  }

  handleTaggedItem(friend) {
    var _taggedFriends = this.state.taggedFriends;
    if (_taggedFriends.indexOf(friend) == -1) {
      _taggedFriends.push(friend);
      var obj = document.getElementById("description");
      var _text = obj.innerHTML;
      _text = _text.replace(
        "@" + this.state.currentTag,
        "<b className='tagged-node' id='" +
          friend.id +
          "' contenteditable='false' style='color:orange'>" +
          friend.first_name +
          " " +
          friend.last_name +
          "</b>&nbsp;"
      );
      obj.innerHTML = _text;
    }
    console.log("_taggedFriends", _taggedFriends);
    var data = {
      currentTag: "",
      taggedFriends: _taggedFriends,
      friends: [],
      taggingActive: false,
    };
    this.props.onTaggedItem(data);
    this.setState(data);
  }
}

export default TaggingField;
