import React, { Component } from "react";
import axios from "axios";
import ApiRequest from "../../libs/request";

class LocationSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      selected_names: this.props.selected_names || [],
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(val) {
    if (this.props.onLocationComplete) {
      this.props.onLocationComplete(val["description"]);
    }
    this.setState({ selected_names: [val["description"]] });
    var api = new ApiRequest("post", "/posts/location_search");
    api.send({ place_id: val["place_id"] }, (result) => {
      this.props.onLocationSelected([
        result.data.data["lat"],
        result.data.data["lng"],
      ]);
    });
    this.handleDone();
  }

  locationItems() {
    const items = this.state.results.map((item, idx) => {
      return (
        <div
          key={idx}
          onClick={() => this.handleClick(item)}
          style={{ padding: "12px", cursor: "pointer" }}
        >
          {item.description}
        </div>
      );
    });
    return items;
  }

  render() {
    var _itemsCss = this.state.results.length > 0 ? "block" : "none";
    var _names = [];
    this.state.selected_names.map((n, idx) => {
      if (n.length > 0) {
        _names.push(
          <div
            key={idx}
            onClick={() => this.removeLocation(idx)}
            className="ct-bubble"
          >
            {n}
          </div>
        );
      }
    });

    return (
      <React.Fragment>
        <div className="ctype-bubbles">{_names}</div>
        <input
          id="loc-search"
          className="form-control nooutline"
          placeholder="Search locations"
          style={{ lineHeight: "42px" }}
          onKeyUp={this.handleSearch.bind(this)}
        />
        <div id="location-list" style={{ display: _itemsCss, padding: "2px" }}>
          <div
            className="autosearch occupations-list"
            style={{ textAlign: "left" }}
          >
            <div
              className="results"
              style={{ width: "100%", maxHeight: "200px", overflow: "auto" }}
            >
              {this.locationItems()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSearch() {
    var input = document.getElementById("loc-search").value;
    if (input.length < 2) {
      return;
    }

    var api = new ApiRequest("post", "/posts/location_search");
    api.send({ search: input }, (response) => {
      this.setState({ results: response.data.data });
      document.querySelector("#location-list").style.display = "block";
    });
  }

  handleDone() {
    document.querySelector("#location-list").style.display = "none";
    document.querySelector("#loc-search").value = "";
  }

  removeLocation(index) {
    var _names = this.state.selected_names;

    if (index !== -1) _names.splice(index, 1);
    this.setState({ selected_names: _names });
    this.props.onLocationSelected([]);
  }
}

export default LocationSearch;
