import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import { FaTimes } from "react-icons/fa";

class MessageRecipients extends Component {
  constructor(props) {
    super(props);
    this.state = { friends: [], selectedFriends: [] };
    this.handleSelect = this.handleSelect.bind(this);
    this.removeRecip = this.removeRecip.bind(this);
  }

  render() {
    var _selectedFriends = [];
    var _friends = [];
    this.state.selectedFriends.map((s, idx) => {
      _selectedFriends.push(
        <div
          key={idx}
          className="recip-bubble"
        >
          {s.first_name} {s.last_name}
          <FaTimes onClick={() => this.removeRecip(idx)} className="recip-remove-icon" />
        </div>
      );
    });

    this.state.friends.map((f, idx) => {
      _friends.push(
        <a
          key={idx}
          onClick={() => this.handleSelect(f)}
          className="recip-item"
          style={{ backgroundImage: "url(" + f.profile_photo + ")" }}
        >
          {f.first_name} {f.last_name}
        </a>
      );
    });
    return (
      <div>
      <div className="recip-panel">
        <label>To:</label>
        <input
          type="text"
          className="recip-input"
          placeholder="Type a friends name or email"
          onKeyUp={this.handleSearch.bind(this)}
        />
      </div>
        {this.state.selectedFriends.length == 0 && 
        <p className="recip-instructions">
          Please select the friends you would like to send this message to.
        </p>}
      <div className="new-recip-panel">
        <div className="recip-results">{_friends}</div>
        {_selectedFriends}
      </div>
      </div>
    );
  }

  removeRecip(idx) {
    var _selectedFriends = this.state.selectedFriends;
    _selectedFriends.splice(idx, 1);
    this.setState({ selectedFriends: _selectedFriends, friends: [] });
    this.props.onSetRecipients(this.state.selectedFriends);
  }

  handleSelect(friend) {
    var indx = 0;
    var _selectedFriends = this.state.selectedFriends;
    _selectedFriends.map((f) => {
      if (friend.id == f.id) {
        indx = 1;
        return;
      }
    });
    if (indx > 0) {
      this.setState({ friends: [] });
      return;
    }
    _selectedFriends.push(friend);
    document.querySelector(".recip-input").value = "";
    document.querySelector(".recip-input").focus();
    // document.querySelector(".message-list").style.zIndex = 1;
    this.setState({ selectedFriends: _selectedFriends, friends: [] });
    this.props.onSetRecipients(this.state.selectedFriends);
  }

  handleSearch(e) {
    var val = e.target.value;
    if (val.length > 2) {
      var api = new ApiRequest("get", "/user/search-friends?search=" + val);
      api.send({}, (response) => {
        this.setState({ friends: response.data.users.data });
      });
    } else {
      this.setState({ friends: [] });
    }
  }
  componentDidMount() {
    if (this.props.initialRecipients) {
      var api = new ApiRequest("get", "/users/" + this.props.initialRecipients);
      api.send({}, (results) => {
        this.setState({
          selectedFriends: [{ user: results.data.data.user }],
        });
      });
    }
    // document.querySelector(".message-list").style.zIndex = -1;
  }
}

export default MessageRecipients;
