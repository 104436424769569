import React, { Component } from "react";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import ApiRequest from "../../libs/request";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = { faqtext: "", user: {} };
  }

  render() {
    return (
      <React.Fragment>
        <Nav user={this.state.user} />
        <div className="feed">
          <div className="feed-container">
            <div className="row" id="invite-form">
              <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
                <FeedNav user={this.props.user} />
              </div>
              <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
                <div
                  dangerouslySetInnerHTML={this.createMarkup(
                    this.state.faqtext
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  createMarkup(data) {
    return { __html: data };
  }

  componentDidMount() {
    var api = new ApiRequest("get", "/user/view-profile");
    api.send({}, (response) => {
      this.setState({ user: response.data.user });
    });
    api.url = "https://api.gorillafilmapp.com/faq.html";
    api.get((response) => {
      this.setState({ faqtext: response.data });
    });
  }
}

export default Faq;
