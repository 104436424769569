import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      results: [],
      selected_items: [],
      selected_objects: [],
      selected_names: [],
      selected_occupation: null,
      selectedTypes: this.props.selectedTypes,
    };
    this.removeCompanyType = this.removeCompanyType.bind(this);
  }

  expandItems(id) {
    var _child = document.getElementById("children_" + id);
    var _arrow = document.getElementById("arrow" + id);
    if (_child.style.display == "none") {
      _child.style.display = "block";
      _arrow.style.transform = "rotate(90deg)";
    } else {
      _child.style.display = "none";
      _arrow.style.transform = "none";
    }
  }

  occupationItems() {
    const items = this.state.results.map((item) => {
      const childrens = item.occupation.map((c, idx) => {
        console.log(this.state.selected_items);
        let checkedCss =
          this.state.selected_items.indexOf(c.id) > -1 ||
          (c.occupation_id &&
            this.state.selected_items.indexOf(c.occupation_id) > -1)
            ? "checkbox-on"
            : "checkbox-off";
        let selectedCss = "round";
        return (
          <div className={selectedCss} key={idx}>
            <a href="#" onClick={() => this.handleClick(c.id)}>
              {c.title}
            </a>
            <input type="checkbox" id={c.id} />
            <label
              id={"label_" + c.id}
              htmlFor={c.id}
              className={checkedCss}
              occupation_id={c.id}
              onClick={() => this.handleClick(c.title, c.id)}
            />
          </div>
        );
      });

      return (
        <div className="root" key={item.id}>
          <div
            title={item.category}
            className="title"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              cursor: "pointer",
              fontSize: "18px",
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
            onClick={() => this.expandItems(item.id)}
          >
            <div
              style={{
                float: "left",
                width: "93%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.category}
            </div>
            <div
              id={"arrow" + item.id}
              style={{ color: "#ccc", float: "right" }}
            >
              &gt;
            </div>
          </div>
          <div id={"children_" + item.id} style={{ display: "none" }}>
            {childrens}
          </div>{" "}
          <div className="root-last"></div>
        </div>
      );
    });
    return items;
  }

  listItems() {
    const items = this.state.results.map((item, idx) => {
      let checkedCss =
        this.state.selected_items.indexOf(item.id) > -1
          ? "checkbox-on"
          : "checkbox-off";
      let selectedCss = "round";
      return (
        <div className="root" key={item.id}> 
          <div
            className={selectedCss}
            style={{
              fontSize: "13px",
              lineHeight: "26px",
              height: "56px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
            }}
          >
            <a
              href="#"
              onClick={() => this.handleClick(item.title, item.id, true)}
            >
              {item.title}
            </a>
            <input type="checkbox" id={item.id} />
            <label
              id={"label_" + item.id}
              htmlFor={item.id}
              className={checkedCss}
              occupation_id={item.id}
              onClick={() => this.handleClick(item.title, item.id)}
            />
          </div>
        </div>
      );
    });

    return items;
  }

  handleClick(name, occupation_id, showFormOnly = false) {
    var items = this.state.selected_items;
    var _names = this.state.selected_names;
    var _objs = this.state.selected_objects;
    if (items.indexOf(occupation_id) === -1) {
      _names.push(name);
      items.push(occupation_id);
      _objs.push({ occupation_id: occupation_id, title: name });
    } else {
      var index = items.indexOf(occupation_id);
      if (index !== -1) items.splice(index, 1);
      index = _names.indexOf(name);
      if (index !== -1) _names.splice(index, 1);
    }
    this.setState({
      selected_names: _names,
      selected_items: items,
      selected_occupation: occupation_id,
      selected_objects: _objs,
    });
    //this.handleDone();
    if (this.props.onOccupationSelected) {
      this.props.onOccupationSelected(_objs);
    }
    this.props.onCompanyTypesSelected(items);
  }

  render() {
    var _itemsCss = this.state.results.length > 0 ? "block" : "none";
    var _names = [];
    this.state.selected_names.map((n, idx) => {
      _names.push(
        <div
          key={idx}
          onClick={() => this.removeCompanyType(idx)}
          className="ct-bubble"
        >
          {n}
        </div>
      );
    });

    var _button = "block";
    if (this.props.hideButton) {
      _button = "none";
    }

    return (
      <React.Fragment>
        <div className="ctype-bubbles">{_names}</div>
        <input
          id={"type-search" + this.props.id}
          className="form-control nooutline"
          placeholder={this.props.placeholder || "Search Types"}
          style={{ lineHeight: "42px", backgroundColor: "#fff" }}
          // onKeyUp={this.handleTypeSearch.bind(this)}
          onClick={this.handleTypeSearch.bind(this)}
          readOnly={true}
        />
        <div
          className="occupations"
          id={"m-select-" + this.props.id}
          style={{ display: _itemsCss, padding: "0px", position: "relative" }}
          onMouseLeave={this.handleMouseOut.bind(this)}
          onMouseEnter={this.handleMouseOver.bind(this)}
        >
          <div
            id={"occupations-list-" + this.props.id}
            className="autosearch occupations-list"
            style={{
              textAlign: "left",
              position: "absolute",
              zIndex: 9,
              border: "1px solid #ccc",
            }}
          >
            <div
              id={"results-" + this.props.id}
              className="results"
              style={{ padding: "12px", width: "100%", height: "240px" }}
            >
              {this.props.question_id
                ? this.occupationItems()
                : this.listItems()}
            </div>
            <br />
            <button
              style={{ display: _button }}
              className="g-button"
              type="button"
              onClick={this.handleDone.bind(this)}
            >
              Done
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleMouseOver() {
    clearTimeout(this.timer);
  }
  handleMouseOut() {
    this.timer = setTimeout(() => {
      this.handleDone();
    }, 800);
  }

  handleDone() {
    if (document.getElementById("m-select-" + this.props.id)) {
      document.getElementById("m-select-" + this.props.id).style.display =
        "none";
      document.getElementById("type-search" + this.props.id).value = "";
    }
  }

  removeCompanyType(index) {
    var items = this.state.selected_items;
    var _names = this.state.selected_names;
    if (index !== -1) items.splice(index, 1);
    if (index !== -1) _names.splice(index, 1);
    this.setState({ selected_names: _names, selected_items: items });
    this.props.onCompanyTypesSelected(items);
  }

  handleTypeSearch() {
    var typeField = document.getElementById("type-search" + this.props.id);
    if (
      localStorage.getItem("active-select") != "m-select-" + this.props.id &&
      document.getElementById(localStorage.getItem("active-select"))
    ) {
      document.getElementById(
        localStorage.getItem("active-select")
      ).style.display = "none";
      localStorage.removeItem("m-select-" + this.props.id);
    }

    document.getElementById("m-select-" + this.props.id).style.display =
      "block";

    localStorage.setItem("active-select", "m-select-" + this.props.id);
    if (this.props.occupations) {
      this.fetchOccupations();
      return;
    }

    if (this.props.question_id) {
      this.fetchCollapsed(typeField);
    } else {
      this.fetchList(typeField);
    }
  }

  fetchOccupations() {
    let api = new ApiRequest("get", "/user/get-occupation");
    api.send({}, (results) => {
      this.setState({ results: results.data.occupation });
      document.querySelector("body").addEventListener("click", (e) => {
        if (e.target.className.indexOf("col-") > -1) {
          this.handleDone();
        }
      });
    });
  }

  fetchList(typeField) {
    var api = new ApiRequest(
      "get",
      "/posts/options_search?post_type=3&id=" +
        this.props.id +
        "&search=" +
        typeField.value
    );
    api.send({ search: typeField.value }, (results) => {
      this.setState({ results: results.data.data });
      document.querySelector("body").addEventListener("click", (e) => {
        if (e.target.className.indexOf("col-") > -1) {
          this.handleDone();
        }
      });
    });
  }

  fetchCollapsed(typeField) {
    var api = new ApiRequest(
      "get",
      "/posts/new?post_type=" +
        this.props.post_type +
        "&search=" +
        typeField.value
    );
    api.send({ search: typeField.value }, (results) => {
      var options = results.data.data.questions;
      var list = [];
      options.map((o) => {
        if (o.question_id === this.props.question_id) {
          list = o.options[0].children;
        }
      });

      this.setState({ results: list });
      document.querySelector("body").addEventListener("click", (e) => {
        if (e.target.className.indexOf("col-") > -1) {
          this.handleDone();
        }
      });
    });
  }

  componentDidMount() {
    var _ids = [];
    var _names = [];

    if (this.props.selectedIds && this.props.selectedIds.length > 0) {
      var qs = "";
      this.props.selectedIds.map((id) => {
        qs += "&option_id[]=" + id;
      });
      var api = new ApiRequest("get", "/posts/options_search?1=1" + qs);
      api.send({}, (results) => {
        results.data.data.map((r) => {
          if (r.id) {
            _ids.push(r.id);
            _names.push(r.name);
          }
        });
        this.setState({ selected_items: _ids, selected_names: _names });
      });
      return;
    }
    if (this.props.selectedTypes && this.props.selectedTypes.length > 0) {
      console.log("selectedTypes",this.props.selectedTypes);
      this.props.selectedTypes.map((t) => {
        if (t.occupation_id) {
          _ids.push(t.occupation_id);
        } else {
          _ids.push(t.id);
        }
        if (t.name) {
          _names.push(t.name);
        }
      });

      this.setState({ selected_items: _ids, selected_names: _names });
    }
  }
}

export default MultiSelect;
