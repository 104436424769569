import React, { useState } from "react";
import Modal from "react-modal";
import ApiRequest from "../../libs/request.js";
import { FaCommentDots, FaTimes } from "react-icons/fa";

const customModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const MessagePost = ({ post }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const onSubmit = () => {
    new ApiRequest("post", `/messages`).send(
      {
        body: message,
        user_ids: [post.user.id],
      },
      ({ data }) => {
        new ApiRequest("get", `/messages`).send(
          { per_page: 1 },
          ({ data }) => {
            window.location.href = `/messages?thread=${data.data.results.inbox[0].message_id}`;
          }
        );
      }
    );
    closeModal(); // Close the modal after submitting
  };

  return (
    <>
      {/* <FaCommentDots
        className="comment-icon"
        title="Message to Author"
        onClick={openModal}
      /> */}
          <i
      className="fas fa-comment-dots"
      title="Message to Author"
      onClick={openModal}
    />

      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Message Modal"
        ariaHideApp={false}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h6>Send Message</h6>
          <FaTimes onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
        <div>
          <textarea
            className="form-control"
            style={{ width: "100%", border: "1px solid #99999961", height: "100px" }}
            placeholder="Type your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <button className="confirmation-modal-cancel" onClick={closeModal}>
            Cancel
          </button>
          <button className="confirmation-modal-submit" onClick={onSubmit}>
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
};

export default MessagePost;
