import React, { Component } from "react";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import ApiRequest from "../../libs/request";
import MultiSelect from "./MultiSelect";
import ReactSelect from "react-select";

class SeekingScreenPlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      film_genere: [], errors: [], minimum: 0, maximum: 0,
      genres: [],
      selectedGenres: [],
      screen_play: {},
    };
  }

  componentDidMount() {
    let api = new ApiRequest("get", "/all-genres-screaning");
    api.send({}, (response) => {
      const options = response.data.genres.map((genre) => {
        return { value: genre.id, label: genre.category }
      });
      this.setState({
        genres: options
      });
    });

  }

  handleSearch() {
    var _state = this.state;
    var _errors = [];

    // if (_state.minimum.length < 1 || _state.maximum.length < 1) {
    //   _errors.push("Page count must be a number");
    // }

    // this.setState({ errors: _errors });
    // if (_errors.length > 0) {
    //   return;
    // }

    // var search_params = {};
    // search_params["answers"] = [];
    // if (_state.film_genere.length > 0) {
    //   search_params["answers"].push({
    //     question_id: 665,
    //     answer: _state.film_genere,
    //   });
    // }
    // if (_state.film_genere.maximum > 0) {
    //   search_params["answers"].push({
    //     question_id: 1500,
    //     answer: { less_than: _state.maximum, greater_than: _state.minimum },
    //   });
    // }

    // search_params["post_type"] = [5];
    // localStorage.setItem("seeking_search", JSON.stringify(search_params));
    // localStorage.setItem("seeking_type", "screen plays");

    // window.location.href = "/?seeking=1";
    window.location.href = "/";
  }

  render() {
    var _showErrors = this.state.errors.length > 0 ? "block" : "none";
    var _errorStr = this.state.errors.join(" , ");
    const { screen_play } = this.state;

    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
              <form className="seeking container">
                <h4>Seeking Screenplays</h4>
                <div
                  className="alert alert-danger"
                  style={{ display: _showErrors }}
                >
                  {_errorStr}
                </div>
                <div className="row" style={{ marginBottom: "12px" }}>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Film Genre</label>
                      {/* <MultiSelect
                        id={280}
                        hideButton={true}
                        question_id={5}
                        post_type={5}
                        placeholder="Select Genre"
                        selectedTypes={this.state.film_genere}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ film_genere: items })
                        }
                      /> */}
                      <div className="react-select-styled">
                        <ReactSelect options={this.state.genres} isMulti
                          defaultValue={screen_play.genre_categories ?
                            screen_play.genre_categories.map((genre) => {
                              return { value: genre.id, label: genre.category }
                            })
                            :
                            this.state.film_genere}
                          id="film_genere"
                          onChange={(e) => this.setState({ film_genere: e })}
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Minimum Page Count</label>
                      <input
                        type="number"
                        onChange={(e) =>
                          this.setState({ minimum: e.target.value })
                        }
                        className="form-control"
                        defaultValue={0}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Maximum Page Count</label>
                      <input
                        type="number"
                        onChange={(e) =>
                          this.setState({ maximum: e.target.value })
                        }
                        className="form-control"
                        defaultValue={0}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={this.handleSearch.bind(this)}
                        className="g-button full"
                      >
                        Seek Screenplays
                      </button>
                      This search will list out all screenplays that match your
                      search criterea.
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SeekingScreenPlay;
