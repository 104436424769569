import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { isset } from "../../libs/functions";
import ScreenPlayForm from "../projects/ScreenPlayForm";
import { validFile } from "../../libs/functions";

class EditScreenPlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: {},
      questions: [],
      post_type: 5,
      id: this.props.post_id,
    };
  }

  render() {
    if (!this.state.questions) {
      return <div />;
    }
    return (
      <React.Fragment>
        <div className="container-fluid header">
          <div className="logo"></div>
          <a
            href="/profile/0/workhistory"
            style={{ marginLeft: "-696px", color: "white" }}
          >
            &lt; Back
          </a>
        </div>
        <div className="row justify-content-center projects"></div>
        <ScreenPlayForm
          post={this.state.post}
          screenplayID = {this.props.match.params.id}
          onAfterSave={() => (window.location.href = "/profile/0/workhistory")}
          onCancel={() => (window.location.href = "/profile/0/workhistory")}
          user={this.props.user}
        />
      </React.Fragment>
    );
  }

  validate(fields) {
    document.getElementById("errors-panel").style.display = "none";
    var errors = [];
    fields.forEach((f) => {
      if (f.dataset["required"] == "true" && f.value.length < 1) {
        errors.push(f.dataset["name"] + " is required");
      }

      if (
        f.dataset["keyboard_type"] &&
        f.dataset["keyboard_type"] == "number" &&
        f.dataset["field_type"] != "phone" &&
        f.value.length > 1 &&
        isNaN(f.value)
      ) {
        errors.push(f.dataset["name"] + " must be a number");
      }

      if (
        f.dataset["field_type"] == "phone" &&
        f.value.length > 1 &&
        !this.phonenumber(f.value)
      ) {
        errors.push(f.dataset["name"] + " is not a valid phone number");
      }
      if (
        f.dataset["field_type"] == "email" &&
        f.value.length > 1 &&
        !this.validateEmail(f.value)
      ) {
        errors.push(f.dataset["name"] + " is not a valid email");
      }
      if (
        f.dataset["field_type"] == "date" &&
        f.value.length > 1 &&
        !Object.prototype.toString.call(f.value) === "[object Date]"
      ) {
        errors.push(f.dataset["name"] + " is not a valid date");
      }
    });
    if (errors.length > 0) {
      document.getElementById("errors-panel").innerHTML = errors.join("<br/>");
      document.getElementById("errors-panel").style.display = "block";
      window.scrollTo(0, 0);
    }
    return errors;
  }

  phonenumber(val) {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return val.match(phoneno);
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  handleSubmit() {
    const id = this.state.id ? this.state.id : this.props.match.params.id;
    var _required = [];
    var fields = document.querySelectorAll(".form-control");
    var answers = [];
    var params = {};
    var _errors = this.validate(fields);
    if (document.getElementById("title").value == "") {
      _errors.push("Title is required");
    }
    this.setState({ form_errors: _errors });
    if (_errors.length > 0) {
      return;
    }

    fields.forEach((f) => {
      if (!params["body"]) {
        params["body"] = f.value;
      }
      var _txtAns = f.options ? f.options[f.selectedIndex].text : f.value;
      answers.push({
        question_id: parseInt(f.dataset.id),
        answer: f.value,
        textAnswer: _txtAns,
        question: f.dataset.question,
      });
    });
    params["answers"] = { answers: answers };
    params["post_type"] = this.state.post_type;
    params["public"] = true;
    params["meta_data"] = { answers: answers };
    var api = new ApiRequest("post", "/posts");
    if (id) {
      api.method = "put";
      api.url = "/posts/" + id;
    }
    api.send({ post: params }, (result) => {
      window.location.href = "/profile/0/workhistory";
    });
  }



  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleFileClick() {
    document.getElementById("image-field").click();
  }

  handleFileUpload() {
    var post_type_id = 5;
    const id =
      this.props && this.props.match.params && this.props.match.params.id
        ? this.props.match.params.id
        : null;
    var formData = new FormData();
    var imagefile = document.querySelector("#image-field");
    if (!validFile(imagefile, "photo")) {
      return;
    }
    formData.append("post[assets][][data]", imagefile.files[0]);
    formData.append("post[assets][][type]", "photo");
    formData.append("post[post_type]", post_type_id);
    formData.append("post[body]", "");
    formData.append(
      "post[meta_data][title]",
      document.querySelector("#title").value
    );
    var api = isset(id)
      ? new ApiRequest("put", "/posts/" + this.state.id + ".json")
      : new ApiRequest("post", "/posts.json");
    api.send_file(formData, (response) => {
      this.setState({ id: response.data.data.post.id });
      var idField = document.querySelector("#post_id");
      idField.value = response.data.data.post_id;
      var headerPanel = document.querySelector("#project-asset-view");
      if (response.data.data.post.photos.length > 0) {
        headerPanel.style.backgroundImage =
          "url('" + response.data.data.post.photos[0].url + "')";
        document.querySelector(".project-camera").style.display = "none";
      }
    });
  }
}

export default EditScreenPlay;
