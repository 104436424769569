import React, { Component } from "react";
import ReactTimeAgo from "react-time-ago";
import ApiRequest from "../../libs/request";
import RecipientList from "./RecipientList";
import { FaPlusCircle } from "react-icons/fa";

class MessagesPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { messages: [], groups: [], current_page: 1, isLoading: false, loadMore: true, message_type: "all", loadingGroups: false, groupsList: [] };
  }

  render() {
    let _messages = [];
    this.state.messages.filter((x) => {
      return x.chat.type == "private";
    }).map((message, idx) => {
      let _recipients = message.chat.participants;
      var _currentThreadUser = message.chat.final_message.user;
      _recipients.map((r) => {
        console.log("r", r);
        console.log("this.props.user", this.props.user);
        if (r.user.id != this.props.user.id) {
          _currentThreadUser = r;
        }
      });

      var _currentThread = {};
      let _id =
        message.chat.final_message.chat_id != null
          ? message.chat.final_message.chat_id
          : message.chat.final_message.id;
      if (this.props.currentThread == _id) {
        _currentThread = { backgroundColor: "#ff8d12" };
      }

      _messages.push(
        <div className="message" key={idx} style={_currentThread}>
          <div
            className="profile_photo"
            style={{
              backgroundImage: "url(" + _currentThreadUser.user.profile_photo + ")",
            }}
          />
          <div className="info" onClick={() => this.handleMessageClick(_id)}>
            <div className="name">{_currentThreadUser.user.full_name}</div>
            <div className="body">{message.chat.final_message.message} </div>
          </div>
          <div className="created">
            <div className="ago"><ReactTimeAgo date={new Date(message.chat.final_message.created_at)} locale="en-US" timeStyle="round" /></div>
            <div className="recipients">
              <RecipientList
                recipients={message.chat.participants}
              />
            </div>
          </div>
        </div>
      );
    });

    let _groups = [];
   
    this.state.messages.filter((x) => {
      return x.chat.type == "group";
    }).map((message, idx) => {
      let _recipients = message.chat.participants;
      var _currentThreadUser = message.chat.final_message.user;
      _recipients.map((r) => {
        console.log("r", r);
        console.log("this.props.user", this.props.user);
        if (r.user.id != this.props.user.id) {
          _currentThreadUser = r;
        }
      });

      var _currentThread = {};
      let _id =
        message.chat.final_message.chat_id != null
          ? message.chat.final_message.chat_id
          : message.chat.final_message.id;
      if (this.props.currentThread == _id) {
        _currentThread = { backgroundColor: "#ff8d12" };
      }

      _groups.push(
        <div className="message" key={idx} style={_currentThread}>
          <div
            className="profile_photo"
            style={{
              backgroundImage: "url(" + _currentThreadUser.user.profile_photo + ")",
            }}
          />
          <div className="info" onClick={() => this.handleMessageClick(_id)}>
            <div className="name">{_currentThreadUser.user.full_name}</div>
            <div className="body">{message.chat.final_message.message} </div>
          </div>
          <div className="created">
            <div className="ago"><ReactTimeAgo date={new Date(message.chat.final_message.created_at)} locale="en-US" timeStyle="round" /></div>
            <div className="recipients">
              <RecipientList
                recipients={message.chat.participants}
              />
            </div>
          </div>
        </div>
      );
    
    });



    var _groupToggle = ["on", "off"];
    if (this.state.groups.length > 0) {
      _groupToggle = ["off", "on"];
    }

    return (
      <div className="messages-list">
        <h2>Messsages
         {!this.props.nav && 
         <span>
          <a href="/messages" style={{color: "#ff5e3a"}} title="Create C">
            <FaPlusCircle style={{
              marginLeft: "10px",
              fontSize: "16px",
            }} 
            
            />
            </a>
          </span>}
        </h2>
        {
          this.props.nav ?

            <a href="/messages" className="popup-new-chat">
              <p> + New Chat</p>
            </a>
            : <div className="toggle">
              <div onClick={this.loadAll.bind(this)} className={_groupToggle[0]}>
                All
              </div>
              <div
                onClick={this.loadGroups.bind(this)}
                className={_groupToggle[1]}
              >
                Groups
              </div>
            </div>
        }


        {!this.state.loadingGroups && <div className="items">
          {_messages}
        </div>}
        {this.state.loadingGroups && (
          this.state.messages.filter((x) => {
            return x.chat.type == "group";
          }).length > 0 ? <div className="items">
            {_groups}
          </div> : <div>
            <div className="no-group">No Group Found!</div>
          </div>)
        }


        {!this.state.isLoading && this.state.loadMore && (
          <div className="load-more" onClick={this.onLoadMore.bind(this)}>
            Load more messages
          </div>
        )}
      </div>
    );
  }

  onLoadMore() {
    this.setState(
      (prevState) => {
        return { current_page: prevState.current_page + 1 };
      },
      () => this.typeSelected()
    );
  }

  typeSelected() {
    console.log(this.state.message_type);

    if (this.state.message_type == "all")
      this.fetchAll();

    if (this.state.message_type == "group")
      this.fetchGroups();
  }

  loadAll() {
    this.setState({ current_page: 1, message_type: "all" });

    this.fetchAll();
  }

  loadGroups() {
    this.setState({ current_page: 1, message_type: "group" });

    this.fetchGroups();
  }

  fetchAll() {
    this.setState({ isLoading: true, loadingGroups: false, messages: [] });

    var api = new ApiRequest(
      "get",
      // "/messages?per=10&page=" + this.state.current_page
      "/user/chats"
    );
    api.send({}, (response) => {
      this.setState((prevState) => {
        return {
          messages: [...prevState.messages, ...response.data.chats],
          groups: [],
          isLoading: false,
          // loadMore: response.data.data.results.inbox.length > 0,
          loadMore: false,
        }
      });
    });
  }

  fetchGroups() {
    this.setState({ isLoading: true, loadingGroups: true });

    var api = new ApiRequest(
      "get",
      // "/messages?per=10&type=group&page=" + this.state.current_page
      "/user/my-groups"
    );
    api.send({}, (response) => {
      this.setState((prevState) => {
        return {
          groups: [1],
          // messages: [...prevState.messages, ...response.data.data.results.inbox],
          isLoading: false,
          // loadMore: response.data.data.results.inbox.length > 0,
        }
      });
    });
  }

  handleMessageClick(id) {
    location.href = "/messages?thread=" + id;
  }

  componentDidMount() {
    if (this.props.isGroup) {
      this.fetchGroups();
    } else {
      this.fetchAll();
    }
  }
}

export default MessagesPanel;
