import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ResetPassword from "./components/ResetPassword";
import Oauth from "./components/Oauth";
import Privacy from "./components/main/Privacy";
import TermsAndConditions from "./components/main/TermsConditions";
import UpdatePassword from "./components/UpdatePassword";
// import TopSlidingText from "./components/shared/top-sliding-text/TopSlidingText";

class Public extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>

          {/* <TopSlidingText route="public" /> */}
          <Route path="/" component={Home} exact />
          <Route path="/login" component={Login} exact />
          <Route path="/signup" component={SignUp} exact />
          <Route path="/reset" component={ResetPassword} exact />
          <Route path="/update-password" component={UpdatePassword} exact />
          <Route path="/auth" component={Oauth} exact />
          <Route path="/privacy" component={Privacy} exact />
          <Route path="/terms-conditions" component={TermsAndConditions} exact />
        </div>
      </BrowserRouter>
    );
  }
}

export default Public;
