import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import "./FilmMarket.css";
import Nav from "../main/Nav.js";
import FeedNav from "../main/FeedNav.js";

class FilmMarket extends Component {
    constructor(props) {
        super(props);
        this.state = { films: [], selectedCard: null };
        this.loadFilms();
    }

    render() {
        return (
            <React.Fragment>
                <Nav user={this.props.user} />
                <div className="feed">
                    <div className="row feed-container">
                        <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
                            <FeedNav user={this.props.user} />
                        </div>
                        <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
                            <div className="row justify-content-center films">
                                <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
                                    <div className="hdr">
                                        <h2>Film Market</h2>
                                    </div>
                                    <div className="row" style={{marginBottom: "16px"}}>
                                        <div className="col-md-6">
                                            <div
                                                className={`card ${this.state.selectedCard === "buyer" ? "selected" : ""}`}
                                                onClick={() => this.handleCardClick("buyer")}
                                            >
                                                <div className="card-body">
                                                    <h5 className="card-title">Buyer</h5>
                                                    <p className="card-text">I am looking for films to buy</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div
                                                className={`card ${this.state.selectedCard === "seller" ? "selected" : ""}`}
                                                onClick={() => this.handleCardClick("seller")}
                                            >
                                                <div className="card-body">
                                                    <h5 className="card-title">Seller</h5>
                                                    <p className="card-text">I am looking to sell films</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn g-button"
                                        // onClick={this.handleContinue.bind(this)}
                                        disabled={!this.state.selectedCard}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    loadFilms() {
        let api = new ApiRequest("get", "/user/all-movies");
        api.send({}, (response, status) => {
            if (status != undefined && status != 200) {
                return;
            }
            this.setState({ films: response.data.movies });
        });
    }

    handleDelete(id) {
        let api = new ApiRequest("delete", "/posts/" + id);
        api.send({}, (response, status) => {
            if (status != undefined && status != 200) {
                return;
            }
            this.loadFilms();
        });
    }

    handleContinue() {
        location.href = "/projects";
    }

    handleCardClick(cardType) {
        this.setState({ selectedCard: cardType });
    }
}

export default FilmMarket;
