import React, { Component } from "react";
import ApiRequest from "../../libs/request";

class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = { reviews: [] };
  }

  render() {
    if (this.state.reviews.length < 1) {
      return <div>There are no reviews yet</div>;
    }
    var _reivews = [];
    this.state.reviews.map((review, idx) => {
      var _ruser = review.from_user.user;
      _reivews.push(
        <li>
          <div
            className="profile_photo"
            style={{ backgroundImage: 'url("' + _ruser.profile_photo + '")' }}
          />
          <div className="name">
            {_ruser.first_name + " " + _ruser.last_name}
          </div>
          <div className="review">{review.comment}</div>
        </li>
      );
    });
    return <ul className="reviewList">{_reivews}</ul>;
  }

  componentDidMount() {
    var api = new ApiRequest(
      "get",
      "/users/" + this.props.user.id + "/ratings"
    );
    api.send({}, (response) => {
      this.setState({ reviews: response.data.data.results });
    });
  }
}

export default Reviews;
