import React, { Component } from "react";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import ApiRequest from "../../libs/request";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { params } from "../../libs/functions";
import ReactSelect from "react-select";
import GooglePlacesAutoComplete from "../shared/location/location";

class SeekingCrew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crew_types: [],
      location: {},
      startDate: null,
      endDate: null,
      range: 0,
      errors: [],
      location_name: "",
      desc: "",
      loaded: false,
      edit_post: null,
      genres: [],
      selectedGenres: []
    };
  }
  componentDidMount() {
    if (params("id")) {
      var api = new ApiRequest("get", "/posts/" + params("id"));
      api.send({}, (results) => {
        var _post = results.data.data.post;
        var _data = { desc: _post.body };
        var dt;
        _post.meta_data["answers"].map((m) => {
          if (m["question_id"] == 715) {
            _data["crew_types"] = m["answer"];
          }
          if (m["question_id"] == 757) {
            dt = new Date(m["answer"]);
            _data["startDate"] = dt;
          }
          if (m["question_id"] == 758 && _data["startDate"]) {
            dt = new Date(m["answer"]);
            _data["endDate"] = dt;
          }
          if (m["question_id"] == 754) {
            _data["desc"] = m["answer"];
          }
          if (m["question_id"] == 756) {
            _data["location_name"] = m["answer"];
          }
        });
        _data["loaded"] = true;
        _data["edit_post"] = _post;
        this.setState(_data);
      });
    } else {
      this.setState({ loaded: true });
    }

    let genreApi = new ApiRequest("get", "/user/crew-types");
    genreApi.send({}, (response) => {
      const options = response.data.types.map((primaryType) => {
        const primaryOption = {
          label: primaryType.name,
          options: primaryType.secondary.map((secondaryType) => ({
            value: secondaryType.id,
            label: secondaryType.name,
          })),
        };
        return primaryOption;
      });

      this.setState({ genres: options });
    });
  }

  parseParams() {
    var _state = this.state;
    var _errors = [];
    if (_state.crew_types.length < 1) {
      _errors.push("Please select at least one Crew Type");
    }
    if (_state.location.length == 0) {
      _errors.push("Please select a location");
    }

    this.setState({ errors: _errors });
    if (_errors.length > 0) {
      return;
    }

    var search_params = {};
    search_params["answers"] = [
      { question_id: 715, answer: _state.crew_types },
    ];
    if (_state.location.length > -0) {
      search_params["near"] = {
        coordinates: _state.location,
        radius: _state.range,
      };
    }
    // if(_state.startDate && _state.endDate) {
    //   search_params["answers"].push(
    //     {
    //   )
    // }
    return search_params;
  }

  handleSearch() {
    var search_params = this.parseParams();
    console.log("search_params", search_params);
    // search_params["post_type"] = [3];
    // localStorage.setItem("seeking_search", JSON.stringify(search_params));
    // localStorage.setItem("seeking_type", "crew");
    // window.location.href = "/?seeking=1";
    window.location.href = "/";
  }

  handlePost() {

    var api = new ApiRequest("post", "/user/add-crew");
    var params = {
      description: this.state.desc,
      radius: this.state.range,
      hiring_from: this.state.startDate ? this.state.startDate.toISOString().split("T")[0] : "",
      hiring_to: this.state.endDate ? this.state.endDate.toISOString().split("T")[0] : "",
      crew_type: this.state.selectedGenres.map((genre) => genre.value),
      location: this.state.location_name,
      latitude: "0",
      longitude: "0",
    };
    console.log("params", params);
    if (this.state.edit_post) {
      api.method = "put";
      api.url = "/posts/" + this.state.edit_post.id;
    }
    api.send( params , (resp) => {
      console.log("resp", resp);
      // window.location.href = "/?id=" + resp.data.data.post.id;
    });
  }

  selectedLocation(loc) {
    console.log("loc", loc)
    this.setState({ location_name: loc.label });
  }

  render() {
    if (!this.state.loaded) {
      return <div />;
    }
    var _showErrors = this.state.errors.length > 0 ? "block" : "none";
    var _errorStr = this.state.errors.join(" , ");
    var _descFields = "";
    var _pageTitle = "Seeking Crew";
    var _submitBtn = (
      <div className="form-group">
        <button
          type="button"
          onClick={this.handleSearch.bind(this)}
          className="g-button full"
        >
          Seek Available Crew
        </button>
        This search will list out all available crew but not notify them.
      </div>
    );
    if (location.search.indexOf("new=1") > -1) {
      _pageTitle = "Create Crew";
      _submitBtn = (
        <div className="form-group">
          <button
            type="button"
            onClick={this.handlePost.bind(this)}
            className="g-button full"
          >
            Post to Newsfeed
          </button>
          This post will notify all available crew matching your search critera
        </div>
      );
      _descFields = (
        <div className="row" style={{ marginBottom: "12px" }}>
          <div className="col-12">
            <label>Description</label>
            <textarea
              className="form-control"
              placeholder="Type something"
              defaultValue={this.state.desc}
              onChange={(e) => this.setState({ desc: e.target.value })}
            ></textarea>
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
              <form className="seeking container">
                <div style={{display: "flex"}}>
                  <h4 style={{width: "250px"}}>{_pageTitle}</h4>
                  {!(location.search.indexOf("new=1") > -1) && 
                  <a href={"/seeking/crew?new=1"} style={{textAlign: "right", width: "100%"}} className="add-new-sub-tab">
                    <i className="fas fa-plus-circle"></i> Add Crew
                  </a>}
                </div>
                <div
                  className="alert alert-danger"
                  style={{ display: _showErrors }}
                >
                  {_errorStr}
                </div>
                {_descFields}
                <div className="row" style={{ marginBottom: "12px" }}>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Crew Type</label>
                      <div className="react-select-styled">
                        
                        <ReactSelect
                          options={this.state.genres}
                          isMulti
                          value={this.state.selectedGenres}
                          onChange={(selectedOptions) => this.setState({ selectedGenres: selectedOptions })}
                        />
                      </div>

                      {/* <MultiSelect
                        id={715}
                        question_id={89}
                        post_type={3}
                        hideButton={true}
                        placeholder="Crew Type"
                        selectedIds={this.state.crew_types}
                        selectedTypes={this.state.crew_types}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ crew_types: items })
                        }
                      /> */}
                    </div>
                  </div>
                  <div className="col-6">
                    <label>Location</label>


                    <GooglePlacesAutoComplete selectedLocation={this.selectedLocation.bind(this)} />

                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="form-group"
                      style={{ backgroundColor: "#fff", padding: "12px" }}
                    >
                      <label>WORK RADIUS</label>
                      <input
                        type="range"
                        className="form-control"
                        min="1"
                        max="12500"
                        step="1"
                        onChange={(e) =>
                          this.setState({ range: e.target.value })
                        }
                      />
                      <span className="sliderVal">{this.state.range || 0}</span>{" "}
                      miles
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>HIRING DURATION (Start Date)</label>
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={(date) => this.setState({ startDate: date })}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>HIRING DURATION (End Date)</label>
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={(date) => this.setState({ endDate: date })}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">{_submitBtn}</div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SeekingCrew;
