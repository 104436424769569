import React, { Component } from "react";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import ApiRequest from "../../libs/request";
import MultiSelect from "./MultiSelect";
import ReactSelect from "react-select";

class SeekingInvestment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project_type: [], errors: [], minimum: 0, maximum: 0,
      selectedTypes: [],
    };
  }

  componentDidMount() {
    let api = new ApiRequest("get", "/user/project-types");
    api.send({}, (response) => {
      const options = response.data.types.map((type) => {
        return { value: type, label: type }
      });
      this.setState({
        project_type: options
      });
    });

  }

  handleSearch() {
    // var _state = this.state;
    // var _errors = [];

    // if (_state.minimum.length < 1 || _state.maximum.length < 1) {
    //   _errors.push("Page count must be a number");
    // }

    // this.setState({ errors: _errors });
    // if (_errors.length > 0) {
    //   return;
    // }

    // var search_params = {};
    // search_params["answers"] = [
    //   { question_id: 691, answer: _state.project_type },
    //   {
    //     question_id: 683,
    //     answer: { less_than: _state.maximum, greater_than: _state.minimum },
    //   },
    //   {
    //     question_id: 684,
    //     answer: { less_than: _state.maximum, greater_than: _state.minimum },
    //   },
    // ];
    // search_params["post_type"] = [2];
    // localStorage.setItem("seeking_search", JSON.stringify(search_params));
    // localStorage.setItem("seeking_type", "investments");

    // window.location.href = "/?seeking=1";
    window.location.href = "/";
  }

  render() {
    var _showErrors = this.state.errors.length > 0 ? "block" : "none";
    var _errorStr = this.state.errors.join(" , ");

    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
              <form className="seeking container">
                <h4>Seek Investment</h4>
                <div
                  className="alert alert-danger"
                  style={{ display: _showErrors }}
                >
                  {_errorStr}
                </div>
                <div className="row" style={{ marginBottom: "12px" }}>
                  <div className="col-12">
                    <div className="form-group">
                      <label>Project Type</label>
                      {/* <MultiSelect
                        id={691}
                        placeholder="Select Project Type"
                        hideButton={true}
                        selectedTypes={this.state.project_type}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ project_type: items })
                        }
                      /> */}
                      <div className="react-select-styled">
                        <ReactSelect
                          placeholder="Select Project Type"
                          defaultValue={this.state.selectedTypes}
                          onChange={(selectedTypes) => {
                            this.setState({ selectedTypes });
                          }
                          }
                          options={
                            this.state.project_type
                          } />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Minimum Amount</label>
                      <input
                        type="number"
                        onChange={(e) =>
                          this.setState({ minimum: e.target.value })
                        }
                        className="form-control"
                        defaultValue={0}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Maximum Amount</label>
                      <input
                        type="number"
                        onChange={(e) =>
                          this.setState({ maximum: e.target.value })
                        }
                        className="form-control"
                        defaultValue={0}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={this.handleSearch.bind(this)}
                        className="g-button full"
                      >
                        Seek Investment
                      </button>
                      This search will list out all available investments
                      matching your search criteria, but not notify them.
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SeekingInvestment;
