class CurrentUser {
  static id() {
    return localStorage.getItem("current_user") ? JSON.parse(localStorage.getItem("current_user")).id : null;
  }

  static first_name() {
    return localStorage.getItem("current_user") ? JSON.parse(localStorage.getItem("current_user")).first_name : null;
  }

  static last_name() { 
    return localStorage.getItem("current_user") ? JSON.parse(localStorage.getItem("current_user")).last_name : null;
  }

  static avatar() {
    return localStorage.getItem("current_user") ? JSON.parse(localStorage.getItem("current_user")).avatar : null;
  }
  

  static user() {
    return localStorage.getItem("current_user");
  }

  static token() {
    return localStorage.getItem("gorilla-token");
  }
}
export default CurrentUser;
