import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { isset, validFile } from "../../libs/functions";

class ProjectBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="row justify-content-center projects">
          <div
            className="header-panel"
            id="project-asset-view"
            onClick={this.handleFileClick.bind(this)}
          >
            <div className="centered">
              <input
                type="file"
                id="image-field"
                onChange={this.handleFileUpload.bind(this)}
              />
              <div className="project-camera"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleFileClick() {
    document.getElementById("image-field").click();
  }

  handleFileUpload() {
    
    var imagefile = document.querySelector("#image-field");
    if (!validFile(imagefile, "photo")) {
      return;
    }
  
    var reader = new FileReader();
    reader.onload = function (e) {
      var headerPanel = document.querySelector("#project-asset-view");
      headerPanel.style.backgroundImage = "url('" + e.target.result + "')";
      document.querySelector(".project-camera").style.display = "none";
    };
  
    reader.readAsDataURL(imagefile.files[0]);

    
   
    this.props.onBannerUploaded(imagefile.files[0]);
    
  }
}

export default ProjectBanner;
