import React, { Component } from "react";
import ApiRequest from "../../libs/request";

function UsersList(_props) {
  let _table = [];
  _props.users.map((user, idx) => {
    var _btnCss =
      _props.pending.indexOf(user.id.toString()) > -1 ||
      user.is_friend ||
      user.request_sent
        ? "requested"
        : "";
    var _text =
      _props.pending.indexOf(user.id.toString()) > -1 || user.request_sent
        ? "REQUESTED"
        : "ADD FRIEND";
    if (user.is_friend) {
      _text = "REMOVE";
    }
    var _button = (
      <button
        id={user.id}
        isFriend={user.is_friend}
        className={"actionbtn " + _btnCss}
        type="button"
        onClick={_props.onButtonClick}
      >
        {_text}
      </button>
    );
    if (_props.user.id === user.id) {
      _button = "";
    }
    _table.push(
      <tr key={idx} style={{ borderBottom: "1px solid #ccc" }}>
        <td style={{ paddingTop: "12px", width: "60px" }}>
          <a href={"/user/profile/" + user.id + "#profile"}>
            <img src={user.profile_photo} height="40" width="40" />
          </a>
        </td>
        <td style={{ textAlign: "left", paddingTop: "12px" }}>
          <a
            href={"/user/profile/" + user.id + "#profile"}
            style={{ color: "#000" }}
          >
            {user.first_name} {user.last_name}
          </a>
        </td>
        <td style={{ paddingTop: "16px", paddingRight: "16px" }}>{_button}</td>
      </tr>
    );
  });
  return (
    <table className="users-list" style={{ width: "100%", marginTop: "2px" }}>
      <tbody>{_table}</tbody>
    </table>
  );
}
class SearchResults extends Component {
  constructor(props) {
    super(props);
    this.state = { users: this.props.users, pending: [] };
  }

  render() {
    var _posts = [];
    // this.props.results.posts.map((post, idx) => {
    //   _posts.push(
    //     <div
    //       key={idx}
    //       className="user-search-result"
    //       onClick={() => (window.location.href = "/?id=" + post.id)}
    //     ></div>
    //   );
    // });
    return (
      <React.Fragment>
        <UsersList
          users={this.props.results}
          user={this.props.user}
          pending={this.state.pending}
          onButtonClick={this.handleVoucherClick.bind(this)}
        />
      </React.Fragment>
    );
  }

  handleVoucherClick(e) {
    let _pending = this.state.pending;
    var api = new ApiRequest("post", "/user/send-friend-request");
    if (e.target.getAttribute("isFriend") == "1") {
      api = new ApiRequest("post", "/user/delete-friend");
    }
    var id = e.target.id;
    api.send({ friend_id: id }, () => {
      _pending.push(id);
      this.setState({ pending: _pending });
    });
  }
}
export default SearchResults;
