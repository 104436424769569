import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import PersonalInfo from "./PersonalInfo";
import WorkHistory from "./WorkHistory";
import AdditionalInfo from "./AdditionalInfo";
import ProfileStats from "./ProfileStats";
import CustomModal from "../shared/CustomModal";
import { FaExpand, FaEye } from "react-icons/fa";
import { _backgroundImagePrompt } from "../../libs/constants";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "#profile",
      cover: "",
      profile_photo: "",
      tabs: [
        {
          url: "#profile",
          icon: "fa-user",
          name: "Personal Info",
        },
        {
          url: "#workhistory",
          icon: "fa-video",
          name: "Work History",
        },
        {
          url: "#info",
          icon: "fa-star",
          name: "Addtional Info",
        },
        // {
        //   url: '#resume',
        //   icon: 'fa-download',
        //   name: 'Resume'
        // }
      ],
    };
  }

  render() {
    var _tabs = [];
    this.state.tabs.map((t, idx) => {
      var _active = this.state.tab === t.url ? "active" : "";
      _tabs.push(
        <li key={idx} className={_active}>
          <a
            className={_active}
            onClick={() => {
              this.setState({ tab: t.url });
            }}
            href={"/user/profile/" + this.props.match.params.id + t.url}
          >
            <i className={"fas " + t.icon} />
            <br />
            {t.name}
          </a>
        </li>
      );
    });

    let _content = "";
    switch (this.state.tab) {
      case "#profile":
        _content = <PersonalInfo user={this.state} />;
        break;
      case "#workhistory":
        _content = <WorkHistory user={this.state} />;
        break;
      case "#info":
        _content = <AdditionalInfo user={this.state} />;
        break;
    }

    var occupation =
      this.state.user_occupations && this.state.user_occupations.length > 0 ? (
        this.state.user_occupations[0].title
      ) : (
        <p>&nbsp;</p>
      )

    var occupation_counts =
      this.state.user_occupations && this.state.user_occupations.length > 0 ? (
        this.state.user_occupations.length - 1
      ) : (0);

    var rating = this.state.rating ? this.state.rating : "N/A";
    var _show_request = true;
    if (this.props.user.id == this.props.match.params.id) {
      _show_request = false;
    }
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12">
              <div className="row justify-content-center" style={{ float: "left" }}>
                <div
                  className="col-9"
                  style={{
                    marginTop: "108px",
                    marginLeft: "30px",
                    maxWidth: "unset",
                  }}
                >
                  <div className="profile-form">
                    <div className="title"></div>
                    <div className="upload" style={{ height: "275px", position: "relative" }}>
                      <div className="image-upload">
                        <div id="backgroundPrompt"style={_backgroundImagePrompt}>Upload background image</div>
                      </div>
                      {this.state.cover &&
                        <CustomModal
                          width={"50%"}
                          trigger={<FaExpand
                            style={{
                              fontSize: "24px",
                              color: "white",
                              cursor: "pointer",
                              position: "absolute",
                              right: "10px",
                              bottom: "65px"
                            }}
                          />} content={
                            <img src={this.state.cover} style={{ width: "100%" }} />
                          } />}
                      <div className="camera-btn" style={{ float: "left" }}>
                        <a href="#" id="avatar"></a>
                      </div>
                      {
                        this.state.profile_photo &&
                        <CustomModal
                          width={"500px"}
                          trigger={<FaEye
                            style={{
                              fontSize: "18px",
                              color: "white",
                              cursor: "pointer",
                              position: "absolute",
                              bottom: "5px",
                              left: "48px",
                              // backgroundColor: "#f8f8f8",
                              width: "80px"
                            }}
                          />} content={
                            <img src={this.state.profile_photo} style={{ width: "100%" }} />
                          } />}
                      <div className="profile-id">
                        <b>
                          {this.state.first_name} {this.state.last_name}
                        </b>
                        <br />
                        <span style={{ fontSize: "16px" }}>{occupation} <span className={occupation_counts > 0 ? '' : 'hidden'}> & {occupation_counts} more</span></span>
                      </div>

                      <div className="stats" style={{ float: "left" }}></div>
                      <div
                        className="prompt"
                        style={{
                          marginRight: "0px",
                          textAlign: "right",
                          width: "255px",
                          marginTop: "9px",
                          height: "50px"
                        }}
                      >
                        <ProfileStats
                          user={this.state}
                          showRequestButton={_show_request}
                        />
                      </div>
                    </div>
                    <div className="col-12 profile-tabs" style={{ float: "left" }}>
                      <ul>{_tabs}</ul>
                    </div>
                    <div
                      className="col-12 pl-0 pr-0 pt-16 profile-fields"
                      style={{ marginTop: "18px", float: "left" }}
                    >
                      {_content}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    let api = new ApiRequest("get", "/user/user-info?user_id=" + id);
    api.send({}, (response) => {
      let result = response.data;
      var _user = result.user_info;
      _user["tab"] = this.props.location.hash;
      document.querySelector("#backgroundPrompt").style.display = "none";
      document.querySelector(".image-upload").style.backgroundImage =
        "url('" + _user.cover + "')";
      document.querySelector("#avatar").style.display = "none";
      document.querySelector(".camera-btn").style.backgroundImage =
        "url('" + _user.profile_photo + "')";

      this.setState({
        cover: _user.cover,
        profile_photo: _user.profile_photo,
      })

      this.setState(_user);
    });
  }
}
export default UserProfile;
