import React from "react";

import ApiRequest from "../../libs/request.js";

const ReportPost = ({ post }) => {
  const onClick = () => {
    // let reason = prompt("Reason");

    // if (reason) {
      var api = new ApiRequest("post", `/user/report-post`);

      api.send(
        {
          // reason: reason,
          post_id: post.id,
        },
        () => {
          // alert("Thank you");
        }
      );
    // }
  };

  return <i className="fas fa-flag" title="Report Post" onClick={onClick} />;
};

export default ReportPost;
