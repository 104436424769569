import React, { Component } from "react";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import ApiRequest from "../../libs/request";
import MultiSelect from "./MultiSelect";
import { time_convert } from "../../libs/functions";
import ReactSelect from "react-select";

class SeekingMusic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instrumentation: [],
      genre: [],
      errors: [],
      mood: [],
      style: [],
      tempo: [],
      duration: [],
      range: 0,
      minimum: 0,
      maximum: 0,
      selectedInstrumentation: [],
      production_genre: [],
      selectedProductionGenre: "",
      mood_emotion: [],
      selectedMoodEmotion: "",
      musical_style: [],
      selectedMusicalStyle: "",
    };
  }

  componentDidMount() {

    let api = new ApiRequest("get", "/user/get-music-meta");
    api.send({}, (response) => {
      // match title of Instrumentation from response.data and set to the Instrumentation
      var instrumentation = response.data.find((item) => item.title === "Instrumentation");
      var convertedInstrumentation = instrumentation.childs.map((genre) => {
        return { value: genre.id, label: genre.title }
      });;

      // match title of Production Genre from response.data and set to the Production Genre

      var production_genre = response.data.find((item) => item.title === "Production Genre");
      var convertedProductionGenre = production_genre.childs.map((genre) => {
        return { value: genre.title, label: genre.title }
      });

      // match title of Mood / Emotion from response.data and set to the Mood / Emotion
      var mood_emotion = response.data.find((item) => item.title === "Mood Emotion");
      var convertedMoodEmotion = mood_emotion.childs.map((genre) => {
        return { value: genre.title, label: genre.title }
      });

      // match title of Musical Style from response.data and set to the Musical Style
      var musical_style = response.data.find((item) => item.title === "Music Style");
      var convertedMusicalStyle = musical_style.childs.map((genre) => {
        return { value: genre.title, label: genre.title }
      });

      // temp
      var tempo = response.data.find((item) => item.title === "Tempos");
      var convertedTempo = tempo.childs.map((genre) => {
        return { value: genre.title, label: genre.title }
      });

      this.setState({
        instrumentation: convertedInstrumentation,
        production_genre: convertedProductionGenre,
        mood_emotion: convertedMoodEmotion,
        musical_style: convertedMusicalStyle,
        tempo: convertedTempo
      });
    });
  }

  handleSearch() {
    // var _state = this.state;
    // var _errors = [];

    // if (_state.minimum.length < 1 || _state.maximum.length < 1) {
    //   _errors.push("Page count must be a number");
    // }

    // this.setState({ errors: _errors });
    // if (_errors.length > 0) {
    //   return;
    // }

    // var search_params = {};
    // search_params["answers"] = [];
    // if (_state.instrumentation.length > 0) {
    //   search_params["answers"].push({
    //     question_id: 513,
    //     answer: _state.instrumentation,
    //   });
    // }
    // if (_state.genre.length > 0) {
    //   search_params["answers"].push({ question_id: 540, answer: _state.genre });
    // }
    // if (_state.mood.length > 0) {
    //   search_params["answers"].push({ question_id: 469, answer: _state.mood });
    // }
    // if (_state.style.length > 0) {
    //   search_params["answers"].push({ question_id: 448, answer: _state.style });
    // }
    // if (_state.tempo.length > 0) {
    //   search_params["answers"].push({ question_id: 576, answer: _state.tempo });
    // }
    // if (_state.range.length > 0) {
    //   search_params["answers"].push({
    //     question_id: 2423,
    //     answer: { less_than: _state.range, greater_than: 0 },
    //   });
    // }

    // search_params["post_type"] = [9];
    // localStorage.setItem("seeking_search", JSON.stringify(search_params));
    // localStorage.setItem("seeking_type", "music");

    // window.location.href = "/?seeking=1";

    window.location.href = "/";
  }

  render() {
    var _showErrors = this.state.errors.length > 0 ? "block" : "none";
    var _errorStr = this.state.errors.join(" , ");

    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
              <form className="seeking container">
                <h4>Search Music</h4>
                <div
                  className="alert alert-danger"
                  style={{ display: _showErrors }}
                >
                  {_errorStr}
                </div>
                <div className="row" style={{ marginBottom: "12px" }}>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Instrumentation</label>
                      {/* <MultiSelect
                        id={513}
                        placeholder="Select Instrumentation"
                        hideButton={true}
                        selectedTypes={this.state.instrumentation}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ instrumentation: items })
                        }
                      /> */}
                      <div className="react-select-styled">
                        <ReactSelect
                          placeholder="Select Instrumentation"
                          isMulti
                          onChange={(selectedInstrumentation) => {
                            this.setState({ selectedInstrumentation });
                          }
                          }
                          options={
                            this.state.instrumentation
                          } />
                      </div>

                    </div>
                  </div>{" "}
                  <div className="col-6">
                    <div className="form-group">
                      <label>Production Genre</label>
                      {/* <MultiSelect
                        id={540}
                        placeholder="Select Genre"
                        hideButton={true}
                        selectedTypes={this.state.genre}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ genre: items })
                        }
                      /> */}
                      <div className="react-select-styled">
                        <ReactSelect
                          placeholder="Select Production Genre"
                          defaultValue={this.state.selectedProductionGenre}
                          onChange={(selectedProductionGenre) => {
                            this.setState({ selectedProductionGenre });
                          }
                          }
                          options={
                            this.state.production_genre
                          } />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Mood/Emotion</label>
                      {/* <MultiSelect
                        placeholder="Select Mood"
                        id={469}
                        hideButton={true}
                        selectedTypes={this.state.mood}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ mood: items })
                        }
                      /> */}
                      <div className="react-select-styled">
                        <ReactSelect
                          placeholder="Select Mood / Emotion"
                          defaultValue={this.state.selectedMoodEmotion}
                          onChange={(selectedMoodEmotion) => {
                            this.setState({ selectedMoodEmotion });
                          }
                          }
                          options={
                            this.state.mood_emotion
                          } />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Musical Style</label>
                      {/* <MultiSelect
                        id={448}
                        placeholder="Select Musical Style"
                        hideButton={true}
                        selectedTypes={this.state.style}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ style: items })
                        }
                      /> */}
                      <div className="react-select-styled">
                        <ReactSelect
                          placeholder="Select Musical Style"
                          defaultValue={this.state.selectedMusicalStyle}
                          onChange={(selectedMusicalStyle) => {
                            this.setState({ selectedMusicalStyle });
                          }
                          }
                          options={
                            this.state.musical_style
                          } />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label>Tempo</label>
                      {/* <MultiSelect
                        id={576}
                        placeholder="Select Tempo"
                        hideButton={true}
                        selectedTypes={this.state.tempo}
                        onCompanyTypesSelected={(items) =>
                          this.setState({ tempo: items })
                        }
                      /> */}
                      <div className="react-select-styled">
                        <ReactSelect
                          placeholder="Select Tempo"
                          defaultValue={this.state.selectedTempo}
                          onChange={(selectedTempo) => {
                            this.setState({ selectedTempo });
                          }
                          }
                          options={
                            this.state.tempo
                          } />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="form-group"
                      style={{ backgroundColor: "#fff", padding: "12px" }}
                    >
                      <label>DURATION</label>
                      <div className="sliderVal" style={{ float: "right" }}>
                        {time_convert(this.state.range)}
                      </div>{" "}
                      <input
                        type="range"
                        className="form-control"
                        style={{ padding: 0 }}
                        min="0"
                        max="300"
                        defaultValue={this.state.range}
                        onChange={(e) =>
                          this.setState({ range: parseInt(e.target.value) })
                        }
                      />
                      0:00
                      <div style={{ float: "right" }}>5:00 +</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="button"
                        onClick={this.handleSearch.bind(this)}
                        className="g-button full"
                      >
                        Search Music
                      </button>
                      This search will list out all available music matching
                      your search criterea.
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SeekingMusic;
