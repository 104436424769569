import React, { Component } from "react";
import PropTypes from "prop-types";
import ApiRequest from "../../libs/request.js";
//import Hobbies from '../profile/Hobbies';
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import CompanyTypes from "./CompanyTypes";
import { validFile } from "../../libs/functions";
import { toast } from "react-toastify";
var companyFormData = new FormData();
class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new: false,
      company: {},
      uploaderMode: "cover",
      companies: [],
      company_types: [],
    };
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleDeleteCompany = this.handleDeleteCompany.bind(this);
  }

  static get propTypes() {
    return {
      user: PropTypes.any,
    };
  }

  render() {
    if (this.state.new) {
      return this.companyForm();
    } else {
      return this.companyList();
    }
  }

  companyForm() {
    var _backgroundImage = {};
    var _backgroundImagePrompt = {
      backgroundImage: 'url("./images/placeholder_2.jpg")',
      width: "100%",
      height: "100%",
      textAlign: "center",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    };
    var _avatar = {};
    var _avatarPrompt = {};
    if (this.state.company["cover"]) {
      _backgroundImage = {
        backgroundImage: 'url("' + this.state.company["cover"] + '")',
      };
      _backgroundImagePrompt = { visibility: "hidden" };
    }
    if (this.state.company["profile"]) {
      _avatar = {
        backgroundImage: 'url("' + this.state.company["profile"] + '")',
      };
      _avatarPrompt = { visibility: "hidden" };
    }
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
          <div className="col-md-9 col-lg-10 col-sm-12">
          <div className="row justify-content-center">
            <div
              className="col-12"
              style={{ marginTop: "108px", marginLeft: "-42px" }}
            >
              <div className="profile-form">
                <div className="title"></div>
                <div className="upload">
                  <div
                    className="image-upload"
                    style={_backgroundImage}
                    onClick={() => this.handleFileClick("cover")}
                  >
                    <div id="backgroundPrompt" style={_backgroundImagePrompt}>
                      Upload background image
                    </div>
                    <input
                      type="file"
                      className="hidden-upload-field"
                      id="cover-image-field"
                      onChange={this.handleFileUpload}
                    />
                  </div>
                  <div
                    className="camera-btn"
                    style={_avatar}
                    onClick={() => this.handleFileClick("profile")}
                  >
                    <a href="#" id="avatar" style={_avatarPrompt}></a>
                  </div>

                  <input
                    type="file"
                    className="hidden-upload-field"
                    id="profile-image-field"
                    onChange={this.handleFileUpload}
                  />

                  <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
                    <h2>Company Information</h2>
                    <form id="profileForm" className="gorilla-form">
                      <div className="row m-0 p-0">
                        <div className="col-6 m-0 p-0 pr-4 pl-6">
                          <label>Company Name</label>
                          <input
                            id="company_name"
                            defaultValue={this.state.company["name"] || ""}
                            className="form-control"
                            placeholder="Company Name"
                          />
                        </div>
                        <div className="col-6 m-0 pl-6 pr-0 pb-4">
                          <label>Address</label>
                          <input
                            id="address"
                            defaultValue={this.state.company["address"] || ""}
                            className="form-control"
                            placeholder="Address"
                          />
                        </div>
                        <div className="col-6 m-0 p-0 pr-4 pl-6">
                          <label>Phone</label>
                          <input
                            id="phone"
                            defaultValue={this.state.company["phone"] || ""}
                            className="form-control"
                            placeholder="Phone"
                          />
                        </div>
                        <div className="col-6 m-0 pl-6 pr-0 pb-4">
                          <label>Email</label>
                          <input
                            id="email"
                            defaultValue={this.state.company["email"] || ""}
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>

                        <div className="col-12 m-0 p-0 pb-4">
                          <label>Company Type</label>
                          <input type="text" id="company_types"    className="form-control"
                            placeholder="Company Type" />
                          {/* <CompanyTypes
                            selectedTypes={this.state.company["companytypes"]}
                            onCompanyTypesSelected={(items) =>
                              this.setState({ company_types: items })
                            }
                          /> */}
                        </div>
                        <div className="col-12 m-0 p-0 pb-4">
                          <label>Description</label>
                          <textarea
                            id="description"
                            defaultValue={
                              this.state.company["description"] || ""
                            }
                            style={{ height: "160px" }}
                            className="form-control textarea"
                            placeholder="About the company"
                          ></textarea>
                        </div>
                        <div className="row m-0 p-0">
                          <div className="col-12 m-0 p-0">
                            <button
                              className="g-button"
                              type="button"
                              onClick={this.handleCompanySave.bind(this)}
                            >
                              {this.state.company["id"] ? "Update" : "Save"}
                            </button>
                          </div>
                        </div>
                        <p>&nbsp;</p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleFileUpload() {
    var imagefile = document.querySelector(
      "#" + this.state.uploaderMode + "-image-field"
    );
    companyFormData.append(
       this.state.uploaderMode,
      imagefile.files[0]
    );
    if (!validFile(imagefile, "photo")) {
      return;
    }

    var reader = new FileReader();
    reader.onload = function (e) {
      if (this.state.uploaderMode === "cover") {
        document.querySelector("#backgroundPrompt").style.visibility = "hidden";
        document.querySelector(".image-upload").style.backgroundImage =
          "url('" + e.target.result + "')";
      } else {
        document.querySelector("#avatar").style.visibility = "hidden";
        document.querySelector(".camera-btn").style.backgroundImage =
          "url('" + e.target.result + "')";
      }
    }.bind(this);

    reader.readAsDataURL(imagefile.files[0]);



    // let apiRequest = new ApiRequest("post", "/users/company");
    // if (this.state.company["id"]) {
    //   apiRequest.method = "put";
    //   apiRequest.url = "/users/company/" + this.state.company["id"];
    // }
    // apiRequest.send_file(formData, (response) => {
    //   let result = response.data;
    //   if (result.errors) {
    //     this.setState({ errors: result.message });
    //   } else {
    //     this.setState({ company: result.data });
    //     if (this.state.uploaderMode === "image") {
    //       document.querySelector("#backgroundPrompt").style.visibility =
    //         "hidden";
    //       document.querySelector(".image-upload").style.backgroundImage =
    //         "url('" + result.data.cover + "')";
    //     } else {
    //       document.querySelector("#avatar").style.visibility = "hidden";
    //       document.querySelector(".camera-btn").style.backgroundImage =
    //         "url('" + result.data.profile + "')";
    //     }
    //   }
    // });
  }

  phonenumber(val) {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return val.match(phoneno);
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  handleCompanySave() {
    if (document.getElementById("company_name").value.length < 1) {
      toast.error("Please enter a company name");
      return;
    }
    if (document.getElementById("address").value.length < 1) {
      toast.error("Please enter a company address");
      return;
    }
    if (document.getElementById("phone").value.length < 1) {
      toast.error("Please enter a company phone");
      return;
    }
    if (document.getElementById("phone").value.length < 1) {
      toast.error("Please enter a valid phone number");
      return;
    }

    if (document.getElementById("email").value.length < 1) {
      toast.error("Please enter a company email");
      return;
    }

    if (!this.validateEmail(document.getElementById("email").value)) {
      toast.error("Please enter a valid email address");
      return;
    }

    // if (this.state.companytypes.length < 1) {
    //   alert("Please select a company type");
    //   return;
    // }

    let apiRequest = new ApiRequest("post", "/user/add-company");
    if (this.state.company["id"]) {
      apiRequest.method = "post";
      apiRequest.url = "/user/update-company/" + this.state.company["id"];
    }
    // let params = {
    //   name: document.getElementById("company_name").value,
    //   address: document.getElementById("address").value,
    //   phone: document.getElementById("phone").value,
    //   email: document.getElementById("email").value,
    //   description: document.getElementById("description").value,
    // };
    // params["types[]"] = [121, 212];

    companyFormData.append("name", document.getElementById("company_name").value);
    companyFormData.append("address", document.getElementById("address").value);
    companyFormData.append("phone", document.getElementById("phone").value);
    companyFormData.append("email", document.getElementById("email").value);
    companyFormData.append("description", document.getElementById("description").value);
    // companyFormData.append("types[]", document.getElementById("company_types").value);
    companyFormData.append("types[]", [121, 212]);

    apiRequest.send_file(companyFormData, (response) => {
      let result = response.data;
      if (result.errors) {
        this.setState({ errors: result.message });
      } else {
        //("/projects/new");
        window.location.href = "/companies";
      }
    });
  }

  handleFileClick(uploader) {
    document.getElementById(uploader + "-image-field").click();
    this.setState({ uploaderMode: uploader });
  }
  companyList() {
    var _companies = [];
    this.state.companies.map((c, idx) => {
      var url = "/companies/edit/" + c.id;
      var name = c.name.length > 0 ? c.name : "My company " + c.id;
      _companies.push(
        <div key={idx} className="company-list-item">
          <img src={c.profile} />
          <a href={url}>{name}</a>
          <i
            className="fas fa-trash"
            onClick={() => this.handleDeleteCompany(c.id)}
          />
        </div>
      );
    });

    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
              <div className="float-left">
                <h3>Companies</h3>
                {_companies}
              </div>
              <div className="float-right">
                <div
                  style={{
                    marginTop: "8px",
                    color: "green",
                    cursor: "pointer",
                  }}
                  onClick={() => this.setState({ new: true })}
                >
                  <i className="fas fa-plus-circle" /> Add New Company
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleDeleteCompany(id) {
    if (confirm("Are you sure you want to remove this company?")) {
      let api = new ApiRequest("post", "/user/delete-company");
      api.send({
        id: id,
      }, () => {
        api.url = "/user/get-companies";
        api.method = "get";
        api.send({}, (response) => {
          this.setState({ companies: response.data.companies });
        });
      });
    }
  }
  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      let api = new ApiRequest("get", "/user/get-company?company_id=" + id);
      api.send({}, (response) => {
        this.setState({ company: response.data.company, new: true });
      });
    } else {
      let api = new ApiRequest("get", "/user/get-companies");
      api.send({}, (response) => {
        this.setState({ companies: response.data.companies });
      });
    }
  }
}

export default Companies;
