import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { validFile } from "../../libs/functions";
import { _backgroundImagePrompt } from "../../libs/constants.js";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.uploaderMode = "banner";
  }

  render() {
    return (
      <React.Fragment>
        <div className="upload" style={{ height: "275px" }}>
          <div
            className="image-upload"
            onClick={() => this.handleFileClick("banner")}
          >
            <div id="backgroundPrompt" style={_backgroundImagePrompt}>Upload background image</div>
            <input
              type="file"
              className="hidden-upload-field"
              id="banner-image-field"
              onChange={this.handleFileUpload}
            />
          </div>
          <div
            className="camera-btn"
            onClick={() => this.handleFileClick("avatar")}
          >
            <a href="#" id="avatar"></a>
          </div>

          <input
            type="file"
            className="hidden-upload-field"
            id="avatar-image-field"
            onChange={this.handleFileUpload}
          />
        </div>
      </React.Fragment>
    );
  }

  handleFileUpload() {
    var formData = new FormData();
    var imagefile = document.querySelector(
      "#" + this.uploaderMode + "-image-field"
    );
    formData.append("user[" + this.uploaderMode + "]", imagefile.files[0]);
    if (!validFile(imagefile, "photo")) {
      return;
    }

    let apiRequest = new ApiRequest("put", "/users/0.json");
    apiRequest.send_file(formData, (response) => {
      let result = response.data;
      if (result.errors) {
        this.setState({ errors: result.message });
      } else {
        if (this.uploaderMode === "banner") {
          document.querySelector("#backgroundPrompt").style.visibility =
            "hidden";
          document.querySelector(".image-upload").style.backgroundImage =
            "url('" + result.data.user.banner + "')";
        } else {
          document.querySelector("#avatar").style.visibility = "hidden";
          document.querySelector(".camera-btn").style.backgroundImage =
            "url('" + result.data.user.profile_photo + "')";
        }
      }
    });
  }

  handleFileClick(uploader) {
    document.getElementById(uploader + "-image-field").click();
    this.uploaderMode = uploader;
  }

  componentDidMount() {
    let api = new ApiRequest("get", "/user/view-profile");
    api.send({}, (response) => {
      let result = response.data;
      this.setState(result.user);
      this.forceUpdate();
      if (this.state.banner) {
        document.querySelector("#backgroundPrompt").style.display = "none";
        document.querySelector(".image-upload").style.backgroundImage =
          "url('" + this.state.banner + "')";
      }
      if (this.state.profile_photo) {
        document.querySelector("#avatar").style.display = "none";
        document.querySelector(".camera-btn").style.backgroundImage =
          "url('" + this.state.profile_photo + "')";
      }
    });
  }
}

export default Banner;
