import React, { Component } from "react";
import { PostTypesMock } from "../../libs/constants";

class FeedFilters extends Component {
  constructor(props) {
    super(props);
    this.state = { filters: [] };
  }

  render() {
    var params = new URLSearchParams(location.search);
    var type = params.get("type") ? params.get("type") : "1";
    const _filters = [];

    if (!this.state.filters) {
      return <div />;
    }

    this.state.filters.map((f, idx) => {
      var _checked = type === f.id.toString() ? true : false;
      if (
        ["general", "project", "seeking crew"].indexOf(f.name.toLowerCase()) >
        -1
      ) {
        _filters.push(
          <div className="filter-items" key={idx}>
            <label>{f.name.replace("Seeking", "")}</label>
            <input
              defaultChecked={_checked}
              name="postfilters"
              id="post-filters"
              value={f.id}
              type="radio"
            />
          </div>
        );
      }
    });
    _filters.push(
      <div className="filter-items" key={111}>
        <label>Items for Sale</label>
        <input
          defaultChecked={false}
          name="postfilters"
          id="post-filters"
          value="0"
          type="radio"
        />
      </div>
    );

    return (
      <div className="filters">
        <b>Feed Type</b>
        {_filters}
        <button
          className="btn g-button"
          type="button"
          onClick={this.handleFilterButton.bind(this)}
        >
          Search
        </button>
      </div>
    );
  }

  handleFilterButton() {
    location.href =
      "/?type=" +
      document.querySelector("input[name=postfilters]:checked").value;
  }

  componentDidMount() {
    // var api = new ApiRequest("get", "/posts/post_types");
    // api.send({}, (response) => {
    //   this.setState({ filters: response.data.data.post_types });
    // });
    this.setState({ filters: PostTypesMock });
  
  }
}

export default FeedFilters;
