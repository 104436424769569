import React, { Component } from "react";

class RecipientList extends Component {
  constructor(props) {
    super(props);
    this.state = { recipients: [] };
  }

  render() {
    let _recipients = [];

    if (this.props.recipients.length == 0)
      return <div />;

    this.props.recipients.slice(0, 2).map((recipient, idx) => {
      _recipients.push(
        <div
          key={idx}
          className="profile_photo"
          style={{
            backgroundImage: "url(" + recipient.user.profile_photo + ")",
          }}
        />
      )
    });

    return _recipients;
  }
}

export default RecipientList;
