import React, { Component } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

class GooglePlacesAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlace: null,
        };
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    }

    render() {
        return (
            <div>
                <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'YOUR_GOOGLE_MAPS_API_KEY'}
                    selectProps={{
                        // value: this.props.selectedValue ? {label: this.props.selectedValue} : this.state.selectedPlace,
                        value:  this.state.selectedPlace,
                        onChange: this.handlePlaceSelect,
                        options: this.state.place,
                    }}
                />
            </div>
        );
    }

    handlePlaceSelect(selectedOption) {
        console.log('selectedOption', selectedOption);
        this.setState({ selectedPlace: selectedOption });
        this.props.selectedLocation(selectedOption);
    }
}

export default GooglePlacesAutoComplete;
