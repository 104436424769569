import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import Nav from "./Nav";
import FeedNav from "./FeedNav";

function UsersList(_props) {
  if (!_props.users) {
    return <div />;
  }
  let _table = [];

  _props.users.map((user, idx) => {
    console.log("user", user);
    var _btnCss =
      _props.pending.indexOf(user.id.toString()) > -1 ? "requested" : "";
    var _text =
      _props.pending.indexOf(user.id.toString()) > -1
        ? "ACCEPTED"
        : "ACCEPT";
    var hasAction = _props.hasAction;
    if (user.id === _props.user.id) {
      hasAction = "none";
    }
    var _rejectBtn = "";
    if (user.id != _props.user.id && _props.hasRejection) {
      _rejectBtn = (
        <button
          id={user.user_from}
          className={"actionbtn frnd-req-reject-btn" + _btnCss}
          style={{ display: hasAction}}
          type="button"
          onClick={_props.onRejectionClick}
        >
          REJECT
        </button>
      );
    }
    var removeFriendBtn = "";
    if (_props.hasRemove) {
      removeFriendBtn = (
        <button
          id={user.user_from}
          className={"actionbtn " + _btnCss}
          style={{ display: _props.hasRemove }}
          type="button"
          onClick={_props.onRemoveFriendClick}
        >
          REMOVE
        </button>
      );
    }
    {
      user.friend_from && 
      _table.push(
      <tr key={idx}>
        <td width="60">
          <a href={"/user/profile/" + user.friend_from.id + "#profile"}>
            <img src={user.friend_from.profile_photo} height="40" width="40" style={{borderRadius: "10px"}} />
          </a>
        </td>
        <td>
          <b>
            {user.friend_from.first_name} {user.friend_from.last_name}
          </b>
        </td>
        <td className="friend-req-btns">
          <button
            id={user.user_from}
            className={"actionbtn frnd-req-accept-btn" + _btnCss}
            style={{ display: hasAction }}
            type="button"
            onClick={_props.onButtonClick}
          >
            {_text}
          </button>
          {_rejectBtn}
        </td>
      </tr>
    );}
    {
      user.friend && 
      _table.push(
      <tr key={idx}>
        <td width="60">
          <a href={"/user/profile/" + user.friend.id + "#profile"}>
            <img src={user.friend.profile_photo} height="40" width="40" style={{borderRadius: "10px"}} />
          </a>
        </td>
        <td>
          <b>
            {user.friend.first_name} {user.friend.last_name}
          </b>
        </td>
        <td>
          <button
            id={user.id}
            className={"actionbtn " + _btnCss}
            style={{ display: hasAction }}
            type="button"
            onClick={_props.onButtonClick}
          >
            {_text}
          </button>
          {_rejectBtn}
          {_props.hasRemove &&
             <button
             id={user.friend.id}
             className={"actionbtn frnd-req-remove-btn" + _btnCss}
             style={{ display: _props.hasRemove }}
             type="button"
             onClick={_props.onRemoveFriendClick}
           >
             REMOVE
           </button>
          }
        </td>
      </tr>
    );}
  });
  return (
    <table
      className="users-list"
      style={{ marginLeft: "12px", marginBottom: "24px" }}
    >
      <tbody>{_table}</tbody>
    </table>
  );
}

class Friends extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      users: [],
      pending: [],
      friends: [],
      requests: [],
    };
  }

  render() {
    return (
      <React.Fragment>
        <Nav user={this.state.user} />
        <div className="feed">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
            

              <h3 style={{ marginLeft: "12px", fontSize: "18px" }}>
                Pending Friend Requests
              </h3>
              <div
                id="alert"
                className="alert alert-success"
                style={{ display: "none" }}
              >
                Friend Request Accepted
              </div>
              <UsersList
                // users={this.state.requests}
                users={this.state.users}
                user={this.props.user}
                hasAction={"block"}
                onRejectionClick={this.handleRejectClick.bind(this)}
                hasRejection={true}
                pending={this.state.pending}
                onButtonClick={this.handleVoucherClick.bind(this)}
              />
              <h3 style={{ marginLeft: "12px", fontSize: "18px" }}>Friends</h3>
              <UsersList
                users={this.state.friends}
                user={this.props.user}
                onRemoveFriendClick={this.handleRemoveFriendClick.bind(this)}
                hasAction={"none"}
                hasRemove={true}
                pending={this.state.pending}
                onButtonClick={this.handleVoucherClick.bind(this)}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleRejectClick(e) {
    var id = e.target.id;
    var api = new ApiRequest("post", "/user/reject-request/");
    api.send({ friend_id: id }, () => {
      var _alert = document.getElementById("alert");
      _alert.innerText = "Friend Request Denied";
      _alert.style.display = "block";

      this.loadFriends();
    });
  }

  handleRemoveFriendClick(e) {
    var id = e.target.id;
    var api = new ApiRequest("post", "/user/delete-friend");
    api.send({ friend_id: id }, () => {
      var _alert = document.getElementById("alert");
      _alert.innerText = "Friend Removed";
      _alert.style.display = "block";

      this.loadFriends();
    });
  }

  handleVoucherClick(e) {
    let _pending = this.state.pending;
    var id = e.target.id;
    // var api = new ApiRequest("post", "/friends/" + id + "/approve");
    var api = new ApiRequest("post", "/user/accept-request/");
    api.send({ friend_id: id }, () => {
      var _alert = document.getElementById("alert");
      _alert.innerText = "Friend Request Approved";
      _alert.style.display = "block";

      this.loadFriends();
      _pending.push(id);
      this.setState({pending: _pending});
    });
  }

  componentDidMount() {
    this.loadFriends();
  }

  loadFriends() {

    let api = new ApiRequest("get", "/user/view-requests?type=pending");
    api.send({}, (response) => {
      var _pending = response.data.requests;
      api.url = "/user/view-friends";
        api.send({}, (response) => {
          this.setState({ users: _pending });
          this.setState({ requests: [] });
          this.setState({ friends: response.data.friends });
        });
    });

    // let api = new ApiRequest("get", "/friends/pending");
    // api.send({}, (response) => {
    //   var _pending = response.data.data.friends;
    //   api.url = "/friends/requests";
    //   api.send({}, (response) => {
    //     var _requests = response.data.data.friends;

    //     api.url = "/friends";
    //     api.send({}, (response) => {
    //       this.setState({ users: _pending });
    //       this.setState({ requests: _requests });
    //       this.setState({ friends: response.data.data.friends });
    //     });
    //   });
    // });
  }
}

export default Friends;
