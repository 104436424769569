import React, { Component } from 'react';

class LoadingSpinner extends Component {
    render() {

        return (
            <div className="loading-overlay">
                <div className="card p-3 rounded" style={{ width: '200px', height: '120px', margin: "auto", textAlign: "center" }}>
                    <h5 className="text-center mb-3">Loading...</h5>
                    <div
                        className="lds-roller mx-auto"
                        style={{
                            zoom: 0.5,
                        }}
                    >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoadingSpinner;
