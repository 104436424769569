import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import PostQuestionList from "../../components/main/PostQuestionList";
import ProjectBanner from "./ProjectBanner";

class MusicProjectForm extends Component {
  constructor(props) {
    super(props);
    this.state = { post: {}, questions: [], post_type: 9 };
  }

  render() {
    var _post_id = this.props.post ? this.props.post.id : 0;
    return (
      <React.Fragment>
        <div className="row justify-content-center projects">
          <ProjectBanner
            post_id={_post_id}
            onBannerUploaded={(post) => this.setState({ post_id: post })}
            post_type_id={9}
          />
        </div>
        <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
          <h2>Music Project</h2>
          <input type="hidden" name="id" id="post_id" value="" />
          <input
            type="hidden"
            value="2"
            id="post_type_id"
            name="post[post_type]"
          />

          <div className="col-12 m-0 p-0">
            <div className="form-group">
              <label>Song Title</label>
              <input
                type="text"
                id="title"
                name="title"
                defaultValue={this.state.title || ""}
                placeholder="Enter Song Title"
                className="form-control"
              />
            </div>

            <PostQuestionList
              post={this.state.post}
              questions={this.state.questions}
              fields={[
                "Instrumentation",
                "Production Genre",
                "Mood / Emotion",
                "Musical Style",
                "Select Duration",
                "Tempo",
              ]}
            />
          </div>

          <div className="col-12 p-0" style={{ float: "left" }}>
            <button
              className="g-button full"
              onClick={this.handleSubmit.bind(this)}
            >
              Save Music
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    let api = new ApiRequest("get", "/posts/new?post_type=6");
    api.send({}, (response) => {
      this.setState({
        post: { meta_data: { answers: [] } },
        questions: response.data.data.questions,
        post_type: 6,
      });
    });
  }

  handleSubmit() {
    const id = this.state.post_id ? this.state.post_id : null;
    var _required = [];
    var fields = document.querySelectorAll(".form-control");
    var answers = [];
    var params = {};
    fields.forEach((f) => {
      if (!params["body"]) {
        params["body"] = f.value;
      }
      var _txtAns = f.options ? f.options[f.selectedIndex].text : f.value;
      answers.push({
        question_id: parseInt(f.dataset.id),
        answer: f.value,
        textAnswer: _txtAns,
        question: f.dataset.question,
      });
    });
    params["answers"] = { answers: answers };
    params["post_type"] = 9;
    params["public"] = true;
    params["meta_data"] = { answers: answers };
    var api = new ApiRequest("post", "/posts");
    if (id) {
      api.method = "put";
      api.url = "/posts/" + id;
    }
    api.send({ post: params }, (result) => {
      this.props.onAfterSave(result.data.data.post.id);
    });
  }
}

export default MusicProjectForm;
