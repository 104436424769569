import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import { time_ago } from "../../libs/functions.js";

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 1, alerts: null };
  }

  createMarkup(markup) {
    return { __html: markup };
  }

  render() {
    if (this.props.user == null || this.state.alerts == null) {
      return <div />;
    }
    if (this.state.alerts.length < 1) {
      return (
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          You have no notifications right now
        </div>
      );
    }
    let _alerts = [];
    this.state.alerts.map((a, idx) => {
      if (a.payload.title) {
       
        var _avatar_image = a.payload.display || '/images/placeholder.png';
        _alerts.push(
          <React.Fragment>
            <div
              key={idx}
              className={`alert-item ${a.status == "read" ? "" : "alert-item-unread"}`}
              title={a.payload.title}
              onClick={() => this.handleAlertClick(idx).bind(this)}
            >
              <div
                className="profile_photo"
                style={{
                  backgroundImage:
                    "url(" + _avatar_image + ")",
                }}
              />
              <div className="name">
                {" "}
                <div
                  dangerouslySetInnerHTML={this.createMarkup(a.payload.title)}
                />{" "}
              </div>
              <div className="time">{time_ago(a.created_at)}</div>
            </div>
            <div
              className="del"
              onClick={() => this.handleDeleteAlert(a.id)}
            >
              {/* <i className="fas fa-trash" /> */}
              <i className="far fa-times-circle"></i>
            </div>
          </React.Fragment>
        );
      }
    });
    return (
      <React.Fragment>
        <div
          className="alerts-header"
          onClick={this.handleMarkAllRead.bind(this)}
        >
          Mark all as read
        </div>
        <div className="alerts-panel">{_alerts}</div>
      </React.Fragment>
    );
  }

  handleDeleteAlert(id) {
    var api = new ApiRequest("post", "/user/delete-notification");

    api.send({ notification_id: id }, (response) => {
      this.fetchAlerts();
      this.props.onAlertsChange();
    });
  }

  handleMarkAllRead() {
    var api = new ApiRequest("post", "/user/mark-all-as-read");
    var ids = [];
    this.state.alerts.map((a) => {
      ids.push(a.id);
    });
    api.send({ notification_id: ids }, (response) => {
      this.fetchAlerts();
      this.props.onAlertsChange();
    });
  }

  handleAlertClick(index) {
    var obj = this.state.alerts[index];
    var alertUrl = "";
    switch (obj.type) {
      case "send_message":
        var _id = obj.payload.chat_id;
        alertUrl = "/messages?thread=" + _id;
        break;
      case "post_tag":
        alertUrl = "/?id=" + obj.payload.post_id;
        break;
      case "post_comment":
        alertUrl = "/?id=" ;
        break;
      case "post_like":
        alertUrl = "/?id=" ;
        break;
      case "send_friend_request":
        alertUrl = "/friends";
        break;
      case "accept_friend_request":
        alertUrl = "/friends";
        break;
    }
    if (alertUrl != "") {
      var api = new ApiRequest("post", "/user/mark-as-read");
      api.send({ notification_id: obj.id }, (response) => {
        location.href = alertUrl;
      });
    }
  }

  fetchAlerts() {
    // var api = new ApiRequest("get", "/alerts?page=" + this.state.currentPage);
    var api = new ApiRequest("get", "/user/get-notifictions");
    api.send({}, (response) => {
      this.setState({ alerts: response.data.notifications });
      this.forceUpdate();
    });
  }

  componentDidMount() {
    this.fetchAlerts();
  }
}

export default Alerts;
