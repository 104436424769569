import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";

class FriendList extends Component {
  constructor(props) {
    super(props);
    this.state = { current_page: 1, friends: [] };
  }

  render() {
    if (this.state.friends.length == 0) {
      return <div />;
    }
    var _friends = [];
    this.state.friends.map((f, idx) => {
      if (f.friend) {
        var _removebutton = (
          <button
            type="button"
            className="g-button full"
            onClick={() => this.handleUnfriend(f.friend.id)}
            style={{ height: "34px", fontSize: "14px" }}
          >
            Remove Friend
          </button>
        );
        if (!!this.props.showRequestButton) {
          _removebutton = "";
        }
  
        _friends.push(
          <div className="friend-item">
            <a
              href={"/user/profile/" + f.friend.id + "#profile"}
              style={{ color: "#000", width: "100%", display: "block", position: "relative" }}
            >
              <div
                className="profile_photo"
                style={{ backgroundImage: "url('" + f.friend.profile_photo + "')" }}
              >
                <div className="info">
                  {f.friend.first_name} {f.friend.last_name}
                </div>
              </div>
            </a>
            <div
              style={{
                marginLeft: "320px",
                marginTop: "-49px",
                width: "153px",
                float: "left",
              }}
            >
              {_removebutton}
            </div>
          </div>
        );
      }
    });
    return <React.Fragment>{_friends}</React.Fragment>;
  }

  handleUnfriend(user_id) {
    if (confirm("Are you sure you want to remove this friend?")) {
      var api = new ApiRequest("post", "/user/delete-friend" + user_id);
      api.send({
        friend_id: user_id,
      }, (response) => {
        this.fetchList();
      });
    }
  }

  componentDidMount() {
    this.fetchList();
  }

  fetchList() {
    var api = new ApiRequest("get", "/user/view-friends");
 
    if (window.location.href.indexOf("/user/profile/") > -1) {
      var user_id = window.location.href.split("/user/profile/")[1].split("#")[0];
      api = new ApiRequest("get", "/user/view-user-friends?user_id=" + user_id);
    }

    api.send({}, (response) => {
      var _friends = response.data.friends;
      this.setState({ friends: _friends });
    });
  }
}
export default FriendList;
