import React, { useState } from 'react';
import { FaTimes, FaTimesCircle } from 'react-icons/fa';
import Modal from 'react-modal';

const CustomModal = ({ trigger, content, width }) => {
    const [isOpen, setIsOpen] = useState(false);


    const customModalStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: width ? width : "500px",
            padding: "0px",
            borderRadius: "10px",
        },
    };


    const openModal = () => {
        setIsOpen(true);
    };


    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <div onClick={openModal}>{trigger}</div>
            <Modal
                style={customModalStyles}
                isOpen={isOpen}
                onRequestClose={closeModal}
                contentLabel="Custom Modal"
                ariaHideApp={false} // To avoid accessibility warnings
            >
                <FaTimes onClick={() => closeModal()}
                    style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer",
                        zIndex: "1000",
                    }}
                />

                {/* <FaTimesCircle
                    onClick={() => closeModal()}
                    style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer",
                        fontSize: "24px",
                        color: "white",
                        zIndex: "1000",
                    }}
                /> */}

                {content}
            </Modal>
        </div>
    );
};

export default CustomModal;
