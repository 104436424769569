import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import ReportPost from "./ReportPost";
import PromoteLink from "./PromoteLink";
import ShareLink from "./ShareLink";
import LikeLink from "./LikeLink";

import {
  time_ago,
  highLightTags,
  urlify,
  metaPostFormat,
  isValidUrl,
  getPostTypes,
} from "../../libs/functions.js";
import Tagging from "./Tagging";
import MessagePost from "./MessagePost";

import { BsHeartFill, BsChatFill, BsEyeFill } from "react-icons/bs";
import MetaDataComponent from "../shared/meta-data/metadata.js";
import ConfirmationModal from "../shared/ConfirmationModal.js";
import CommentModal from "../shared/CommentModal.js";
import { toast } from "react-toastify";


class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taggedFriends: [],
      taggingActive: false,
      current_page: 1,
      comments: [],
      isEditing: false,
      editId: null,
      friends: [],
      currentTag: "",
      showDeleteModal: false,
      showCommentModal: false,
      selectedPost: null,
    };
    this.handleComments = this.handleComments.bind(this);
    this.handleAddComment = this.handleAddComment.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleReplyComment = this.handleReplyComment.bind(this);
  }

  render() {
    if (this.props.post.type == "post") {
      return this.generalPost();
    }
    if (this.props.post.type == "crew") {
      return this.crewPost();
    }
    if (this.props.post.type == "shared") {
      return this.sharedPost();
    }
    return this.metaBasedPost();
  }

  metaBasedPost() {
    var _controls = this.postControls();

    const { post: { post } } = this.props;

    // Regular expression to match URLs in the post text
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Extract all URLs from the post text
    const urls = post && post.match(urlRegex);

    var perpsective = (
      <div className="post-header">
        {_controls}
        <a
          href={"/user/profile/" + this.props.post.user.id + "#profile"}
          className="profile_photo"
        >
          <img src={this.props.post.user.profile_photo} />
        </a>
        <div className="name">
          {this.props.post.user.first_name} {this.props.post.user.last_name}
        </div>
        <div className="time">
          <span>{this.props.post.type}</span>
          {time_ago(this.props.post.created_at)}
        </div>
      </div>
    );
    if (this.props.post.company) {
      var image = this.props.post.user.profile_photo;
      if (this.props.post.company.profile) {
        image = this.props.post.company.profile;
      }
      perpsective = (
        <div className="post-header">
          {_controls}
          <a
            href={"/user/profile/" + this.props.post.user.id + "#profile"}
            className="profile_photo"
          >
            <img src={image} />
          </a>
          <div className="name">{this.props.post.company.name}</div>
          <div className="time">
            <span>{this.props.post.type}</span>
            {time_ago(this.props.post.created_at)}
          </div>
        </div>
      );
    }

    return (
      <div className="feed-panel" post={this.props.post.id}>

        {perpsective}

        <div className="body">
          {this.props.post.post && (

            isValidUrl(`${this.props.post.post}`) ? (
              <>
                <blockquote><div
                  dangerouslySetInnerHTML={{
                    __html: urlify(this.props.post.post),
                  }}
                /></blockquote>
                {urls &&
                  urls.map((url, index) => (
                    <MetaDataComponent key={index} url={url} />
                  ))}
              </>
            ) : (
              // <blockquote>{this.props.post.post}</blockquote>
              <div
                dangerouslySetInnerHTML={{
                  __html: urlify(highLightTags(this.props.post)),
                }}
              />
            )
          )}
          {/* <div
            dangerouslySetInnerHTML={{
              __html: urlify(highLightTags(this.props.post)),
            }}
          /> */}


          {/* Render the post content with HTML formatting */}
          {/* <div
            dangerouslySetInnerHTML={{
              __html: urlify(highLightTags(post)),
            }}
          />
          {_meta_data} */}
          <div className="assets">{this.props.asset}</div>
        </div>
        <div className="footer">
          <LikeLink post={this.props.post} />
          <div
             onClick={() => this.setState({ showCommentModal: true })}
            className="likes"
          >
            <BsChatFill className="_icon" />
            <span style={{ marginLeft: "5px" }}>
              {this.props.post.comments_count}{" "}
              Comments
            </span>
          </div>
          {/* <PromoteLink user={this.props.user} post={this.props.post} /> */}
          <ShareLink post={this.props.post} />
        </div>
        <CommentModal
          isOpen={this.state.showCommentModal}
          onRequestClose={() => {
            this.setState({ showCommentModal: false });
          }}
          post={this.props.post}
          user={this.props.user}
          friends={this.state.friends}
          comments={this.state.comments}
          onhandleTaggedItem={(friend) => this.handleTaggedItem(friend)}
        />
      </div>
    );
  }

  handleDeleteComment(id) {
    var api = new ApiRequest("post", "/user/delete-comment");
    api.send({
      comment_id: id,
    }, () => {
      toast.success("Comment deleted");
    });
  }

  postControls() {
    let _controls = [];
    if (this.props.user.id === this.props.post.user.id) {
      var editUrl =
        "/posts/edit/" +
        1 +
        "/" +
        this.props.post.id;

      if (this.props.post.type_id == 2) {
        editUrl = "/new-project?id=" + this.props.post.id;
      }
      if (this.props.post.type_id == 3) {
        editUrl = "/seeking/crew?new=1&id=" + this.props.post.id;
      }
      if (this.props.post.type_id == 8) {
        editUrl = "/posts/sell?id=" + this.props.post.id;
      }
      _controls.push(
        <i
          key="2"
          className="fas fa-edit"
          title="Edit Post"
          onClick={() => (window.location.href = editUrl)}
        />
      );
      _controls.push(
        <i
          key="3"
          className="fas fa-trash"
          title="Delete Post"
          onClick={() => {
            this.setState({
              showDeleteModal: true,
              selectedPost: this.props.post.id,
            });
          }}
        />
      );
    } else {
      _controls.push(<ReportPost key="1" post={this.props.post} />);
      _controls.push(<MessagePost key="4" post={this.props.post} />);
    }

    return <div className="post-controls">{_controls}</div>;
  }

  generalPost() {
    var _controls = this.postControls();

    const { post: { post } } = this.props;

    // Regular expression to match URLs in the post text
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Extract all URLs from the post text
    const urls = post && post.match(urlRegex);

    var perpsective = (
      <div className="post-header">
        {_controls}
        <a
          href={"/user/profile/" + this.props.post.user.id + "#profile"}
          className="profile_photo"
        >
          <img src={this.props.post.user.profile_photo} />
        </a>
        <div className="name">
          {this.props.post.user.first_name} {this.props.post.user.last_name}
        </div>
        <div className="time">
          <span>{this.props.post.type}</span>
          {time_ago(this.props.post.created_at)}
        </div>
      </div>
    );
    if (this.props.post.company) {
      var image = this.props.post.user.profile_photo;
      if (this.props.post.company.profile) {
        image = this.props.post.company.profile;
      }
      perpsective = (
        <div className="post-header">
          {_controls}
          <a
            href={"/user/profile/" + this.props.post.user.id + "#profile"}
            className="profile_photo"
          >
            <img src={image} />
          </a>
          <div className="name">{this.props.post.company.name}</div>
          <div className="time">
            <span>{getPostTypes(this.props.post.type)}</span>
            {time_ago(this.props.post.created_at)}
          </div>
        </div>
      );
    }

    return (
      <div className="feed-panel" post={this.props.post.id}>

        {perpsective}

        <div className="body">
          {this.props.post.post && (

            isValidUrl(`${this.props.post.post}`) ? (
              <>
                <blockquote><div
                  dangerouslySetInnerHTML={{
                    __html: urlify(this.props.post.post),
                  }}
                /></blockquote>
                {urls &&
                  urls.map((url, index) => (
                    <MetaDataComponent key={index} url={url} />
                  ))}
              </>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: urlify(highLightTags(this.props.post)),
                }}
              />
            )
          )}
          <div className="assets">{this.props.asset}</div>
        </div>
        <div className="footer">
          <LikeLink post={this.props.post} />
          <div
            onClick={() => this.setState({ showCommentModal: true })}
            className="likes"
          >
            <BsChatFill className="_icon" />
            <span style={{ marginLeft: "5px" }}>
              {this.props.post.comments_count}{" "}
              Comments
            </span>
          </div>
          {/* <PromoteLink user={this.props.user} post={this.props.post} /> */}
          <ShareLink post={this.props.post} />
        </div>


        <ConfirmationModal
          isOpen={this.state.showDeleteModal}
          onRequestClose={() => {
            this.setState({ showDeleteModal: false });
          }}
          heading="Delete Post"
          bodyText="Are you sure you want to delete this post?"
          onSubmit={() => {
            this.handleDeletePost(this.state.selectedPost);
          }}
        />

        <CommentModal
          isOpen={this.state.showCommentModal}
          onRequestClose={() => {
            this.setState({ showCommentModal: false });
          }}
          post={this.props.post}
          user={this.props.user}
          friends={this.state.friends}
          comments={this.state.comments}
          onhandleTaggedItem={(friend) => this.handleTaggedItem(friend)}
        />



      </div>
    );
  }

  crewPost() {
    var _controls = this.postControls();
    var hideFooter = this.props.hideFooter ? "none" : "grid";

    var perpsective = (
      <div className="post-header">
        {_controls}
        <a
          href={"/user/profile/" + this.props.post.user.id + "#profile"}
          className="profile_photo"
        >
          <img src={this.props.post.user.profile_photo} />
        </a>
        <div className="name">
          {this.props.post.user.first_name} {this.props.post.user.last_name}
        </div>
        <div className="time">
          <span>{getPostTypes(this.props.post.type)}</span>
          {time_ago(this.props.post.created_at)}
        </div>
      </div>
    );
    if (this.props.post.company) {
      var image = this.props.post.user.profile_photo;
      if (this.props.post.company.image) {
        image = this.props.post.company.image;
      }
      perpsective = (
        <div className="post-header">
          {_controls}
          <a
            href={"/user/profile/" + this.props.post.user.id + "#profile"}
            className="profile_photo"
          >
            <img src={image} />
          </a>
          <div className="name">{this.props.post.company.name}</div>
          <div className="time">
            <span>{getPostTypes(this.props.post.type)}</span>
            {time_ago(this.props.post.created_at)}
          </div>
        </div>
      );
    }
    return (
      <div className="feed-panel" post={this.props.post.id}>
        {perpsective}
        <div className="body">
          {this._renderCrew(this.props.post.crew)}
          <div className="assets">{this.props.asset}</div>
        </div>
        <div className="footer" style={{ display: hideFooter }}>
          <LikeLink post={this.props.post} />
          <div
            onClick={() => this.setState({ showCommentModal: true })}
            className="likes"
          >
            <BsChatFill className="_icon" />{" "}
            <span style={{ marginLeft: "5px" }}>
              <span id={"comment-counter" + this.props.post.id}>
                {this.props.post.comments_count}
              </span>{" "}
              Comments
            </span>
          </div>
          {/* <PromoteLink user={this.props.user} post={this.props.post} /> */}
          <ShareLink post={this.props.post} />
        </div>
        {/* <Comments
          onDeleteClick={(id) => this.handleDeleteComment(id)}
          onEditClick={(id, parent) => this.handleEditComment(id, parent)}
          user={this.props.user}
          onAddComment={(event, post) => this.handleAddComment(event, post)}
          comments={this.state.comments}
          post={this.props.post}
          friends={this.state.friends}
          onhandleTaggedItem={(friend) => this.handleTaggedItem(friend)}
        /> */}

        <ConfirmationModal
          isOpen={this.state.showDeleteModal}
          onRequestClose={() => {
            this.setState({ showDeleteModal: false });
          }}
          heading="Delete Post"
          bodyText="Are you sure you want to delete this post?"
          onSubmit={() => {
            this.handleDeletePost(this.state.selectedPost);
          }}
        />

        <CommentModal
          isOpen={this.state.showCommentModal}
          onRequestClose={() => {
            this.setState({ showCommentModal: false });
          }}
          post={this.props.post}
          user={this.props.user}
          friends={this.state.friends}
          comments={this.state.comments}
          onhandleTaggedItem={(friend) => this.handleTaggedItem(friend)}
        />


      </div>
    );
  }

  sharedPost() {
    var _controls = this.postControls();
    var hideFooter = this.props.hideFooter ? "none" : "grid";


    const { post: { shared: { post } } } = this.props;

    // Regular expression to match URLs in the post text
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Extract all URLs from the post text
    const urls = post && post.match(urlRegex);

    var perpsective = (
      <div className="post-header">
        {_controls}
        <a
          href={"/user/profile/" + this.props.post.user.id + "#profile"}
          className="profile_photo"
        >
          <img src={this.props.post.user.profile_photo} />
        </a>
        <div className="name">
          {this.props.post.user.first_name} {this.props.post.user.last_name}
        </div>
        <div className="time">
          <span>{getPostTypes(this.props.post.type)}</span>
          {time_ago(this.props.post.created_at)}
        </div>
      </div>
    );

    var postOwner = (
      <div className="post-header">
        <a
          href={"/user/profile/" + this.props.post.shared.user.id + "#profile"}
          className="profile_photo"
        >
          <img src={this.props.post.shared.user.profile_photo} />
        </a>
        <div className="name">
          {this.props.post.shared.user.first_name} {this.props.post.shared.user.last_name}
        </div>
        <div className="time">
          {time_ago(this.props.post.shared.created_at)}
        </div>
      </div>
    );

    if (this.props.post.shared.company) {
      var image = this.props.post.shared.user.profile_photo;
      if (this.props.post.shared.company.profile) {
        image = this.props.post.shared.company.profile;
      }
      postOwner = (
        <div className="post-header">
          {/* {_controls} */}
          <a
          href={"/user/profile/" + this.props.post.user.id + "#profile"}
          className="profile_photo"
        >
          <img src={image} />
        </a>
          <div className="name">{this.props.post.shared.company.name}</div>
          <div className="time">
            <span>{getPostTypes(this.props.post.shared.type)}</span>
            {time_ago(this.props.post.shared.created_at)}
          </div>
        </div>
      );
    }

    return (
      <div className="feed-panel" post={this.props.post.id}>
        {perpsective}


        <div className="body shared-post">
          {postOwner}
          {this.props.post.shared.post && (

            isValidUrl(`${this.props.post.shared.post}`) ? (
              <>
                <blockquote><div
                  dangerouslySetInnerHTML={{
                    __html: urlify(this.props.post.shared.post),
                  }}
                /></blockquote>
                {urls &&
                  urls.map((url, index) => (
                    <MetaDataComponent key={index} url={url} />
                  ))}
              </>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: urlify(highLightTags(this.props.post.shared)),
                }}
              />
            )
          )}

          <div className="assets">{this.props.asset}</div>
        </div>

        <div className="footer" style={{ display: hideFooter }}>
          <LikeLink post={this.props.post} />
          <div
            onClick={() => this.setState({ showCommentModal: true })}
            className="likes"
          >
            <BsChatFill className="_icon" />{" "}
            <span style={{ marginLeft: "5px" }}>
              <span id={"comment-counter" + this.props.post.id}>
                {this.props.post.comments_count}
              </span>{" "}
              Comments
            </span>
          </div>
          {/* <PromoteLink user={this.props.user} post={this.props.post} /> */}
          <ShareLink post={this.props.post} />
        </div>


        <ConfirmationModal
          isOpen={this.state.showDeleteModal}
          onRequestClose={() => {
            this.setState({ showDeleteModal: false });
          }}
          heading="Delete Post"
          bodyText="Are you sure you want to delete this post?"
          onSubmit={() => {
            this.handleDeletePost(this.state.selectedPost);
          }}
        />

        <CommentModal
          isOpen={this.state.showCommentModal}
          onRequestClose={() => {
            this.setState({ showCommentModal: false });
          }}
          post={this.props.post}
          user={this.props.user}
          friends={this.state.friends}
          comments={this.state.comments}
          onhandleTaggedItem={(friend) => this.handleTaggedItem(friend)}
        />

      </div>
    );
  }

  _renderCrew(crew) {

    return (
      <div>
        <div className="crew-item">
          <div className="crew-title">{crew.description}</div>

          <div className="crew-location">
            <span className="crew-label">Preferred Location:</span>
            {crew.location}
            <span className="crew-label">Radius:</span>
            {crew.radius} miles
            <span className="crew-label">Preferred Designation:</span>
            {crew.crewtypes.map((ct) => {
              return ct.name + ", ";
            })}
            <span className="crew-label">Availability:</span>
            {crew.hiring_from} to {crew.hiring_to}
          </div>
        </div>
      </div>
    );

  }


  handleEditComment(comment, parent) {
    var _field = document.getElementById(comment.parent_id);
    _field.innerHTML = comment.body;
    _field.scrollIntoView();
    window.scrollBy(0, -80);
    this.setState({
      isEditing: true,
      editId: comment.id,
      editParent: comment.parent_id,
    });
  }

  handleReplyComment(comment, parent) {
    var _field = document.getElementById(comment.parent_id);
    _field.innerHTML = comment.body;
    _field.scrollIntoView();
    window.scrollBy(0, -80);
    this.setState({
      isEditing: true,
      editId: comment.id,
      editParent: comment.parent_id,
    });
  }

  handleDeletePost(post_id) {
    var api = new ApiRequest("post", "/user/delete-post");
    api.send({
      post_id: post_id,
    }, () => {
      document.querySelector('[post="' + post_id + '"]').remove();
      var _alert = document.getElementById("result");
      _alert.innerText = "Post deleted";
      _alert.style.display = "block";
      this.setState({ showDeleteModal: false });
    });
  }

  handleAddComment(e, post) {
    if (e) {
      var _obj = e;
      var _field = _obj.target;
      if (_obj.keyCode == 13 && _obj.target.innerText.length > 0) {
        var _taggedUsers = [];
        var _taggedNodes = document.querySelectorAll(".tagged-node");
        if (_taggedNodes.length > 0) {
          _taggedNodes.forEach((tn) => {
            this.state.taggedFriends.map((tf) => {
              if (tn.id == tf.id) {
                _taggedUsers.push({
                  img: tf.profile_photo != null ? tf.profile_photo : '/images/placeholder.png',
                  taggedName: tf.first_name + " " + tf.last_name,
                  taggedID: tf.id,
                  user: JSON.stringify(tf),
                });
              }
            });
          });
        }

        var api = new ApiRequest("post", "/posts");
        if (this.state.isEditing) {
          api.method = "put";
          api.url = "/posts/" + this.state.editId;
        }
        var _val = _obj.target;
        api.send(
          {
            post: {
              post_type: post.type_id,
              body: _val.innerText,
              parent_id: post.id,
              meta_data: { taggedUser: _taggedUsers },
            },
          },
          (response) => {
            _val.innerText = "";
            if (this.state.isEditing) {
              document.getElementById(this.state.editParent).innerText = "";
              this.handleComments(e, this.state.editParent);
              return;
            } else {
              var _comments = this.state.comments;
              _field.value = "";
              var data = response.data.data;
              _comments.push(data);
              var _counter = document.getElementById(
                "comment-counter" + post.id
              );
              _counter.innerText = parseInt(_counter.innerText) + 1;
              this.setState({ comments: _comments, isEditing: false });
            }
          }
        );
      } else {
        this.handleTagging(e);
      }
    }
  }

  handleTagging(e) {
    var _obj = e;
    var _field = _obj.target;
    if (e.keyCode == 50 && !this.state.taggingActive) {
      this.setState({ taggingActive: true });
    }
    if (this.state.taggingActive) {
      var obj = _field;
      var value = obj.innerHTML;
      var lastInstance = value.lastIndexOf("@");
      var tagSearch = value.substring(lastInstance + 1, value.length);
      if (tagSearch.length > 2) {
        var api = new ApiRequest("get", "/user/search-friends?search=" + tagSearch);
        api.send({}, (response) => {
          this.setState({
            currentField: obj,
            currentTag: tagSearch,
            friends: response.data.users.data,
          });
        });
      } else {
        this.setState({ friends: [] });
      }
    }
  }

  handleTaggedItem(friend) {
    var _taggedFriends = this.state.taggedFriends;
    if (_taggedFriends.indexOf(friend) == -1) {
      _taggedFriends.push(friend);
      var obj = this.state.currentField;
      var _text = obj.innerHTML;
      _text = _text.replace(
        "@" + this.state.currentTag,
        "<b className='tagged-node' id='" +
        friend.id +
        "' contenteditable='false' style='color:orange'>" +
        friend.first_name +
        " " +
        friend.last_name +
        "</b>&nbsp;"
      );
      obj.innerHTML = _text;
    }
    this.setState({
      currentField: null,
      currentTag: "",
      taggedFriends: _taggedFriends,
      friends: [],
    });
  }

  handleComments(e, parent_id) {

    this.setState({ showCommentModal: true });

    // var elmnt = document.querySelector("#comments-form" + parent_id);
    // elmnt.style.display = "block";

    // let _comments = this.state.comments;
    // if (e != null) {
    //   e.preventDefault();
    // }
    // var api = new ApiRequest(
    //   "post",
    //   "/user/add-comment"
    // );
    // api.send({
    //   post_id: parent_id,
    //   comment: "Test",
    //   type: 0,
    //   main_comment: "",
    // }, (response) => {
    //   _comments = response.data.data;
    //   this.setState({ comments: _comments });
    //   this.forceUpdate();
    //   var elmnt = document.querySelector("#comments-form" + parent_id);
    //   elmnt.style.display = "block";
    //   // elmnt.scrollIntoView();
    // });
  }

}

export default Post;
