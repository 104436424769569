import React, { Component } from "react";

const Tagging = ({ friends, onTagSelected }) => {
  if (friends.length == 0) {
    return <div />;
  }

  const taggerStyle = {
    position: "absolute",
    background: "#fff",

    boxShadow: "0 2px 6px 0 rgba(47, 83, 151, 0.1)",
    border: "1px solid #ccc",
  };

  const tagItemStyle = {
    padding: "1em",
  };

  return (
    <div className="tagging" style={{ position: "relative" }}>
      <div className="tagger" style={taggerStyle}>
        {friends.map((friend, idx) => {
          return (
            <div
              key={idx}
              style={tagItemStyle}
              className="tag-item"
              onClick={() => onTagSelected(friend)}
            >
              <img src={friend.profile_photo} />{" "}
              <label>
                {friend.first_name} {friend.last_name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tagging;
