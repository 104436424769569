import React, { Component } from "react";
import ApiRequest from "../../libs/request";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { isValidEmail } from "../../libs/functions";

class Invites extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      pending: [],
      friends: [],
      requests: [],
      phone: "",
    };
  }

  render() {
    var alert = [];
    if (this.state.sent) {
      alert.push(
        <div key="success" className="alert alert-success">
          Invitation sent successfully!
        </div>
      );
    }
    if (this.state.errors) {
      alert.push(
        <div key="danger" className="alert alert-danger">
          {this.state.errors}
        </div>
      );
    }
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed">
          <div className="feed-container">
            <div className="row" id="invite-form">
              <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
                <FeedNav user={this.props.user} />
              </div>
              <div className="col-md-6 col-lg-4 col-sm-12 feed-center">
                <h4>Send Invitations</h4>
                <hr />
                {alert}
                <div className="form-group">
                  <label>Invite via email</label>
                  <input
                    type="text"
                    id="emails"
                    placeholder="Enter email addresses"
                    className="form-control"
                  />
                </div>
                {/* <div className="form-group">
                  <label>Invite via SMS</label>
                  <PhoneInput
                    country={"us"}
                    value={this.state.phone}
                    onChange={(phone) => this.setState({ phone })}
                  />
                </div> */}
                <button
                  className="g-button full"
                  type="button"
                  onClick={this.handleSend.bind(this)}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSend() {
    this.setState({ errors: null, sent: null });
    var emails = document.getElementById("emails").value;
  

    if (
      !isValidEmail(emails)
    ) {
      this.setState({
        errors: "Please enter at least one valid email.",
      });
      return;
    }
    
    var api = new ApiRequest("post", "/user/invite-user");
    api.send({ email: emails }, (response) => {
      if (response.status === 200) {
        this.setState({ sent: true });
      } else {
        this.setState({ errors: response.data.errors.join(",") });
      }
    });
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  componentDidMount() {
    var api = new ApiRequest("get", "/user/view-profile");
    api.send({}, (response) => {
      this.setState({ user: response.data.user });
    });
  }
}

export default Invites;
