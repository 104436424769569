import React, { Component } from 'react';

class LoadingSpinnerSmall extends Component {
    render() {

        return (
            <div
            className="lds-roller mx-auto"
            style={{
                zoom: 0.5,
            }}
        >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        );
    }
}

export default LoadingSpinnerSmall;
