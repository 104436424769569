import React, { Component } from "react";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import ApiRequest from "../../libs/request";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.state = {
      selectedDays: [],
    };
  }

  handleDayClick(day, { selected }) {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    var api = new ApiRequest("post", "/user/add-availability");
    api.send({ availability: selectedDays }, (resp) => {
      this.setState({ selectedDays });
    });
  }

  render() {
    return (
      <React.Fragment>
        <Nav user={this.props.user} />
        <div className="feed projects">
          <div className="row  feed-container">
            <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
              <FeedNav user={this.props.user} />
            </div>
            <div className="col-md-9 col-lg-10 col-sm-12 feed-center">
              <h3>Set Your Availability</h3>
              <b>
                Select the dates(s) on which you won&lsquo;t be able to connect.
              </b>
              <br />
              <br />
              <li style={{ color: "red" }}>
                Date marked red indicates that you are un-available
              </li>
              <br />
              <DayPicker
                selectedDays={this.state.selectedDays}
                onDayClick={this.handleDayClick}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    var api = new ApiRequest("get", "/user/get-availability");
    var selectedDays = [];
    api.send({}, (resp) => {
      resp.data.availability.map((item) => {
        selectedDays.push(new Date(item.availability));
        this.setState({ selectedDays });
      });
    });
  }
}

export default Calendar;
