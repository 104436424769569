import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import axios from "axios";
import { validFile } from "../../libs/functions";
import InfoForm from "./InfoForm";

class MoreInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { reels: [], images: [], location: { city: "", state: "" } };
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid header small gnav">
          <a href="/onboarding/projects" className="back">
            &lt; Back
          </a>
          <div className="logo"></div>
          <a href="/" className="skip">
            Skip for now
          </a>
        </div>
        <div className="row justify-content-center projects">
          <div className="col-12 pl-0 pr-0 pt-16 profile-fields">
            <h2>Additional Information</h2>
            <div className="reels">
              <h4>Upload Portfolios</h4>
              <InfoForm next="/" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleContinue() {
    this.props.history.push("/");
  }
}

export default MoreInfo;
