import React from "react";
import ApiRequest from "../../libs/request.js";

class Hobbies extends React.Component {
  constructor(props) {
    super(props);
    this.state = { default_hobbies: [], selected_items: [] };
    this.handleClick = this.handleClick.bind(this);
    this.fetchHobies();
  }

  render() {
    var childrens = this.state.default_hobbies.map((c, idx) => {
      return (
        <li className="bubble" key={idx} id={c} onClick={this.handleClick}>
          {c}
        </li>
      );
    });
    return (
      <div className="hobbies">
        <h2>Hobbies/Interests</h2>
        <ul>{childrens}</ul>
      </div>
    );
  }

  handleClick(e) {
    let elem = e.target;
    let items = this.state.selected_items;
    if (!elem.classList.contains("on")) {
      items.push(elem.id);
      elem.classList.add("on");
    } else {
      var items_id = [];
      items.map((i) => {
        items_id.push(i.id);
      });
      const index = items_id.indexOf(parseInt(elem.id));
      if (index > -1) {
        items.splice(index, 1);
      }
      elem.classList.remove("on");
    }

    let api = new ApiRequest("post", "/user/update-interest");
    api.send({ interests: items }, () => {
      this.setState({ selected_items: items });
      this.props.onHobbiesChange(items);
    });
  }

  fetchHobies() {
    let api = new ApiRequest("get", "/user/interests");
    api.send({}, (response) => {
      this.setState({ default_hobbies: response.data.interests });
      this.setState({ selected_items: this.props.user_hobbies });
      let ids = this.props.user_hobbies.map((h) => h);
      console.log(ids);
      const bubbles = document.getElementsByClassName("bubble");
      for (var bubble of bubbles) {
        if (ids.includes(bubble.id)) {
          bubble.classList.add("on");
        }
      }
    });
  }
}
export default Hobbies;
