export const PostTypesMock = [
    {
        "id": 1,
        "name": "General",
        "paywall": false
    },
    {
        "id": 2,
        "name": "Project",
        "paywall": false
    },
    {
        "id": 3,
        "name": "Seeking Crew",
        "paywall": false
    },
    {
        "id": 4,
        "name": "Seeking Work",
        "paywall": false
    },
    {
        "id": 6,
        "name": "Seeking Music",
        "paywall": false
    },
    {
        "id": 7,
        "name": "Seeking Investment",
        "paywall": false
    },
    {
        "id": 9,
        "name": "Music Project",
        "paywall": false
    },
    {
        "id": 11,
        "name": "Work Reels",
        "paywall": false
    },
    {
        "id": 12,
        "name": "Film News",
        "paywall": false
    },
    {
        "id": 8,
        "name": "Sell an Item",
        "paywall": true
    },
    {
        "id": 13,
        "name": "Add Project",
        "paywall": false
    },
    {
        "id": 10,
        "name": "Seeking ScreenPlay",
        "paywall": false
    },
    {
        "id": 5,
        "name": "Screen Play Project",
        "paywall": false
    }
]

export var _backgroundImagePrompt = {
    backgroundImage: 'url("./images/placeholder_2.jpg")',
    width: "100%",
    height: "100%",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };