import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import { isset, validFile } from "../../libs/functions";
import TaggingField from "./TaggingField";
import { PostTypesMock } from "../../libs/constants.js";

class FeedStatusPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTag: "",
      friends: [],
      taggedFriends: [],
      companies: [],
      post_type: 1,
      post_types: [],
      assetIds: [],
      showModal: false,
      pending_post: {},
      uploading: false,
      questions: [],
      selectedCompany: [],
      panelMode: "status-panel",
    };
    this.handleTagged = this.handleTagged.bind(this);
  }
  render() {
    if (this.props.user == null) {
      return <div />;
    }

    console.log("selectedComapny", this.state.selectedCompany);

    let _categories = [];
    this.state.post_types.map((t, idx) => {
      if ([1, 2, 3, 8].indexOf(t.id) > -1) {
        var _name = t.id == 3 ? "Create Crew" : t.name;
        _categories.push(
          <option key={idx} value={t.id}>
            {_name}
          </option>
        );
      }
    });

    let _assets = [];

    let panelMode = this.state.panelMode;
    if (this.state.uploading || this.state.pending_post.id) {
      panelMode = "status-panel open";
    }
    if (this.state.pending_post.id) {
      panelMode = "status-panel open ready";
      this.state.pending_post.thumbnails.map((img, idx) => {
        _assets.push(
          <div
            key={idx}
            className="uploaded-image"
            style={{ backgroundImage: "url(" + img.url + ")" }}
          >
            <input
              type="checkbox"
              defaultChecked={true}
              id={img.id}
              className="checkbox-round"
            />
          </div>
        );
      });
    }
    let btnStyle = "none";
    if (!this.state.uploading) {
      if (this.state.questions.length > 0 || _assets.length > 0) {
        btnStyle = "block";
      }
    }

    
    var _companies = [];
    var _post_as = "";
    if (this.state.companies.length > 0) {
      this.state.companies.map((c, idx) => {
        _companies.push(<option key={idx} value={c.id} company={c}>{c.name}</option>);
      });
      _post_as = (
        <div className="postas">
          Post as{" "}
          <select id="postas" onChange={() => {
              this.selectCompany();
          }}
            value={this.state.selectedCompany.length > 0 ? this.state.selectedCompany[0].id : ""}
          >
            <option value="">Me</option>
            {_companies}
          </select>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className={panelMode}>
          {_post_as}
          <div >
            {
              this.state.selectedCompany.length > 0 ? (
                <img className="profile_photo" src={this.state.selectedCompany[0].profile}
                  style={{ marginTop: this.state.companies.length > 0 ? '55px' : '' }} />
              ) : (
                <a href={"/user/profile/" + this.props.user.id + "#profile"}>
                  <img className="profile_photo" src={this.props.user.profile_photo}
                    style={{ marginTop: this.state.companies.length > 0 ? '55px' : '' }}
                  />
                </a>)}
            <TaggingField
              user={this.props.user}
              defaultValue={{ body: "" }}
              placeholder={`What are you up to${this.props.user.first_name ? ", "+this.props.user.first_name : ""}?`}
              onTaggedItem={(data) => this.handleTagged(data)}
              hidePhotoControls={true}
            />
            <hr />

            {/* <div className="category">
              Add to a category
              <select
                id="post_type"
                style={{ cursor: "pointer" }}
                onChange={this.handlePostType.bind(this)}
              >
                {_categories}
              </select>
              <img
                className="camera-btn"
                src="/images/status-camera.png"
                onClick={this.handleFileClick.bind(this)}
              />
            </div> */}
            <div className="status-modal">
              <input
                type="file"
                className="hidden-upload-field"
                name="photos[]"
                multiple
                id="photo-field"
                onChange={this.handleFileUpload.bind(this)}
              />
              <img src="/images/loading.gif" className="loading" />
              <div className="assets">{_assets}</div>
            </div>
            <div style={{ width: "100%", display: "flex", padding: "0px 14px 0px 14px" }}>
              <img
                className="camera-btn"
                style={{
                  width: "35px",
                  height: "35px",
                  marginRight: "10px",
                }}
                src="/images/status-camera.png"
                onClick={this.handleFileClick.bind(this)}
              />
              <button
                type="button"
                className="btn g-button full thin"
                onClick={this.handlePost.bind(this)}
              >
                Post
              </button>
            </div>
          </div>
        </div>
        <div
          className="alert alert-success"
          id="result"
          style={{ display: "none", position: "relative", top: "12px", width: "50%", margin: "auto" }}
        ></div>
      </React.Fragment>
    );
  }

  handleTagged(data) {
    console.log(data);
    this.setState(data);
  }

  handlePostType(e) {
    let id = e.target.value;
    if (id == "2") {
      window.location.href = "/new-project";
      return;
    }
    if (id == "8") {
      window.location.href = "/posts/sell";
      return;
    }
    if (id == "3") {
      window.location.href = "/seeking/crew?new=1";
      return;
    }
    // var api = new ApiRequest("get","/posts/new?post_type=" + id);
    // api.send({}, (response) => {
    //   this.setState({questions: response.data.data.questions, post_type: id});
    // });
  }

  handlePost(e) {
    if (e.target.id == "description" && e.keyCode != 13) {
      return;
    }
    if (e.target.id == "description" && e.keyCode == 13) {
      if (this.state.post_type != 1) {
        return;
      }
    }
    let _assetIds = [];
    document.querySelectorAll(".checkbox-round").forEach(function (cb) {
      if (cb.checked) {
        _assetIds.push(cb.id);
      }
    });

    var body = document.querySelector("#description").innerText;
    if (body.trim().length < 1 && _assetIds.length < 1) {
      //alert("Please enter a message")

      return;
    }
    var _taggedUsers = [];
    var _taggedNodes = document.querySelectorAll(".tagged-node");
    if (_taggedNodes.length > 0) {
      _taggedNodes.forEach((tn) => {
        this.state.taggedFriends.map((tf) => {
          if (tn.id == tf.id) {
            _taggedUsers.push({
              img: tf.profile_photo,
              taggedName: tf.first_name + " " + tf.last_name,
              taggedID: tf.id,
              user: JSON.stringify(tf),
            });
          }
        });
      });
    }

    let _answers = [];
    document.querySelectorAll(".question-field").forEach(function (q) {
      _answers.push({
        question_id: q.getAttribute("question_id"),
        answer: q.value,
      });
    });
    var company_id = this.state.selectedCompany.length > 0 ? this.state.selectedCompany[0].id : "";
    var tag_friend_ids = this.state.taggedFriends.map((tf) => tf.id);
    this.props.onSubmitClick(
      this.state.pending_post,
      _assetIds,
      body,
      _answers,
      company_id,
      tag_friend_ids
    );
    this.setState({ pending_post: {}, questions: [], post_type: 1 });
    document.querySelector("#description").innerText = "";
  }

  handleFileUpload() {
    var body = document.querySelector("#description").innerText;
    console.log("body", body);
    var formData = new FormData();
    var imagefile = document.querySelector("#photo-field");
    if (!validFile(imagefile, "photo")) {
      return;
    }
    for (var i = 0; i < imagefile.files.length; i++) {
      formData.append("media[]", imagefile.files[i]);
    }

    formData.append("post", body);
    if (this.state.id) {
      formData.append("post_id", this.state.id);
    }
    this.setState({ uploading: true });
    var api = isset(this.state.pending_post.id)
      ? new ApiRequest("post", "/user/edit-post")
      : new ApiRequest("post", "/user/add-post");
    api.send_file(formData, (response) => {
      this.setState({
        uploading: false,
        pending_post: response,
      });
    });
  }

  handleFileClick() {
    document.getElementById("photo-field").click();
  }


  selectCompany () {
    // if selected company is not me
    if (document.getElementById("postas").value != "") {
      this.setState({ selectedCompany: this.state.companies.filter(c => c.id == document.getElementById("postas").value) });
      localStorage.setItem("selectedCompany", JSON.stringify(this.state.companies.filter(c => c.id == document.getElementById("postas").value)));
    }
    else {
      this.setState({ selectedCompany: [] });
      localStorage.removeItem("selectedCompany");
    }
  }


  componentDidMount() {
    // var api = new ApiRequest("get", "/posts/post_types");
    // let types = [];
    // api.send({}, (response) => {
    //   types = response.data.data.post_types;

    //   api.url = "/user/get-companies";
    //   api.send({}, (response) => {
    //     this.setState({ post_types: types, companies: response.data.companies });
    //   });
    // });

    if (localStorage.getItem("selectedCompany")) {
      this.setState({ selectedCompany: JSON.parse(localStorage.getItem("selectedCompany")) });
    }

    var api = new ApiRequest("get", "/user/get-companies");
    api.send({}, (response) => {
      this.setState({ post_types: PostTypesMock, companies: response.data.companies });

    });
  }
}

export default FeedStatusPanel;
