import React, { Component } from "react";
import Banner from "../profile/Banner";
import ProfileForm from "../profile/ProfileForm";
import WorkHistoryForm from "../main/WorkHistoryForm";
import ProjectForm from "../projects/ProjectForm";
import ProjectBanner from "../projects/ProjectBanner";
import ScreenPlayForm from "../projects/ScreenPlayForm";
import MusicProjectForm from "../projects/MusicProjectForm";
import ReelsForm from "../projects/ReelsForm";
import { params } from "../../libs/functions";

class OnProjects extends Component {
  constructor(props) {
    super(props);
    this.handleProjectSelect = this.handleProjectSelect.bind(this);
    this.state = { post_type: null };
  }

  render() {
    if (params("type")) {
      return this.projectView();
    } else {
      return this.selectionView();
    }
  }

  projectView() {
    var _view = null;
    var _banner = null;

    if (!params("type")) {
      _view = this.renderChoose();
    } else {
      //_banner = ()
    }
    if (params("type") == "13") {
      _view = (
        <ProjectForm
          onCancel={() => this.setState({ post_type: null })}
          onAfterSave={() =>
            (window.location.href = "/onboarding/projects_list")
          }
        />
      );
    }
    if (params("type") == "5") {
      _view = (
        <ScreenPlayForm
          onCancel={() => this.setState({ post_type: null })}
          onAfterSave={() =>
            (window.location.href = "/onboarding/projects_list")
          }
        />
      );
    }
    if (params("type") == "6") {
      _view = (
        <MusicProjectForm
          onCancel={() => this.setState({ post_type: null })}
          onAfterSave={() =>
            (window.location.href = "/onboarding/projects_list")
          }
        />
      );
    }
    if (params("type") == "11") {
      _view = (
        <ReelsForm
          onCancel={() => this.setState({ post_type: null })}
          onAfterSave={() =>
            (window.location.href = "/onboarding/projects_list")
          }
        />
      );
    }

    return (
      <React.Fragment>
        <div className="container-fluid header">
          <div className="logo">
            <a
              href="/onboarding/projects"
              style={{ marginLeft: "-627px", color: "#fff" }}
              className="back"
            >
              &lt; Back
            </a>
          </div>
        </div>
        {_view}
      </React.Fragment>
    );
  }

  selectionView() {
    return (
      <React.Fragment>
        <div className="container-fluid header small gnav">
          <a href="/onboarding/profile" className="back">
            &lt; Back
          </a>
          <div className="logo"></div>
          <a href="/profile/more-info" className="skip">
            Skip For Now
          </a>
        </div>
        <div className="row justify-content-center projects">
          <div className="col-6 mt-4">
            <div style={{ fontSize: "20px", fontWeight: "bold" }}>
              Create new project
            </div>
            <div>
              Select one option for which type of project you want to create:
            </div>
            <div className="col-6 mt-5 float-left">
              <button
                onClick={() => this.handleProjectSelect(13)}
                className="col-10 mb-4 g-button full inverted"
              >
                Project
              </button>
              <button
                onClick={() => this.handleProjectSelect(5)}
                className="col-10 g-button full inverted"
              >
                Screenplay
              </button>
            </div>
            <div className="col-6 mt-5 float-left">
              <button
                onClick={() => this.handleProjectSelect(6)}
                className="col-10 mb-4 g-button full inverted"
              >
                Music
              </button>
              <button
                onClick={() => this.handleProjectSelect(11)}
                className="col-10 g-button full inverted"
              >
                Reels
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleProjectSelect(post_type_id) {
    location.href = "/onboarding/projects?type=" + post_type_id.toString();
    //  this.setState({ post_type: post_type_id.toString() });
  }
}
export default OnProjects;
