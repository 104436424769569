import React, { useState, useEffect } from 'react';
import MetaDataCard from './MetaDataCard';
import ApiRequest from '../../../libs/request';

const MetaDataComponent = ({ url }) => {
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    const getLinkPData = async () => {
      var api = new ApiRequest("get", "/url/crawler?parse_url="+url);
      api.send({}, (response, status) => {
          if (status != undefined && status != 200) {
              return;
          }
          setMetaData(response.data);
      });
    };

    getLinkPData();
  }, [url]);

  return (
    <div>
      <MetaDataCard metaData={metaData} />
    </div>
  );
};

export default MetaDataComponent;
