import React, { Component } from "react";
import ApiRequest from "../../libs/request";

class PhotoEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: this.props.post,
      photos: this.props.post.photos,
      thumbnails: this.props.post.thumbnails,
    };
    this.handleRemove = this.handleRemove.bind(this);
  }

  render() {
    return (
      <div className="photo-edits">{this.photoList(this.state.thumbnails)}</div>
    );
  }
  photoList(thumbs) {
    var _thumbs = [];
    if (thumbs && thumbs.length > 0) {
      thumbs.map((t, idx) => {
        _thumbs.push(
          <div
            className="photo-edit-thumb"
            style={{ backgroundImage: "url(" + t.url + ")" }}
          >
            <i className="fas fa-trash" onClick={() => this.handleRemove(t)} />
          </div>
        );
      });
    }
    return _thumbs;
  }

  handleRemove(item) {
    if (confirm("Are you sure you want to remove this photo?")) {
      var _items = [];
      this.state.thumbnails.map((t) => {
        if (item.id != t.id) {
          _items.push(t);
        }
      });
      this.setState({ thumbnails: _items });
      this.props.onPhotoDelete(item);
    }
  }

  componentDidUpdate() {
    if (this.state.post != this.props.post) {
      this.setState({
        post: this.props.post,
        photos: this.props.post.photos,
        thumbnails: this.props.post.thumbnails,
      });
    }
  }
}

export default PhotoEdit;
