import React, { Component } from "react";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid signup">
          <div className="row">

            {/* ------------- Show text on desktop ------------ */}

              <div className="col-md-6 left-panel show-text-on-desktop">
                <p className="auth-intro-text-home">
                  Welcome to Gorilla Film, the platform connecting filmmakers worldwide with a film market, monetized streaming service, production office featuring call sheets, and a job board for finding and posting jobs.
                  Screenwriters can upload screenplays and composers can upload their compositions to attract film producers.
                  Designed to streamline your filmmaking process, Gorilla Film enables seamless collaboration and profitability for your projects.
                </p>
              </div>

            <div className="col-md-6 right-panel form-panel-modi">
              <div className="panel" style={{ margin: "auto" }}>
                <div style={{ marginBottom: "20px" }}>
                  <img
                    src="/images/gorilla-logo-large.png"
                    className="homelogo"
                    style={{ width: "80%" }}
                    alt="Gorilla"
                  />
                  <br />
                  <span className="h5">Make Friends, Make Films!</span>
                </div>
                <div className="buttons">
                  <a href="/login" className="btn btn-light">
                    Login
                  </a>
                  <a href="/signup" className="btn btn-light btnpad">
                    Sign Up
                  </a>
                </div>
              </div>
            </div>


            {/* ---------- Show text on Mobile --------------- */}

              <div className="col-md-6 left-panel show-text-on-mobile">
                <p className="auth-intro-text-home">
                  Welcome to Gorilla Film, the platform connecting filmmakers worldwide with a film market, monetized streaming service, production office featuring call sheets, and a job board for finding and posting jobs.
                  Screenwriters can upload screenplays and composers can upload their compositions to attract film producers.
                  Designed to streamline your filmmaking process, Gorilla Film enables seamless collaboration and profitability for your projects.
                </p>
              </div>

          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
