import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import "./films.css";
import Nav from "../main/Nav.js";
import FeedNav from "../main/FeedNav.js";
import { FaHeart, FaPlay } from "react-icons/fa";
import CustomModal from "../shared/CustomModal.js";
import LoadingSpinner from "../shared/loading-spinner/LoadingSpinner.js";


class FilmDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { movieDetails: null, recommended: [] };
        this.loadFilms();
    }


    convertSecondsToHoursMinutesSeconds(seconds) {
        const hours = Math.floor(seconds / 3600);
        const remainingSecondsAfterHours = seconds % 3600;
        const minutes = Math.floor(remainingSecondsAfterHours / 60);
        const remainingSeconds = remainingSecondsAfterHours % 60;
        return `${hours}h ${minutes}m ${remainingSeconds}s`;
      }


    render() {
        return (
            <React.Fragment>
                <Nav user={this.props.user} />
                <div className="feed">
                    <div className="row  feed-container">
                        <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
                            <FeedNav user={this.props.user} />
                        </div>
                        <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
                            <div className="row justify-content-center films">
                                <div className="col-12 ml30">

                                    {
                                        this.state.movieDetails == null ? (
                                            <LoadingSpinner />
                                        )
                                            :
                                            (

                                                <article className="container-fluid" page-content="">
                                                    <div className="movie-detail">
                                                        <div className="backdrop-image"
                                                            style={{
                                                                backgroundImage: `url(${this.state.movieDetails.banner})`,
                                                            }}
                                                        ></div>

                                                        <figure className="poster-box movie-poster">
                                                            <img src={this.state.movieDetails.thumbnail} style={{ borderRadius: "10px", width: "320px" }} alt={this.state.movieDetails.film_name} className="img-cover" />
                                                        </figure>

                                                        <div className="detail-box">

                                                            <div className="detail-content">
                                                                <h1 className="heading">{
                                                                    this.state.movieDetails.film_name
                                                                }</h1>

                                                                <div className="meta-list">

                                                                    <div className="meta-item">
                                                                        <img src="/images/star.png" width="20" height="20" alt="rating" />

                                                                        <span className="span">{this.state.movieDetails.total_ratings}</span>
                                                                    </div>

                                                                    <div className="separator"></div>

                                                                    <div className="meta-item">{this.convertSecondsToHoursMinutesSeconds(this.state.movieDetails.run_time)}</div>

                                                                    <div className="separator"></div>

                                                                    <div className="meta-item">{this.state.movieDetails.release_date}</div>

                                                                    <div className="meta-item card-badge">{this.state.movieDetails.company}</div>

                                                                </div>
                                                                <p className="genre">
                                                                    {/* Show comma-separated genre_categories */}
                                                                    {this.state.movieDetails.genre_categories.map((genre, i) => (
                                                                        <React.Fragment key={i}>
                                                                            <span className="genre-item">{genre.category}</span>
                                                                            {i !== this.state.movieDetails.genre_categories.length - 1 && ', '}
                                                                        </React.Fragment>
                                                                    ))}
                                                                </p>


                                                                <p className="overview">
                                                                    {this.state.movieDetails.synopsis}
                                                                </p>

                                                                <ul className="detail-list">

                                                                    <div className="list-item">
                                                                        <p className="list-name">Cast</p>

                                                                        <p>{
                                                                            this.state.movieDetails.cast
                                                                        }</p>
                                                                    </div>

                                                                    <div className="list-item">
                                                                        <p className="list-name">Directed By</p>

                                                                        <p>{this.state.movieDetails.director}</p>
                                                                    </div>

                                                                </ul>

                                                                <div style={{display: "flex"}}>

                                                                <CustomModal
                                                                    width={"60%"}
                                                                    trigger={<div className="btn-group">
                                                                        <button className="btn btn-primary g-button" style={{
                                                                            width: "150px",
                                                                        }} >
                                                                            <FaPlay style={{
                                                                                position: "relative",
                                                                                right: "10px",
                                                                                bottom: "1px"
                                                                            }} />
                                                                            Play Now
                                                                        </button>
                                                                    </div>} content={
                                                                        <video controls autoPlay controlsList="nodownload" width={"100%"} height={"auto"}>
                                                                            <source src={this.state.movieDetails.movie_url} type="video/mp4" />
                                                                            Your browser does not support the video tag.
                                                                        </video>

                                                                    } />

                                                                <button className="btn btn-primary g-button" style={{
                                                                    width: "40px",
                                                                    marginLeft: "10px",
                                                                    textAlign: "center"
                                                                }} 
                                                                title="Add to Favourite"
                                                                onClick={() => {
                                                                    this.addToFavorite(this.state.movieDetails.id);
                                                                }}
                                                                >
                                                                    <FaHeart />
                                                                    {/* Add to Favourite */}
                                                                </button>

                                                                </div>


                                                            </div>


                                                        </div>




                                                    </div>
                                                    {
                                                        this.state.recommended.length > 0 && (
                                                            <div className="row justify-content-center films">

                                                                <div className="col-12" >
                                                                    <div className="hdr" style={{ marginTop: "20px", marginBottom: "20px", color: "white" }}>
                                                                        <h4>Recommended</h4>
                                                                    </div>
                                                                    <div className="wf-grid-list">
                                                                        {this.state.recommended.map((film, idx) => (

                                                                            <div className="wf-movie-card" key={idx}>
                                                                                <figure className="wf-poster-box wf-card-banner">
                                                                                    <img src={film.thumbnail} alt={film.film_name} className="wf-img-cover" loading="lazy" />
                                                                                </figure>
                                                                                <h4 className="wf-title" style={{ color: "white" }}>{film.film_name}</h4>
                                                                                <div className="wf-meta-list">
                                                                                    <div className="wf-meta-item">
                                                                                        <img src="../images/star.png" className="wf-star-rating" width="20" height="20" loading="lazy" alt="rating" />
                                                                                        <span className="span" style={{ color: "white" }}>{film.total_ratings}</span>
                                                                                    </div>
                                                                                    <div className="wf-card-badge">{film.release_date.substring(0, 4)}</div>
                                                                                </div>
                                                                                <a href={`/film-details/${film.id}`} className="wf-card-btn" title={film.film_name}></a>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        )

                                                    }

                                                </article>
                                            )
                                    }



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    loadFilms() {
        // get id from url
        const id = location.href.split("/").pop();

        let api = new ApiRequest("get", "/user/show-movie?movie_id=" + id);
        api.send({}, (response, status) => {
            if (status != undefined && status != 200) {
                return;
            }
            this.setState({ movieDetails: response.data.movie, recommended: response.data.recommended });
        });
    }

    addToFavorite(id) {

        let api = new ApiRequest("post", "/user/mark-movie-as-favorite");
        api.send({ movie_id: id }, (response, status) => {
            if (status != undefined && status != 200) {
                return;
            }

        });
    }




}

export default FilmDetails;