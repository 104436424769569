import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import "./films.css";
import Nav from "../main/Nav.js";
import FeedNav from "../main/FeedNav.js";
import LoadingSpinner from "../shared/loading-spinner/LoadingSpinner.js";

class FilmsByCategory extends Component {
    constructor(props) {
        super(props);
        this.state = { films: [], loading: true, category: "" }; // Added loading state
    }

    componentDidMount() {
        this.loadFilms();
    }

    loadFilms() {
        let category = window.location.search.split("?category=")[1];
        // Decode the category value to handle encoded characters
        category = decodeURIComponent(category);
        this.setState({ category: category });
        let api = new ApiRequest("get", "/user/get-movies-by-category?category=" + encodeURIComponent(category));
        api.send({}, (response, status) => {
            if (status !== undefined && status !== 200) {
                return;
            }
            this.setState({ films: response.data.movies, loading: false }); // Set loading to false after fetching films
        });
    }

    render() {
        const { films, loading } = this.state; // Destructure state
        return (
            <React.Fragment>
                <Nav user={this.props.user} />
                <div className="feed">
                    <div className="row feed-container">
                        <div className="col-md-3 col-lg-2 col-sm-12 feed-left">
                            <FeedNav user={this.props.user} />
                        </div>
                        <div className="col-md-9 col-lg-10 col-sm-12 feed-center large">
                            <div className="row justify-content-center films">
                                {loading ? ( // Render loading text if loading is true
                                    <div className="col-12">
                                         <LoadingSpinner />
                                    </div>
                                ) : (
                                    <div className="col-12">
                                    <div className="hdr" style={{marginTop: "20px", marginBottom: "20px"}}>
                                        <div >
                                            <h4>{this.state.category} - Movies</h4>
                                        </div>
                                     
                                    </div>
                                    <div className="wf-grid-list">
                                        {this.state.films.length > 0 &&
                                            this.state.films.map((film, idx) => (
                                                <div className="wf-movie-card" key={idx}>
                                                    <figure className="wf-poster-box wf-card-banner">
                                                        <img src={film.thumbnail} alt={film.film_name} className="wf-img-cover" loading="lazy" />
                                                    </figure>
                                                    <h4 className="wf-title">{film.film_name}</h4>
                                                    <div className="wf-meta-list">
                                                        <div className="wf-meta-item">
                                                            <img src="./images/star.png" className="wf-star-rating" width="20" height="20" loading="lazy" alt="rating" />
                                                            <span className="span">{film.total_ratings}</span>
                                                        </div>
                                                        <div className="wf-card-badge">{film.release_date.substring(0, 4)}</div>
                                                    </div>
                                                    <a href={`/film-details/${film.id}`} className="wf-card-btn" title={film.film_name}></a>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default FilmsByCategory;
