import React, { Component } from "react";
import ApiRequest from "../../libs/request.js";
import ProfileStats from "./ProfileStats";
import Nav from "./Nav";
import FeedNav from "./FeedNav";
import WorkHistory from "./WorkHistory";
import CustomModal from "./../shared/CustomModal.js";
import { FaExpand, FaEye } from "react-icons/fa";
import { _backgroundImagePrompt } from "../../libs/constants.js";

class WorkHistoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      reviews: [],
      cover: "",
      profile_photo: "",
      tab: "#profile",
      tabs: [
        {
          url: "edit",
          icon: "fa-user",
          name: "Personal Info",
        },
        {
          url: "workhistory",
          icon: "fa-video",
          name: "Work History",
        },
        {
          url: "info",
          icon: "fa-star",
          name: "Addtional Info",
        },
      ],
    };
  }

  render() {
    var occupation =
      this.state.occupations && this.state.occupations.length > 0 ? (
        this.state.occupations[0].name
      ) : (
        <p>&nbsp;</p>
      )

    var occupation_counts =
      this.state.occupations && this.state.occupations.length > 0 ? (
        this.state.occupations.length - 1
      ) : (0);

    var _errors = <div />;
    if (this.state.errors) {
      _errors = <div className="alert alert-danger">{this.state.errors}</div>;
    }

    var _tabs = [];
    this.state.tabs.map((t, idx) => {
      var _active = "workhistory" === t.url ? "active" : "";
      _tabs.push(
        <li key={idx} className={_active}>
          <a
            className={_active}
            onClick={() => {
              this.setState({ tab: t.url });
            }}
            href={"/profile/0/" + t.url}
          >
            <i className={"fas " + t.icon} />
            <br />
            {t.name}
          </a>
        </li>
      );
    });

    return (
      <React.Fragment>
        <div className="row justify-content-center" style={{ float: "left" }}>
          <div
            className="col-9"
            style={{
              marginTop: "108px",
              marginLeft: "30px",
              maxWidth: "unset",
            }}
          >
            <div className="profile-form">
              <div className="title"></div>
              <div className="upload" style={{ height: "275px", position: "relative" }}>
                <div className="image-upload">
                  <div id="backgroundPrompt" style={_backgroundImagePrompt}>Upload background image</div>
                </div>
                {this.state.cover &&
                  <CustomModal
                    width={"50%"}
                    trigger={<FaExpand
                      style={{
                        fontSize: "24px",
                        color: "white",
                        cursor: "pointer",
                        position: "absolute",
                        right: "10px",
                        bottom: "65px"
                      }}
                    />} content={
                      <img src={this.state.cover} style={{ width: "100%" }} />
                    } />}
                <div className="camera-btn" style={{ float: "left" }}>
                  <a href="#" id="avatar"></a>
                </div>
                {
                  this.state.profile_photo &&
                  <CustomModal
                    width={"500px"}
                    trigger={<FaEye
                      style={{
                        fontSize: "18px",
                        color: "white",
                        cursor: "pointer",
                        position: "absolute",
                        bottom: "5px",
                        left: "48px",
                        // backgroundColor: "#f8f8f8",
                        width: "80px"
                      }}
                    />} content={
                      <img src={this.state.profile_photo} style={{ width: "100%" }} />
                    } />}
                <div className="profile-id">
                  <b>
                    {this.state.first_name} {this.state.last_name}
                  </b>
                  <br />
                  <span style={{ fontSize: "16px" }}>{occupation} <span className={occupation_counts > 0 ? '' : 'hidden'}> & {occupation_counts} more</span></span>
                </div>

                <div className="stats" style={{ float: "left" }}></div>
                <div
                  className="prompt"
                  style={{
                    marginRight: "0px",
                    textAlign: "right",
                    width: "255px",
                    marginTop: "9px",
                  }}
                >
                  <ProfileStats user={this.state} showRequestButton={false} />
                </div>
              </div>
              <div className="col-12 profile-tabs" style={{ float: "left" }}>
                <ul>{_tabs}</ul>
              </div>
              <div
                className="col-12 pl-0 pr-0 pt-16 profile-fields"
                style={{ marginTop: "18px", float: "left" }}
              >
                <WorkHistory user={this.props.user} editable={true} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  componentDidMount() {
    let api = new ApiRequest("get", "/user/view-profile");
    api.send({}, (response) => {
      let result = response.data;
      this.setState(result.user);
      this.forceUpdate();
      if (this.state.cover) {
        document.querySelector("#backgroundPrompt").style.display = "none";
        document.querySelector(".image-upload").style.backgroundImage =
          "url('" + this.state.cover + "')";
        this.setState({ cover: this.state.cover });
      }
      if (this.state.profile_photo) {
        document.querySelector("#avatar").style.display = "none";
        document.querySelector(".camera-btn").style.backgroundImage =
          "url('" + this.state.profile_photo + "')";
        this.setState({ profile_photo: this.state.profile_photo });
      }
    });
  }
}

export default WorkHistoryForm;
