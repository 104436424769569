import React, { Component } from "react";
import ApiRequest from "../../libs/request";

class PersonalInfo extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    console.log("PersonalInfo.js", this.props.user);
    return (
      <React.Fragment>
        <h2>Profile Information</h2>
        <form id="profileForm" className="gorilla-form">
          <div className="row m-0 p-0">
            <div className="col-6 m-0 p-0 pr-4 pl-6">
              <label>First Name</label>
              <input
                name="first_name"
                value={this.props.user.first_name || ""}
                className="form-control readonly"
                readOnly={true}
                placeholder="First Name"
              />
            </div>
            <div className="col-6 m-0 pl-6 pr-0 pb-4">
              <label>Last Name</label>
              <input
                name="last_name"
                value={this.props.user.last_name || ""}
                className="form-control readonly"
                readOnly={true}
                placeholder="Last Name"
              />
            </div>
            <div className="col-6 m-0 p-0 pr-4 pl-6">
              <label>City</label>
              <input
                name="city"
                value={this.props.user.city || ""}
                className="form-control readonly"
                readOnly={true}
                placeholder="City"
              />
            </div>
            <div className="col-6 m-0 pl-6 pr-0 pb-4">
              <label>Zip Code</label>
              <input
                name="zipcode"
                value={this.props.user.zip || ""}
                className="form-control readonly"
                readOnly={true}
                placeholder="Zip"
              />
            </div>
            <div className="col-6 m-0 p-0 pr-4 pl-6">
            <label>Email</label>
              <input
                name="email"
                value={this.props.user.email || ""}
                className="form-control readonly"
                readOnly={true}
                placeholder="Email"
              />
            </div>
            <div className="col-6 m-0 pl-6 pr-0 pb-4">
            <label>IMDb Link</label>
              <input
                name="imdb_link"
                value={this.props.user.imdb || ""}
                className="form-control readonly"
                readOnly={true}
                placeholder="IMDb Link"
              />
            </div>
           
            <div className="col-12 m-0 p-0 pb-4">
              <label>About Me</label>
              <textarea
                name="aboutme"
                value={this.props.user.tell_us || ""}
                style={{ height: "160px" }}
                className="form-control textarea readonly"
                readOnly={true}
                placeholder="Tell us about yourself"
              ></textarea>
            </div>

            <p>&nbsp;</p>
          </div>
        </form>
      </React.Fragment>
    );
  }
}
export default PersonalInfo;
